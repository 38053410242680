import {
	getNotifications,
	putNotificationAsRead
} from 'services/notifications';

// types
export const GET_USER_NOTIFICATIONS = 'GET_USER_NOTIFICATIONS';
export const PUSH_USER_NOTIFICATIONS = 'PUSH_USER_NOTIFICATIONS';
export const UPDATE_USER_NOTIFICATION = 'UPDATE_USER_NOTIFICATION';

// actions
export const getUserNotifications = (payload) => ({
	type: GET_USER_NOTIFICATIONS,
	payload
});

export const updateNotification = (payload) => ({
	type: UPDATE_USER_NOTIFICATION,
	payload
});

export const pushUserNotification = (payload) => ({
	type: PUSH_USER_NOTIFICATIONS,
	payload
});

// state
const initialState = [];

export function fetchNotifications() {
	return async (dispatch) => {
		const notifications = await getNotifications();
		dispatch(getUserNotifications(notifications.data));
		return notifications;
	};
}

export function pushNotification(notification) {
	return (dispatch) => dispatch(pushUserNotification(notification));
}

export function markNotificationAs(notificationId, type) {
	return async (dispatch) => {
		try {
			type === 'read' && (await putNotificationAsRead(notificationId));
			dispatch(updateNotification({ notificationId, type }));
		} catch (e) {
			console.log(e);
		}
	};
}

const mapMarkAsRead = (list, id) =>
	list.map((item) => (item.id === id ? { ...item, hasRead: true } : item));

const mapMarkAsInteracted = (list, id) =>
	list.map((item) =>
		item.id === id ? { ...item, hasInteracted: true, hasRead: true } : item
	);

const handleUpdateByType = (type, list, id) => {
	if (type === 'interacted') return mapMarkAsInteracted(list, id);
	if (type === 'read') return mapMarkAsRead(list, id);

	throw new TypeError(
		'Wrong value for "type" argument. Expect one of "read" | "interacted"'
	);
};

// reducer
export function userNotifications(state = initialState, action) {
	const { notificationId, type } = action.payload || {};

	switch (action.type) {
		case GET_USER_NOTIFICATIONS:
			return [...action.payload];
		case UPDATE_USER_NOTIFICATION:
			return handleUpdateByType(type, state, notificationId);
		case PUSH_USER_NOTIFICATIONS:
			return [action.payload, ...state];
		default:
			return state;
	}
}
