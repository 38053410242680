import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import BracketHeadingItem from './HeadingItem';
import roundsFixture from '../../__fixtures__/tournament.brackets.rounds';

const useStyles = makeStyles(
	({ palette }) => ({
		wrapper: {
			margin: '0 -3rem 4rem'
		},
		container: {
			backgroundColor: palette.asphalt,
			height: '7.5rem',
			paddingLeft: '3rem',
			paddingRight: '3rem',
			width: 'auto'
		}
	}),
	{ name: 'BracketHeadingItem' }
);

const BracketHeading = ({ rounds = roundsFixture }) => {
	const classes = useStyles();
	const [indexActive, setIndexActive] = useState(null);

	return (
		<div className={classes.wrapper}>
			<Grid container className={classes.container} wrap="nowrap">
				{rounds.map((round, i) => {
					const isVisible = indexActive === i;
					return (
						<BracketHeadingItem
							round={round}
							key={`round-${i}`}
							isVisible={isVisible}
							onToggleVisibility={() =>
								setIndexActive(isVisible ? null : i)
							}
						/>
					);
				})}
			</Grid>
		</div>
	);
};

export default BracketHeading;
