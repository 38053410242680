import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Item from './Item';

// import ReportProblemRoundedIcon from '@material-ui/icons/ReportProblemRounded';

const useStyles = makeStyles({
	prizeTitle: {
		textTransform: 'uppercase',
		margin: [[0, 0, 16]]
	},
	prizeContainer: {
		height: 300
	}
});

const Prize = ({ prizes = [] }) => {
	const classes = useStyles();

	return (
		<>
			<Typography variant="h4" className={classes.prizeTitle}>
				Premiações
			</Typography>
			<Box className={classes.prizeContainer}>
				{prizes.map((prize, idx) => (
					<Item key={`prize-${idx}`} prize={prize} idx={idx} />
				))}
			</Box>
		</>
	);
};

export default Prize;
