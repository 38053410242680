import { withStyles } from '@material-ui/core/styles';
import { TableCell } from '@material-ui/core';

const StyledTableCell = withStyles({
	root: {
		padding: '0.875rem',
		borderColor: 'transparent',
		borderWidth: '2px',
		borderTopStyle: 'solid',
		borderBottomStyle: 'solid',
		'&:first-child': {
			borderLeftStyle: 'solid'
		},
		'&:last-child': {
			borderRightStyle: 'solid'
		}
	}
})(TableCell);

export default StyledTableCell;
