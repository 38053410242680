import React from 'react';
import { Link as MALink } from '@material-ui/core';
import { Link as RRLink } from 'react-router-dom';

const Link = ({ to, children, className }) => (
	<MALink component={RRLink} underline="none" to={to} className={className}>
		{children}
	</MALink>
);

export default Link;
