import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import ListIcon from '@material-ui/icons/List';
import CancelIcon from '@material-ui/icons/Cancel';
import BlockIcon from '@material-ui/icons/Block';
import PersonIcon from '@material-ui/icons/Person';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import SearchIcon from '@material-ui/icons/Search';

const routes = {
	tournaments: [
		{
			name: 'Listar',
			path: '/tournaments',
			icon: <ListIcon fontSize="medium" />
		},
		{
			name: 'criar',
			path: '/create/tournaments',
			icon: <AddCircleOutlineIcon fontSize="medium" />
		},
		{
			name: 'editar partida',
			path: '/edit/tournaments',
			icon: <EditIcon fontSize="medium" />
		}
	],
	matches: [
		{
			name: 'Listar',
			path: '/matches',
			icon: <ListIcon fontSize="medium" />
		},
		{
			name: 'Criar',
			path: '/matches/create',
			icon: <AddCircleOutlineIcon fontSize="medium" />
		},
		{
			name: 'Cancelar',
			path: '/matches/cancel',
			icon: <CancelIcon fontSize="medium" />
		}
	],
	players: [
		{
			name: 'jogadores banidos',
			path: '/players',
			icon: <PersonIcon fontSize="medium" />
		},
		{
			name: 'banir',
			path: '/players/ban',
			icon: <BlockIcon fontSize="medium" />
		},
		{
			name: 'desbanir',
			path: '/players/unban',
			icon: <CheckCircleOutlineIcon fontSize="medium" />
		},
		{
			name: 'Buscar Jogadores',
			path: '/players/search',
			icon: <SearchIcon fontSize="medium" />
		}
	],
	content: [
		{
			name: 'listar',
			path: '/content',
			icon: <ListIcon fontSize="medium" />
		},
		{
			name: 'criar',
			path: '/content/create',
			icon: <AddCircleOutlineIcon fontSize="medium" />
		}
	]
};

export default routes;
