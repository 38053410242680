import React, { useReducer, useState, useEffect } from 'react';
import { useAPI } from '../hooks';
import {
	Card,
	Button,
	CardActionArea as div,
	CardActions,
	CardContent,
	makeStyles,
	CardMedia
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import ScheduleIcon from '@material-ui/icons/Schedule';
import RestoreIcon from '@material-ui/icons/Restore';
import Edit from '@material-ui/icons/Edit';
import Wo from '../components/Modal/Wo';
import Reset from '../components/Modal/Reset';
import Modal from '../components/shared/Modal';
import Reschedule from '../components/Modal/Reschedule';
import Layout from 'components/Layout';
import routes from 'routes';
import TournamentReportsItem from './tournaments/reports/item/TournamentReportsItem';
import TournamentReportModal from './tournaments/reports/modal/TournamentReportModal';
import { useLocation } from 'react-router-dom';
import Restart from 'components/Modal/Restart';
import ChangeFormat from 'components/Modal/ChangeFormat';

const useStyles = makeStyles((theme) => ({
	root: {
		background: 'transparent'
	},
	cardpanel: {
		display: 'flex',
		flexFlow: 'row'
	},
	cardcontent: {
		display: 'flex',
		justifyContent: 'center',
		textAlign: 'center',
		flexFlow: 'column',
		alignItems: 'center',
		width: '100%'
	},
	cardactions: {
		display: 'flex',
		justifyContent: 'center'
	},
	avatar: {
		height: 270,
		width: 270,
		borderRadius: '50%',
		marginTop: 20
	},
	wobutton: {
		color: 'white',
		backgroundColor: 'indianred',
		'&:hover': {
			backgroundColor: 'red'
		}
	},
	resetbutton: {
		color: 'white',
		backgroundColor: theme.palette.darkGold,
		'&:hover': {
			backgroundColor: theme.palette.gold
		}
	},
	restartbutton: {
		color: 'white',
		backgroundColor: theme.palette.silver,
		'&:hover': {
			backgroundColor: theme.palette.gandalf
		}
	},
	reschedulebutton: {
		color: 'white',
		backgroundColor: theme.palette.primary.main,
		'&:hover': {
			backgroundColor: 'slateblue'
		}
	},
	changeformatbutton: {
		color: 'white',
		backgroundColor: theme.palette.secondary.main,
		'&:hover': {
			backgroundColor: theme.palette.secondary.light
		}
	}
}));

const Match = (props) => {
	const tournament = props.match.params;
	const match = props.match.params;
	const classes = useStyles();

	const { response: tournamentMatch = {} } = useAPI(
		`/tournaments/${tournament.id}/matches/${match.matchid}`
	);
	const { response: reports = [] } = useAPI(
		`/tournaments/${tournament.id}/matches/${match.matchid}/reports`
	);

	const { search } = useLocation();

	const query = new URLSearchParams(search);

	const selectedReportId = query.get('reportId');

	const selectedReport = reports.find(
		(report) => String(report.id) === selectedReportId
	);

	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [isDialogOpenWO, setIsDialogOpenWO] = useState(false);
	const [isDialogOpenRES, setIsDialogOpenRES] = useState(false);
	const [isDialogOpenRestart, setIsDialogOpenRestart] = useState(false);
	const [isDialogOpenBestOf, setIsDialogOpenBestOf] = useState(false);

	const dialogToggle = () => {
		setIsDialogOpen(!isDialogOpen);
	};
	const dialogToggleWO = () => {
		setIsDialogOpenWO(!isDialogOpenWO);
	};
	const dialogToggleRES = () => {
		setIsDialogOpenRES(!isDialogOpenRES);
	};
	const dialogToggleRestart = () => {
		setIsDialogOpenRestart(!isDialogOpenRestart);
	};
	const dialogToggleBestOf = () => {
		setIsDialogOpenBestOf(!isDialogOpenBestOf);
	};

	return (
		<Layout routes={routes.tournaments} name={'campeonatos'}>
			<Card className={classes.root} elevation={0}>
				<div className={classes.cardpanel}>
					<CardContent className={classes.cardcontent}>
						{tournamentMatch.teamA?.name}
						<CardMedia
							className={classes.avatar}
							image={tournamentMatch.teamA?.avatar}
						/>
					</CardContent>
					<CardContent className={classes.cardcontent}>
						{tournamentMatch.scheduling?.datetime}
						<br />
						{tournamentMatch.game}
						<br />
						<br />
						VS
					</CardContent>
					<CardContent className={classes.cardcontent}>
						{tournamentMatch.teamB?.name}
						<CardMedia
							className={classes.avatar}
							image={tournamentMatch.teamB?.avatar}
						/>
					</CardContent>
				</div>
				<CardActions className={classes.cardactions}>
					<Button
						variant="contained"
						className={classes.wobutton}
						onClick={dialogToggleWO}
					>
						<WarningIcon /> W.O.
					</Button>
					<Button
						variant="contained"
						className={classes.restartbutton}
						onClick={dialogToggleRestart}
					>
						<RestoreIcon /> Reiniciar
					</Button>
					<Button
						variant="contained"
						className={classes.resetbutton}
						onClick={dialogToggleRES}
					>
						<RestoreIcon /> Reset
					</Button>
					<Button
						variant="contained"
						className={classes.reschedulebutton}
						onClick={dialogToggle}
					>
						<ScheduleIcon /> Reagendamento
					</Button>
					<Button
						variant="contained"
						className={classes.changeformatbutton}
						onClick={dialogToggleBestOf}
					>
						<Edit /> Formato
					</Button>
				</CardActions>
			</Card>
			{!tournamentMatch?.outcome && (
				<div>
					{reports?.map((report) => (
						<TournamentReportsItem
							report={report}
							tournamentId={tournament.id}
							match={tournamentMatch}
							key={report.id}
						/>
					))}
				</div>
			)}
			<Modal
				title="WO"
				toggleModal={dialogToggleWO}
				isShowingModal={isDialogOpenWO}
			>
				<Wo
					matchesinfo={tournamentMatch}
					tournament={tournament.id}
					match={match.matchid}
					dialogToggleWO={() => {
						dialogToggleWO();
					}}
				/>
			</Modal>
			<Modal
				title="Resetar"
				toggleModal={dialogToggleRES}
				isShowingModal={isDialogOpenRES}
			>
				<Reset
					matchesinfo={tournamentMatch}
					tournament={tournament.id}
					match={match.matchid}
					dialogToggleRES={() => {
						dialogToggleRES();
					}}
				/>
			</Modal>
			<Modal
				title="Best Of"
				toggleModal={dialogToggleBestOf}
				isShowingModal={isDialogOpenBestOf}
			>
				<ChangeFormat
					matchId={match.matchid}
					dialogToggleBestOf={() => {
						dialogToggleBestOf();
					}}
				/>
			</Modal>
			<Modal
				title="Reiniciar"
				toggleModal={dialogToggleRestart}
				isShowingModal={isDialogOpenRestart}
			>
				<Restart
					tournament={tournament.id}
					match={match.matchid}
					matchInfo={tournamentMatch}
					dialogToggleRestart={() => {
						dialogToggleRestart();
					}}
				/>
			</Modal>
			<Modal
				title="Editar Partida"
				toggleModal={dialogToggle}
				isShowingModal={isDialogOpen}
			>
				<Reschedule
					matches={tournamentMatch}
					tournament={tournament.id}
					match={match.matchid}
					dialogToggle={() => {
						dialogToggle();
					}}
				/>
			</Modal>
			<TournamentReportModal
				report={selectedReport}
				match={tournamentMatch}
				tournamentId={tournament.id}
			/>
		</Layout>
	);
};

export default Match;
