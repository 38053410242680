import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	title: {
		textTransform: 'uppercase',
		lineHeight: 1.2,
		margin: [[0, 0, 16, 0]]
	}
});

const SectionTitle = ({ children, ...props }) => {
	const classes = useStyles();

	return (
		<Typography variant="h4" className={classes.title} {...props}>
			{children}
		</Typography>
	);
};

export default SectionTitle;
