import React from 'react';

import { Typography, Grid, CardMedia } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Link from 'components/shared/Link';
import { parseDateAsReadable } from '../helpers';
import ConfirmationText from './ConfirmationText';

const useStyles = makeStyles(
	(theme) => ({
		root: {
			backgroundColor: theme.palette.gunpowder,
			padding: [[0, 28]],
			height: 64,
			borderRadius: 8,
			textAlign: 'center',
			margin: [[5, 0, 25]]
		},
		teamNames: {
			textAlign: 'left',
			fontSize: 18,
			color: 'white',
			fontWeight: 500,
			margin: [['auto', 0]]
		},
		media: {
			height: 42,
			width: 42,
			margin: [[0, 8]],
			borderRadius: 21
		},
		date: {
			fontSize: 12,
			lineHeight: '12px',
			color: theme.palette.gandalf,
			fontWeight: 500
		}
	}),
	{ name: 'TeamListItem' }
);

const TeamListItem = ({ info }) => {
	const classes = useStyles();
	const { id, avatar, name, hasConfirmed, confirmationDate } = info;

	return (
		<Grid
			container
			alignItems="center"
			justify="space-between"
			spacing={0}
			className={classes.root}
		>
			<Grid item xs={2} lg={1}>
				<CardMedia
					className={classes.media}
					component="img"
					image={avatar}
				/>
			</Grid>
			<Grid item xs={6} lg={3}>
				<Link to={`/team/${id}`}>
					<Typography className={classes.teamNames}>
						{name}
					</Typography>
				</Link>
			</Grid>
			<Grid item xs={2} lg={2}>
				<ConfirmationText hasConfirmed={hasConfirmed} />
			</Grid>
			<Grid item xs={2} lg={2}>
				<Typography variant="caption">
					{parseDateAsReadable(confirmationDate, true, true, true)}
				</Typography>
			</Grid>
		</Grid>
	);
};

export default TeamListItem;
