import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

export const avatarSizes = {
	small: '1.875rem',
	default: '2.625rem',
	lg: '3.125rem',
	extraLarge: '5rem'
};

const useStyles = makeStyles(
	Object.keys(avatarSizes).reduce((style, size) => {
		style[size] = {
			width: avatarSizes[size],
			height: avatarSizes[size],
			margin: ({ margin }) => margin
		};
		return { ...style };
	}, {})
);

const TeamAvatar = ({ size, margin, ...props }) => {
	const classes = useStyles({ margin });

	return <Avatar className={classes[size]} {...props} />;
};

TeamAvatar.defaultProps = {
	size: 'default',
	margin: [[0, '0.5rem']]
};

export default TeamAvatar;
