import React, { useState, useEffect } from 'react';
import style from './players.module.scss';
import MenuLateral from 'components/menu-lateral';
import { getBanPlayer, unbanPlayer } from 'services/players';
import routes from 'routes';
import { addDays } from 'date-fns';

export default function Players() {
	// const leagues = ["cpl", "cgl", "grl"]
	const [leagues, setLeagues] = useState([
		{
			league: 'cpl',
			name: 'RPL',
			active: true
		},
		{
			league: 'cgl',
			name: 'ADV',
			active: false
		},
		{
			league: 'grl',
			name: 'GRL',
			active: false
		},
		{
			league: 'default',
			name: 'TODOS',
			active: false
		}
	]);
	const [listBanPlayers, setListBanPlayers] = useState();
	const [leagueSelected, setLeagueSelected] = useState(leagues[0]);

	const dateLimit = addDays(new Date(), 31)
		.toISOString()
		.slice(0, 19)
		.replace('T', ' ');

	const handleBanPlayerList = async () => {
		const { data } = await getBanPlayer(
			leagueSelected.league,
			leagueSelected.league === 'default' ? dateLimit : undefined
		);
		setListBanPlayers(data);
	};

	useEffect(() => {
		handleBanPlayerList();
	}, [leagueSelected]);

	async function goUnban(id) {
		await unbanPlayer(id);
		const { data } = await getBanPlayer(leagueSelected.league);
		setListBanPlayers(data);
	}

	function selectLeague(item) {
		leagues.map((league) => {
			if (league.league == item.league) {
				league.active = true;
			} else {
				league.active = false;
			}
			return league;
		});
		setLeagueSelected(item);
	}

	return (
		<>
			<div className={style.container}>
				<MenuLateral name="jogadores" routes={routes.players} />
				<div className={style.content}>
					<h1>Listar jogadores banidos</h1>
					<div className={style.league}>
						<p>Selecione uma liga:</p>
						<ul className={style.listLeague}>
							{leagues.map((league) => {
								return (
									<li
										data-selected={league.active}
										className={style.item}
										onClick={() => selectLeague(league)}
									>
										{league.name}
									</li>
								);
							})}
						</ul>
					</div>
					<table className={style.table}>
						<thead>
							<tr>
								<th>id</th>
								<th>Nome</th>
								<th>Banido até</th>
								<th>Steam</th>
							</tr>
						</thead>
						<tbody>
							{listBanPlayers &&
								listBanPlayers.items.map((player) => {
									return (
										<tr>
											<td>{player.id}</td>
											<td>{player.name}</td>
											<td>{player.bannedUntil}</td>
											<td>{player.steamId}</td>
											<td
												onClick={() =>
													goUnban(player.id)
												}
											>
												Desbanir
											</td>
										</tr>
									);
								})}
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
}
