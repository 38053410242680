import React from 'react';
import style from './home.module.scss';

export default function Home() {
	return (
		<>
			<div className={style.container}></div>
		</>
	);
}
