const onOpen = (event) => {
	console.log('ws: connected');
	console.log(event);
};

const onClose = (event) => {
	console.log('ws: disconnected');
	console.log(event);
};

const onError = (event) => {
	console.log('ws: error');
	console.log(event);
};

const onMessage = ({ data: response }) => {
	console.log('ws: message received');
	console.log(JSON.parse(response));
};

export class SocketWrapper {
	constructor({ url, token, autoConnect = false, keepAlive = false }) {
		this.url = url;
		this.token = token;
		this.socket = {
			removeEventListener: () => ({}),
			addEventListener: () => ({}),
			close: () => ({})
		};
		this.timer = null;
		this.keepAliveTimeout = keepAlive;

		if (autoConnect) {
			this.connect();
		}
	}

	connect() {
		const { url, token } = this;
		if (!token) {
			return null;
		}

		this.socket = new WebSocket(`${url}?token=${token}`);
		this.keepAlive();
		this.removeListeners();
		this.addListeners();

		return this.socket;
	}

	disconnect() {
		return this.socket.close();
	}

	removeListeners() {
		this.socket.removeEventListener('open', onOpen);
		this.socket.removeEventListener('close', onClose);
		this.socket.removeEventListener('error', onError);
		this.socket.removeEventListener('message', onMessage);
	}

	addListeners() {
		this.socket.addEventListener('open', onOpen);
		this.socket.addEventListener('close', onClose);
		this.socket.addEventListener('error', onError);
		this.socket.addEventListener('message', onMessage);
	}

	keepAlive() {
		if (!this.keepAliveTimeout) {
			return;
		}

		const { socket, keepAliveTimeout } = this;
		this.timerID = setTimeout(() => {
			if (socket.readyState === socket.OPEN) {
				socket.send('keep-alive');
			}
			this.keepAlive();
		}, keepAliveTimeout);
	}

	cancelKeepAlive() {
		const { timerID } = this;
		if (timerID) {
			clearTimeout(timerID);
		}
	}

	resetKeepAlive() {
		this.cancelKeepAlive();
		this.keepAlive();
	}
}
