import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { tournamentSignup } from 'services/tournament';
import { StyledTabs, StyledTab } from 'components/Tabs';
import TournamentHeading from 'components/Tournament/Heading';
import {
	TournamentContentSummary,
	TournamentContentRules,
	TournamentContentBrackets,
	TournamentContentTeams,
	TournamentContentMatches
} from 'components/Tournament';
import Modal from 'components/shared/Modal';
// import SignupTeam from 'components/ModalContent/SignupTeam';
import BoxedContainer from 'components/BoxedContainer';
import { useAPI, useTab, useModal } from 'hooks';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ContentContainer } from 'container/ContentContainer';
import HeaderContainer from '../HeaderContainer';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles(
	() => ({
		padding: {
			paddingBottom: 20
		}
	}),
	{ name: 'Tournament' }
);

const tabsComponents = [
	TournamentContentSummary,
	TournamentContentRules,
	TournamentContentBrackets,
	TournamentContentTeams,
	TournamentContentMatches
];

const TournamentContainer = ({ id }) => {
	const history = useHistory();
	const classes = useStyles();
	const [selectedTab, handleChange] = useTab();
	const [showingSignupModal, toggleSignupModal] = useModal();
	const user = useSelector((appState) => appState.userInfoReducer.userInfo);

	const TabComponent = tabsComponents[selectedTab];

	const {
		response: tournament = {},
		isLoaded
		// setResponseWithData
	} = useAPI(`/tournaments/${id}`);

	// TEMP:
	// Remove after proper endpoint implementation
	// also remove "skip" from hook argument
	// useEffect(() => {
	// 	setResponseWithData(tournamentsFixture[id - 1]);
	// }, [id, setResponseWithData]);

	useEffect(() => {
		user.id && !user.steam && history.push('/connect-accounts');
	}, [user]);

	const onTournamentSignup = (teamId) =>
		tournamentSignup({
			teamId,
			tournamentId: id
		});

	return (
		isLoaded && (
			<>
				<HeaderContainer />
				<Container>
					<BoxedContainer maxWidth="lg" paddingTop={0}>
						<TournamentHeading
							tournament={tournament}
							onTournamentSignup={onTournamentSignup}
						/>
						<Box>
							<StyledTabs
								value={selectedTab}
								onChange={handleChange}
								aria-label="Tournament Tabs"
							>
								<StyledTab label="Resumo" />
								<StyledTab label="Chave" />
								<StyledTab label="Regras" />
								<StyledTab label="Equipes" />
								<StyledTab label="Partidas" />
							</StyledTabs>
						</Box>
					</BoxedContainer>

					<BoxedContainer
						className={classes.boxed}
						paddingTop="1.25rem"
						maxWidth={selectedTab === 2 ? false : 'lg'}
					>
						<TabComponent
							tournament={tournament}
							onTournamentSignup={toggleSignupModal}
						/>
						<Modal
							isShowingModal={showingSignupModal}
							toggleModal={toggleSignupModal}
							title="Inscrever time"
						>
							{/* <SignupTeam
							closeModal={toggleSignupModal}
							teamSize={tournament.teamSize}
							onSignup={onTournamentSignup}
						/> */}
						</Modal>
					</BoxedContainer>
				</Container>
			</>
		)
	);
};

export default TournamentContainer;
