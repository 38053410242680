import React, { useState } from 'react';
import style from './playersUnban.module.scss';
import MenuLateral from 'components/menu-lateral';
import { Button, InputText } from 'components/form';
import { unbanPlayer } from 'services/players';
import routes from 'routes';

export default function PlayerUnban() {
	const [playerId, setPlayerId] = useState();

	function goUnban(id) {
		unbanPlayer(id);
	}

	return (
		<>
			<div className={style.container}>
				<MenuLateral name="jogadores" routes={routes.players} />
				<div className={style.content}>
					<h1>Desbanir jogador</h1>
					<InputText
						label="ID do jogador"
						handleChange={(event) =>
							setPlayerId(event.target.value)
						}
					/>
					<Button
						label="Desbanir"
						handlerButton={() => goUnban(playerId)}
					/>
				</div>
			</div>
		</>
	);
}
