import { withStyles } from '@material-ui/core/styles';
import { TableRow } from '@material-ui/core';

const StyledTableRow = withStyles((theme) => ({
	root: {
		// backgroundColor: '#000000',
		// '&:nth-of-type(even)': {
		backgroundColor: theme.palette.lead,
		// }

		'&:first-of-type': {
			'& th:first-of-type': {
				borderTopLeftRadius: 10
			},
			'& th:last-of-type': {
				borderTopRightRadius: 10
			}
		},

		'&:last-of-type': {
			'& td:first-of-type': {
				// borderColor: 'white',
				borderBottomLeftRadius: 10
			},
			'& td:last-of-type': {
				// borderColor: 'white',
				borderBottomRightRadius: 10
			}
		}
	}
}))(TableRow);

export default StyledTableRow;
