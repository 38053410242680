import { makeStyles } from '@material-ui/core/styles';

export const stylesChatBubble = makeStyles(
	(theme) => ({
		root: {
			marginBottom: '1.5rem'
		},
		userName: {
			color: theme.palette.smoke,
			fontWeight: 'bold'
		},
		timestamp: {
			color: theme.palette.ash
		},
		message: {
			lineHeight: 1.15,
			fontSize: '12',
			color: theme.palette.smoke
		}
	}),
	{ name: 'ChatBubble' }
);

export const stylesChatBox = makeStyles(
	(theme) => ({
		info: {
			padding: 24,
			backgroundColor: (props) =>
				!props.transparent && theme.palette.gunpowder,
			borderRadius: 8
		},
		chatBox: {
			height: 420,
			overflow: 'auto'
		},
		noCommentsContainer: {
			height: '100%'
		},
		noComments: {
			fontWeight: 500,
			fontSize: 20,
			lineHeight: '36px',
			color: '#FFF',
			textAlign: 'center'
		},
		infoMarginTop: {
			marginTop: '3.25rem'
		},
		form: {
			marginRight: -8
		},
		inputWrapper: {
			flex: '1 0'
		},
		input: {
			width: '100%'
		}
	}),
	{ name: 'ChatBox' }
);
