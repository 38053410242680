import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Avatar } from '@material-ui/core';
import StyledTableRow from './StyledTableRow';
import StyledTableCell from './StyledTableCell';

const useStyles = makeStyles(
	(theme) => ({
		teamName: {
			paddingLeft: '1rem'
		},
		qualified: {
			borderLeftWidth: 5,
			borderLeftColor: theme.palette.primary.main,
			color: theme.palette.primary.main,
			fontWeight: 'bold'
		},
		avatar: {
			width: '1.875rem',
			height: '1.875rem'
		}
	}),
	{ name: 'RowTeamStats' }
);

const RowTeamStats = ({ row, idx }) => {
	const classes = useStyles();
	const cellClass = row.isQualified ? classes.qualified : undefined;
	return (
		<StyledTableRow key={`team-${idx}`}>
			<StyledTableCell component="td" scope="row" className={cellClass}>
				<Grid container alignItems="center">
					<Avatar
						alt={`${row.name} avatar`}
						src={row.avatar}
						className={classes.avatar}
					/>
					<Grid item className={classes.teamName}>
						{row.name}
					</Grid>
				</Grid>
			</StyledTableCell>
			<StyledTableCell align="center" className={cellClass}>
				{row.matchesPlayed}/{row.matchesTotal || 0}
			</StyledTableCell>
			<StyledTableCell align="center" className={cellClass}>
				{row.winnings}
			</StyledTableCell>
			<StyledTableCell align="center" className={cellClass}>
				{row.losses}
			</StyledTableCell>
			<StyledTableCell align="center" className={cellClass}>
				{row.draws || 0}
			</StyledTableCell>
			<StyledTableCell align="center" className={cellClass}>
				{row.rg || 0}
			</StyledTableCell>
			<StyledTableCell align="center" className={cellClass}>
				{row.rp || 0}
			</StyledTableCell>
			<StyledTableCell align="center" className={cellClass}>
				{row.sr || 0}
			</StyledTableCell>
			<StyledTableCell align="center" className={cellClass}>
				{row.points || 0}
			</StyledTableCell>
		</StyledTableRow>
	);
};

export default RowTeamStats;
