import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TeamCard from './TeamCard';
import BracketConnector from './Connector';
import MatchWrapper from './MatchWrapper';

const useStyles = makeStyles(
	() => ({
		root: {
			display: 'flex',
			justifyContent: 'space-around',
			flexDirection: 'column',
			width: 'fit-content',
			marginLeft: 77,
			'&:first-child': {
				marginLeft: 0
			}
		}
	}),
	{ name: 'TierWrapper' }
);

const TierWrapper = ({ tier, isFinal, idx }) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			{tier.map((match, k) => {
				const isBottomMatch = !(k % 2);
				return (
					<MatchWrapper tierPos={idx} key={`tier-${idx}-match-${k}`}>
						{match.map((team, l) => (
							<TeamCard
								team={team}
								key={`tier-${idx}-match-${k}-team-${l}`}
							/>
						))}
						{!isFinal && (
							<BracketConnector
								visible={isBottomMatch}
								level={idx}
							/>
						)}
					</MatchWrapper>
				);
			})}
		</div>
	);
};

export default TierWrapper;
