import React, { useState, useEffect } from 'react';
import style from './matches.module.scss';
import MenuLateral from 'components/menu-lateral';
import { getLiveMatches, cancelMatch } from 'services/matches';
import Dialog from 'components/dialog';
import routes from 'routes';

export default function Matches() {
	const [matches, setMaches] = useState();
	const [statusModal, setStatusModal] = useState(false);
	const [matchForCancel, setMatchForCancel] = useState();
	const [statusInfoMatch, setStatusInfoMatch] = useState();
	const [infoMatchSelected, setInfoMatchSelected] = useState();

	useEffect(() => {
		(async () => {
			try {
				const { data } = await getLiveMatches();
				setMaches(data);
			} catch (error) {
				console.error(error);
			}
		})();
	}, []);

	function goDialogCancelMatch(match) {
		setStatusModal(true);
		setMatchForCancel(match);
	}

	function closeDialog() {
		setStatusModal(false);
	}

	function goCancelMatch(match) {
		closeDialog();
		cancelMatch(match.id);
	}

	function closeDialogInfoMatch() {
		setStatusInfoMatch(false);
	}

	function openInfosMatch(match) {
		setStatusInfoMatch(true);
		setInfoMatchSelected(match);
	}

	return (
		<>
			<Dialog
				open={statusModal}
				handlerClick={() => goCancelMatch(matchForCancel)}
				close={() => closeDialog()}
			>
				<p>Deseja realmente cancelar esta partida?</p>
			</Dialog>
			<Dialog
				noButton
				open={statusInfoMatch}
				handlerClick={() => goCancelMatch(matchForCancel)}
				close={() => closeDialogInfoMatch()}
			>
				<div>
					<p>id: {infoMatchSelected?.id}</p>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-evenly'
						}}
					>
						<div>
							<p>Team A</p>
							<br />
							<ul>
								{infoMatchSelected &&
									infoMatchSelected.teamA &&
									infoMatchSelected.teamA.map((player) => {
										return (
											<li>
												<p>
													{player.name} - {player.id}
												</p>
											</li>
										);
									})}
							</ul>
						</div>
						<div>
							<p>Team B</p>
							<br />
							<ul>
								{infoMatchSelected &&
									infoMatchSelected.teamB &&
									infoMatchSelected.teamB.map((player) => {
										return (
											<li>
												<p>
													{player.name} - {player.id}
												</p>
											</li>
										);
									})}
							</ul>
						</div>
					</div>
				</div>
			</Dialog>
			<div className={style.container}>
				<MenuLateral name="partidas" routes={routes.matches} />
				<div className={style.content}>
					<h1>Listar partidas</h1>
					<div>
						<table className={style.table}>
							<thead>
								<tr>
									<th>#</th>
									<th>Partida</th>
									<th>Mapa</th>
									<th>Liga</th>
								</tr>
							</thead>
							<tbody>
								{matches &&
									matches.map((match, index) => {
										return (
											<tr>
												<td
													onClick={() =>
														openInfosMatch(match)
													}
												>
													{index + 1}
												</td>
												<td
													onClick={() =>
														openInfosMatch(match)
													}
												>{`${match.teamA[0].name} X ${match.teamB[0].name}`}</td>
												<td
													onClick={() =>
														openInfosMatch(match)
													}
												>
													{match.map}
												</td>
												<td
													onClick={() =>
														openInfosMatch(match)
													}
												>
													{match.queueId}
												</td>
												<td
													className={style.cancel}
													onClick={() =>
														goDialogCancelMatch(
															match
														)
													}
												>
													Cancelar partida
												</td>
											</tr>
										);
									})}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	);
}
