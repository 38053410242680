import React from 'react';

import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Link from 'components/shared/Link';
import NUKE from 'assets/images/nuke.map.png';
import INFERNO from 'assets/images/inferno.map.png';
import DUST2 from 'assets/images/dust2.map.png';
import OVERPASS from 'assets/images/overpass.map.png';
import TRAIN from 'assets/images/train.map.png';
import VERTIGO from 'assets/images/vertigo.map.png';
import MIRAGE from 'assets/images/mirage.map.png';
import TeamScore from './TeamScore';
import { DateMapText } from './styles';
import { parseDateAsReadable } from '../helpers';

const mapImages = [
	{
		name: 'NUKE',
		image: NUKE
	},
	{
		name: 'INFERNO',
		image: INFERNO
	},
	{
		name: 'DUST2',
		image: DUST2
	},
	{
		name: 'OVERPASS',
		image: OVERPASS
	},
	{
		name: 'TRAIN',
		image: TRAIN
	},
	{
		name: 'VERTIGO',
		image: VERTIGO
	},
	{
		name: 'MIRAGE',
		image: MIRAGE
	}
];
const mapSelector = (name) => mapImages.filter((m) => m.name === name)[0];

const useStyles = makeStyles(
	(theme) => ({
		root: {
			backgroundColor: '#1B1D20',
			backgroundImage: (props) => `url(${props.mapImage?.image})`,
			backgroundBlendMode: 'overlay',
			backgroundPosition: 'center',
			backgroundSize: 'cover',
			boxShadow: 'inset 0 0 80px #000000',
			height: '3.875rem',
			borderRadius: '0.5rem',
			textAlign: 'center',
			marginBottom: '1rem'
		},
		middleWrapper: {
			height: '100%'
		},
		score: {
			color: theme.palette.smoke,
			fontSize: '1.125rem',
			lineHeight: 1
		}
	}),
	{ name: 'MatchInfo' }
);

const MatchInfo = ({ match, tournamentId, ...props }) => {
	const { id, teamA, teamB, startTime, vetoes, scoreboard } = match;
	const printedDate = parseDateAsReadable(startTime);
	const map = vetoes?.vetoes.filter((mp) => mp.action === 'pick')[0];
	const mapImage = mapSelector(map?.mapName);
	const classes = useStyles({ mapImage });

	return (
		<Grid
			{...props}
			className={classes.root}
			component={Link}
			to={`/match/${tournamentId}/${id}`}
		>
			{teamA && (
				<TeamScore
					team={teamA}
					winner={scoreboard && scoreboard[0]?.teamA.isWinner}
					score={(scoreboard && scoreboard[0]?.teamA.score) || 0}
				/>
			)}
			<Grid item className={classes.middleWrapper}>
				<Grid
					container
					direction="column"
					justify="space-between"
					alignItems="center"
					className={classes.middleWrapper}
				>
					<DateMapText>{printedDate}</DateMapText>
					<Typography className={classes.score} align="center">
						VS
					</Typography>
					<DateMapText>{map?.mapName || 'NO_MAP'}</DateMapText>
				</Grid>
			</Grid>
			{teamB && (
				<TeamScore
					position="right"
					team={teamB}
					winner={scoreboard && scoreboard[0]?.teamB.isWinner}
					score={(scoreboard && scoreboard[0]?.teamB.score) || 0}
				/>
			)}
		</Grid>
	);
};

export default MatchInfo;
