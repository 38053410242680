import {
	Avatar,
	CardHeader,
	Flex,
	TableCell,
	TableRow,
	Typography
} from '@coliseum.gg/ui';
import React from 'react';
import theme from 'theme';

export const ContentItem = ({ post, onClick }) => {
	return (
		<TableRow
			onClick={onClick}
			css={{
				'&:hover': {
					color: '#FFF',
					cursor: 'pointer',
					background: theme.palette.lead
				}
			}}
		>
			<TableCell css={{ width: '12%' }}>
				<CardHeader css={{ width: '150px' }} src={post.thumbnail} />
			</TableCell>
			<TableCell css={{ width: '20%', wordBreak: 'break-all' }}>
				<Typography variant="h5"> {post.title}</Typography>
			</TableCell>
			<TableCell css={{ width: '20%' }}>
				<Typography variant="body"> {post.content}</Typography>
			</TableCell>
			<TableCell css={{ width: '15%' }}>
				<Flex gap="16" align="center">
					<Avatar src={post.author.avatar} />
					<Typography>{post.author.username}</Typography>
				</Flex>
			</TableCell>
			<TableCell css={{ width: '30%' }}>
				<Typography variant="body">
					<a
						href={post.video}
						target="_blank"
						style={{ textDecoration: 'none' }}
					>
						{post.video}
					</a>
				</Typography>
			</TableCell>
			<TableCell css={{ width: '10%' }}>
				<Typography variant="body">
					{post.featured ? 'Sim' : 'Não'}
				</Typography>
			</TableCell>
		</TableRow>
	);
};
