import React from 'react'
import style from './inputText.module.scss'

export default function InputText({ label, handleChange, value, type = 'text' }) {
    return (
        <div className={style.inputGroup}>
            <label className={style.label}>{label}:</label>
            <input className={style.input} type={type} value={value} onChange={handleChange}/>
        </div>
    )
}
