import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(
	({ palette }) => ({
		wrapper: {
			backgroundColor: palette.nocturne,
			color: '#ffffff',
			paddingTop: ({ paddingTop }) => paddingTop
		}
	}),
	{ name: 'BoxedContainer' }
);

const BoxedContainer = ({ maxWidth, paddingTop, children }) => {
	const classes = useStyles({ paddingTop });

	return (
		<Box className={classes.wrapper}>
			<Container maxWidth={maxWidth}>{children}</Container>
		</Box>
	);
};

BoxedContainer.defaultProps = {
	maxWidth: 'lg',
	paddingTop: '7.5rem'
};

export default BoxedContainer;
