import { useCallback } from 'react';
import { API } from 'services/api';
import usePromise from './usePromise';

const useAPI = (endpoint, { method = 'get', payload, skip = false } = {}) => {
	const handler = useCallback(() => API[method](endpoint, payload), [
		endpoint
	]);
	return usePromise(handler, skip);
};

export default useAPI;
