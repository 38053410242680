import React from 'react';
import InfoScoreBoard from '../InfoScoreBoard';

const MatchInfo = ({ match }) => {
	match.teamA = match.teamOne;
	match.teamB = match.teamTwo;

	return (
		<InfoScoreBoard
			results={[match]}
			avatarSize="small"
			hideName
			noStyling
		/>
	);
};

export default MatchInfo;
