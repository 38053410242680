import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const DateMapText = withStyles((theme) => ({
	root: {
		fontSize: '0.75rem',
		lineHeight: 1,
		color: theme.palette.gandalf,
		fontWeight: 500,
		minWidth: 110
	}
}))(Typography);
