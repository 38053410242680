import MenuLateral from 'components/menu-lateral';
import style from './layout.module.scss';

const Layout = ({ children, routes, name }) => {
	return (
		<>
			<div className={style.container}>
				<MenuLateral name={name} routes={routes} />
				<div className={style.content}>{children}</div>
			</div>
		</>
	);
};

export default Layout;
