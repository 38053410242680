import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	Grid,
	Typography,
	Button,
	TextField,
	FormControl,
	Select,
	MenuItem,
	InputLabel,
	Switch,
	Slider,
	Input
} from '@material-ui/core';
import { ContentContainer } from './ContentContainer';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { createNewTournament, editTournament } from '../services/tournament';
import { format, isValid } from 'date-fns';
import clsx from 'clsx';
import { API } from 'services/api';

const useStyles = makeStyles(
	(theme) => ({
		gridpadding: {
			padding: theme.spacing(1.875)
		},
		header: {
			display: 'flex',
			justifyContent: 'flex-end'
		},
		headerbuttongroup: {
			display: 'flex',
			justifyContent: 'space-evenly',
			alignItems: 'center',
			'& button': {
				margin: theme.spacing(0, 1, 0, 1)
			}
		},
		footerbuttongroup: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			'& button': {
				margin: theme.spacing(0, 1, 0, 1)
			}
		},
		pagetitle: {
			fontWeight: 700,
			fontSize: 60,
			textTransform: 'uppercase',
			marginRight: 'auto'
		},
		title: {
			fontWeight: 500,
			fontSize: 30,
			textTransform: 'uppercase',
			marginTop: theme.spacing(5),
			marginBottom: theme.spacing(5)
		},
		inputtournament: {
			width: '100%',
			'& .MuiFilledInput-input': {
				padding: theme.spacing(1.25, 1.5, 1.5, 1.25)
			},
			'& .MuiFilledInput-underline:before': {
				borderBottom: '0px solid #000'
			},
			'& .MuiFilledInput-root': {
				backgroundColor: theme.palette.lead,
				borderRadius: 4
			},
			'& input[type=number]': {
				'-moz-appearance': 'textfield'
			},
			'& input[type=number]::-webkit-outer-spin-button': {
				'-webkit-appearance': 'none',
				margin: 0
			},
			'& input[type=number]::-webkit-inner-spin-button': {
				'-webkit-appearance': 'none',
				margin: 0
			}
		},
		placementtitle: {
			fontWeight: 500,
			fontSize: 18,
			padding: theme.spacing(0, 0, 2, 0),
			textTransform: 'uppercase'
		},
		detailstitle: {
			fontWeight: 500,
			fontSize: 18,
			padding: theme.spacing(2, 0, 1, 0),
			textTransform: 'uppercase'
		},
		griddetails: {
			padding: theme.spacing(1, 0, 5, 0)
		},
		infopreview: {
			display: 'flex',
			justifyContent: 'space-between',
			margin: theme.spacing(0, 2, 1, 0)
		},
		rulespreview: {
			display: 'flex',
			justifyContent: 'flex-end',
			margin: theme.spacing(0, 2, 1, 0)
		},
		inputrules: {
			width: '100%',
			padding: theme.spacing(0, 0.5, 0, 0),
			'& .MuiFilledInput-input': {
				padding: theme.spacing(1.25, 1.5, 1.5, 1.25)
			},
			'& .MuiFilledInput-underline:before': {
				borderBottom: '0px solid #000'
			},
			'& .MuiFilledInput-root': {
				backgroundColor: theme.palette.lead,
				borderRadius: 4
			}
		},

		inputrulespreview: {
			minHeight: '285px',
			width: '100%',
			padding: theme.spacing(1.25, 1.5, 1.5, 1.25),
			backgroundColor: theme.palette.lead,
			borderRadius: 4
		},
		textdetails: {
			color: theme.palette.gandalf,
			fontSize: 12,
			textAlign: 'center',
			margin: theme.spacing(2, 0, 3.125, 0)
		},
		inputplacement: {
			width: '85%',
			padding: theme.spacing(0, 1, 0, 0),
			'& .MuiFilledInput-input': {
				padding: theme.spacing(1.25, 1.5, 1.5, 1.25)
			},
			'& .MuiFilledInput-underline:before': {
				borderBottom: '0px solid #000'
			},
			'& .MuiFilledInput-root': {
				backgroundColor: theme.palette.lead,
				borderRadius: 4
			}
		},
		gridplacementupload: {
			marginBottom: theme.spacing(2)
		},
		buttondetails: {
			width: '12%',
			marginRight: theme.spacing(1)
		},
		buttonphotoupload: {
			height: 430,
			width: 430,
			borderRadius: 28,
			backgroundColor: theme.palette.ash,
			'&.MuiButton-containedPrimary': {
				color: theme.palette.smoke
			},
			'& .MuiSvgIcon-root': {
				minHeight: 75,
				minWidth: 100
			}
		},

		coverphotoupload: {
			height: 370,
			width: 800,
			borderRadius: 28,
			backgroundColor: theme.palette.ash,
			'&.MuiButton-containedPrimary': {
				color: theme.palette.smoke
			},
			'& .MuiSvgIcon-root': {
				minHeight: 75,
				minWidth: 100
			}
		},

		gridphotoupload: {
			width: 430,
			margin: theme.spacing(3, 'auto', 2.75, 'auto')
		},
		inputphotoupload: {
			width: '85%',
			padding: theme.spacing(0, 1, 0, 0),
			'& .MuiFilledInput-input': {
				padding: theme.spacing(1.25, 1.5, 1.5, 1.25)
			},
			'& .MuiFilledInput-underline:before': {
				borderBottom: '0px solid #000'
			},
			'& .MuiFilledInput-root': {
				backgroundColor: theme.palette.lead,
				borderRadius: 4
			}
		},
		textphoto: {
			fontSize: 12,
			color: theme.palette.gandalf,
			textAlign: 'center'
		},
		buttoninviteorganization: {
			width: '100%',
			marginTop: theme.spacing(2)
		},
		datepicker: {
			backgroundColor: theme.palette.lead,
			borderRadius: 4,
			margin: theme.spacing(1, 0, 1, 0),
			'& .MuiInputBase-input': {
				marginLeft: theme.spacing(1.25)
			},
			'& .MuiInputLabel-formControl': {
				top: 2,
				left: 10
			},
			'& .MuiInput-underline:before': {
				borderBottom: '0px solid'
			},
			'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
				borderBottom: '0px solid'
			},
			'& .MuiIconButton-root': {
				color: theme.palette.smoke
			}
		},
		formselect: {
			'& label': {
				zIndex: 2,
				marginLeft: theme.spacing(1.25)
			},
			'& .MuiInput-underline:before': {
				borderBottom: '0px solid'
			},
			'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
				borderBottom: '0px solid'
			}
		},
		select: {
			backgroundColor: theme.palette.lead,
			borderRadius: 4,
			margin: theme.spacing(1, 0, 1, 0),
			'& .MuiSelect-icon': {
				color: theme.palette.smoke
			},
			'& > div': {
				marginLeft: theme.spacing(1.25)
			}
		},
		inscriptiontext: {
			color: theme.palette.smoke
		},
		gridtoggle: {
			margin: theme.spacing(1, 0, 1, 0)
		},
		inputslider: {
			'& > input': {
				textAlign: 'center',
				backgroundColor: theme.palette.lead,
				borderRadius: 4
			},
			'&.MuiInput-underline:before': {
				borderBottom: '0px solid'
			},
			'&.MuiInput-underline:hover:not(.Mui-disabled):before': {
				borderBottom: '0px solid'
			}
		},
		titlevacancy: {
			marginBottom: theme.spacing(2)
		}
	}),
	{ name: 'TournamentCreateContainer' }
);

const TournamentCreateContainer = ({ tournamentId }) => {
	const classes = useStyles();
	const [valueTotalTeamsMinMax, setValueTotalTeamsMinMax] = useState([
		8, 512
	]);

	const ENTRY_TYPE_TEXT = ['Free', 'Premium', 'Invite', 'Paid'];

	const [infoPreview, setInfoPreview] = useState(false);
	const [rulesPreview, setRulesPreview] = useState(false);

	const [tournament, setNewTournament] = useState({
		name: '',
		teamSize: 5,
		startDate: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
		endDate: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
		registrationEndDate: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
		registrationStartDate: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
		checkInEndDate: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
		checkInStartDate: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
		skillLevel: 10,
		rulesBook: '',
		info: '',
		logo: '',
		game: 'LOL',
		entryType: 0,
		rulesBookPDF: '',
		prizeFirstPlaceImage: '',
		prizeSecondPlaceImage: '',
		prizeThirdPlaceImage: '',
		isInviteOnly: true,
		isPremiumOnly: false,
		organizerId: 0,
		description: '',
		cover: ''
	});

	const [isLoading, setIsLoading] = useState(false);

	function checkDate(date) {
		if (date === null) return date;
		return isValid(date)
			? format(date, 'yyyy-MM-dd HH:mm:ss')
			: String(date).replace('T', ' ').substring(0, 19);
	}

	const handleEntryType = (entryType) => {
		return ENTRY_TYPE_TEXT.indexOf(entryType);
	};

	useEffect(() => {
		if (tournamentId) {
			setIsLoading(true);
			const handler = () => API.get(`/tournaments/${tournamentId}`);
			handler()
				.then(({ data }) => {
					setNewTournament({
						name: data.name,
						teamSize: data.teamSize,
						startDate: checkDate(data.startDate.replace()),
						endDate: checkDate(data.endDate),
						registrationEndDate: checkDate(
							data.registrationEndDate
						),
						registrationStartDate: checkDate(
							data.registrationStartDate
						),
						checkInEndDate: checkDate(data.checkinEndDate),
						checkInStartDate: checkDate(data.checkinStartDate),
						skillLevel: data.skillLevel,
						rulesBook: data.rulesBook,
						info: data.information,
						logo: data.logo,
						game: data.game,
						rulesBookPDF: data.rulesBookPDF,
						isInviteOnly: data.isInviteOnly,
						isPremiumOnly: data.isPremiumOnly,
						organizerId: data.organizer.id,
						entryType: handleEntryType(data.entryType),
						maxEntries: data.maxEntries,
						minEntries: data.minEntries
					});
					setValueTotalTeamsMinMax([
						data.minEntries,
						data.maxEntries
					]);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	}, [tournamentId]);

	function handleSetIsInviteOnly() {
		setNewTournament((oldTournament) => ({
			...oldTournament,
			isInviteOnly: !oldTournament.isInviteOnly
		}));
	}

	function handleSetPremiumOnly() {
		setNewTournament((oldTournament) => ({
			...oldTournament,
			isPremiumOnly: !oldTournament.isPremiumOnly
		}));
	}

	const handleSliderChange = (event, newValue) => {
		setValueTotalTeamsMinMax(newValue);
	};

	const handleMinTeamSize = (event) => {
		setValueTotalTeamsMinMax([
			Number(event.target.value),
			valueTotalTeamsMinMax[1]
		]);
	};

	const handleDateInput = (date, key) => {
		setNewTournament((oldTournament) => ({
			...oldTournament,
			[key]: checkDate(date)
		}));
	};

	const handleMaxTeamSize = (event) => {
		setValueTotalTeamsMinMax([
			valueTotalTeamsMinMax[0],
			Number(event.target.value)
		]);
	};

	const handleBlur = () => {
		switch (true) {
			case valueTotalTeamsMinMax[0] < 0:
				setValueTotalTeamsMinMax([0, valueTotalTeamsMinMax[1]]);
				break;
			case valueTotalTeamsMinMax[1] < 0:
				setValueTotalTeamsMinMax([valueTotalTeamsMinMax[0], 0]);
				break;
			case valueTotalTeamsMinMax[0] > 512:
				setValueTotalTeamsMinMax([512, valueTotalTeamsMinMax[1]]);
				break;
			case valueTotalTeamsMinMax[1] > 512:
				setValueTotalTeamsMinMax([valueTotalTeamsMinMax[0], 512]);
				break;
			default:
				setValueTotalTeamsMinMax([
					valueTotalTeamsMinMax[0],
					valueTotalTeamsMinMax[1]
				]);
		}
	};

	function handleChangeInput(event) {
		const { value, name: key, type } = event.target;
		const isNumber = type === 'number';
		setNewTournament((oldTournament) => ({
			...oldTournament,
			[key]: isNumber ? Number(value) : value
		}));
	}

	function handleInfoPreview() {
		setInfoPreview(!infoPreview);
	}
	function handleRulesPreview() {
		setRulesPreview(!rulesPreview);
	}

	const returnString = (teste) => {
		let text = '';
		Object.entries(teste).forEach(([key, value]) => {
			return (text += value + ':' + key + '\n');
		});
		alert(text);
	};

	function sendValues() {
		const {
			name,
			teamSize,
			skillLevel,
			rulesBook,
			info,
			logo,
			game,
			entryType,
			isInviteOnly,
			isPremiumOnly,
			startDate,
			endDate,
			checkInEndDate,
			checkInStartDate,
			registrationEndDate,
			registrationStartDate,
			organizerId,
			description,
			cover
		} = tournament;
		const newTournament = {
			name,
			teamSize,
			organizerId,
			skillLevel,
			rulesBook,
			info,
			logo,
			game,
			entryType,
			isInviteOnly,
			isPremiumOnly,
			startDate,
			endDate,
			registrationEndDate,
			registrationStartDate,
			checkInEndDate,
			checkInStartDate,
			organizerId,
			description,
			cover,
			minEntries: valueTotalTeamsMinMax[0],
			maxEntries: valueTotalTeamsMinMax[1]
		};
		setIsLoading(true);
		if (newTournament.organizerId === 0) {
			alert('Você precisa selecionar um organizador');
			setIsLoading(false);
			return;
		}

		if (tournamentId) {
			editTournament(tournamentId, newTournament)
				.then(() => alert('Torneio editado com sucesso!'))
				.catch((error) => {
					console.log(error);
					error?.response?.data
						? returnString(error?.response?.data)
						: alert(`Ocorreu um erro ao editar o torneio.`);
				})
				.finally(() => setIsLoading(false));
		} else {
			createNewTournament(newTournament)
				.then(() => alert('Torneio criado com sucesso!'))
				.catch((error) =>
					error?.response?.data
						? returnString(error?.response?.data)
						: alert(`Ocorreu um erro ao criar o torneio.`)
				)
				.finally(() => setIsLoading(false));
		}
	}

	return (
		<>
			<ContentContainer>
				<Grid
					item
					container
					xs={12}
					className={clsx(classes.header, classes.gridpadding)}
				>
					<Typography className={classes.pagetitle}>
						{tournamentId
							? 'editar campeonato'
							: 'criar campeonato'}
					</Typography>
					<div className={classes.headerbuttongroup}>
						<Button
							color="primary"
							variant="outlined"
							className={classes.buttoncancel}
						>
							Cancelar
						</Button>
						<Button
							disabled={isLoading}
							color="primary"
							variant="contained"
							onClick={sendValues}
						>
							Salvar
						</Button>
					</div>
				</Grid>

				<Grid item xs={12} className={classes.gridpadding}>
					<Typography className={classes.title}>Nome</Typography>
					<TextField
						value={tournament.name}
						className={classes.inputtournament}
						placeholder="Nome do Campeonato"
						variant="filled"
						onChange={(e) => handleChangeInput(e)}
						name="name"
					/>
				</Grid>

				<Grid xs={12} className={classes.gridpadding} item>
					<Typography className={classes.title}>Foto</Typography>
					<Grid container justifyContent="center" alignItems="center">
						<label htmlFor="photo-upload-button-file">
							{tournament.logo ? (
								<img
									src={tournament.logo}
									className={classes.buttonphotoupload}
								/>
							) : (
								<div className={classes.buttonphotoupload} />
							)}
						</label>
					</Grid>

					<Grid
						container
						direction="row"
						justifyContent="center"
						alignItems="center"
						className={classes.gridphotoupload}
					>
						<TextField
							className={classes.inputtournament}
							placeholder="URL da imagem"
							variant="filled"
							name="logo"
							onChange={(e) => handleChangeInput(e)}
							value={tournament.logo}
						/>
					</Grid>
					<Typography className={classes.textphoto}>
						Imagem em formato PNG e será exibida 126 x 147
					</Typography>
				</Grid>

				<Grid xs={12} className={classes.gridpadding} item>
					<Typography className={classes.title}>Capa</Typography>
					<Grid container justifyContent="center" alignItems="center">
						<label htmlFor="photo-upload-button-file">
							{tournament.cover ? (
								<img
									src={tournament.cover}
									className={classes.coverphotoupload}
								/>
							) : (
								<div className={classes.coverphotoupload} />
							)}
						</label>
					</Grid>

					<Grid
						container
						direction="row"
						justifyContent="center"
						alignItems="center"
						className={classes.gridphotoupload}
					>
						<TextField
							className={classes.inputtournament}
							placeholder="URL da imagem"
							variant="filled"
							name="cover"
							onChange={(e) => handleChangeInput(e)}
							value={tournament.cover}
						/>
					</Grid>
					<Typography className={classes.textphoto}>
						Imagem em formato PNG
					</Typography>
				</Grid>

				<Grid container className={classes.gridpadding}>
					<Grid className={classes.gridpadding} xs={6} item>
						<Typography className={classes.title}>
							organizador
						</Typography>
						<TextField
							className={classes.inputtournament}
							placeholder="Organizadores"
							type="number"
							variant="filled"
							name="organizerId"
							value={tournament.organizerId}
							onChange={(e) => handleChangeInput(e)}
						/>
					</Grid>

					<Grid className={classes.gridpadding} xs={6} item>
						<Grid container direction="column">
							<Typography className={classes.title}>
								configuração
							</Typography>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<KeyboardDatePicker
									label="Data de início"
									placeholder="01/02/2021"
									onChange={(date) =>
										handleDateInput(date, 'startDate')
									}
									value={tournament.startDate}
									selected={tournament.startDate}
									format="dd/MM/yyyy HH:mm:ss"
									variant="dialog"
									color="primary"
									className={classes.datepicker}
									name="startDate"
								/>
							</MuiPickersUtilsProvider>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<KeyboardDatePicker
									label="Data de fim"
									placeholder="28/02/2021"
									selected={tournament.endDate}
									value={tournament.endDate}
									name="endDate"
									onChange={(date) =>
										handleDateInput(date, 'endDate')
									}
									format="dd/MM/yyyy HH:mm:ss"
									variant="dialog"
									color="primary"
									className={classes.datepicker}
								/>
							</MuiPickersUtilsProvider>

							<FormControl
								className={classes.formselect}
								fullWidth
							>
								<InputLabel id="game-type">Jogo</InputLabel>
								<Select
									labelId="game-type"
									id="game-type-select"
									value={tournament.game}
									onChange={(e) => handleChangeInput(e)}
									name="game"
									className={classes.select}
								>
									<MenuItem value={'LOL'}>
										League of Legends
									</MenuItem>
									<MenuItem value={'CSGO'}>CS:GO</MenuItem>
									<MenuItem value={'DOTA2'}>Dota 2</MenuItem>
									<MenuItem value={'WILDRIFT'}>
										Wild Rift
									</MenuItem>
									<MenuItem value={'COD'}>COD</MenuItem>
									<MenuItem value={'FIFA'}>FIFA</MenuItem>
									<MenuItem value={'VALORANT'}>
										VALORANT
									</MenuItem>
								</Select>
							</FormControl>

							<FormControl
								className={classes.formselect}
								fullWidth
							>
								<InputLabel id="entryType">Entrada</InputLabel>
								<Select
									labelId="entryType"
									id="entryType-select"
									name="entryType"
									value={tournament.entryType}
									onChange={(e) => handleChangeInput(e)}
									className={classes.select}
								>
									<MenuItem value={0}>Free</MenuItem>
									<MenuItem value={1}>Premium</MenuItem>
									<MenuItem value={2}>Invite</MenuItem>
									<MenuItem value={3}>Pago</MenuItem>
								</Select>
							</FormControl>

							<FormControl
								className={classes.formselect}
								fullWidth
							>
								<InputLabel id="game-players">
									Jogadores
								</InputLabel>
								<Select
									labelId="game-players"
									id="game-players-select"
									name="teamSize"
									value={tournament.teamSize}
									onChange={(e) => handleChangeInput(e)}
									className={classes.select}
								>
									<MenuItem value={1}>1v1</MenuItem>
									<MenuItem value={2}>2v2</MenuItem>
									<MenuItem value={3}>3v3</MenuItem>
									<MenuItem value={5}>5v5</MenuItem>
								</Select>
							</FormControl>

							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<KeyboardDatePicker
									label="Data de início do Check-in"
									placeholder="28/02/2021"
									value={tournament.checkInStartDate}
									name="checkInStartDate"
									onChange={(date) =>
										handleDateInput(
											date,
											'checkInStartDate'
										)
									}
									format="dd/MM/yyyy HH:mm:ss"
									variant="dialog"
									color="primary"
									className={classes.datepicker}
								/>
							</MuiPickersUtilsProvider>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<KeyboardDatePicker
									label="Data limite do Check-in"
									placeholder="28/02/2021"
									value={tournament.checkInEndDate}
									name="checkInEndDate"
									onChange={(date) =>
										handleDateInput(date, 'checkInEndDate')
									}
									format="dd/MM/yyyy HH:mm:ss"
									variant="dialog"
									color="primary"
									className={classes.datepicker}
								/>
							</MuiPickersUtilsProvider>
						</Grid>
					</Grid>

					<Grid className={classes.gridpadding} xs={6} item>
						<Typography className={classes.title}>
							premiação
						</Typography>
						<div>
							<Grid item>
								<Typography className={classes.placementtitle}>
									1° lugar
								</Typography>
								<Grid
									className={classes.gridplacementupload}
									container
									direction="row"
									justifyContent="space-between"
									alignItems="center"
								>
									<TextField
										className={classes.inputtournament}
										placeholder="URL da imagem"
										variant="filled"
										name="prizeFirstPlaceImage"
										value={tournament.prizeFirstPlaceImage}
										onChange={(e) => handleChangeInput(e)}
									/>
								</Grid>
							</Grid>
							<Grid item>
								<Typography className={classes.placementtitle}>
									2° lugar
								</Typography>
								<Grid
									className={classes.gridplacementupload}
									container
									direction="row"
									justifyContent="space-between"
									alignItems="center"
								>
									<TextField
										className={classes.inputtournament}
										placeholder="URL da imagem"
										variant="filled"
										name="prizeSecondPlaceImage"
										value={tournament.prizeSecondPlaceImage}
										onChange={(e) => handleChangeInput(e)}
									/>
								</Grid>
							</Grid>
							<Grid item>
								<Typography className={classes.placementtitle}>
									3° lugar
								</Typography>
								<Grid
									className={classes.gridplacementupload}
									container
									direction="row"
									justifyContent="space-between"
									alignItems="center"
								>
									<TextField
										className={classes.inputtournament}
										placeholder="URL da imagem"
										variant="filled"
										name="prizeThirdPlaceImage"
										value={tournament.prizeThirdPlaceImage}
										onChange={(e) => handleChangeInput(e)}
									/>
								</Grid>
							</Grid>
						</div>
					</Grid>

					<Grid className={classes.gridpadding} xs={6} item>
						<Grid container direction="column">
							<Typography className={classes.title}>
								inscrição
							</Typography>

							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<KeyboardDatePicker
									label="Data de início das inscrições"
									placeholder="01/02/2021"
									value={tournament.registrationStartDate}
									onChange={(date) =>
										handleDateInput(
											date,
											'registrationStartDate'
										)
									}
									name="registrationStartDate"
									format="dd/MM/yyyy HH:mm:ss"
									variant="dialog"
									color="primary"
									className={classes.datepicker}
								/>
							</MuiPickersUtilsProvider>

							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<KeyboardDatePicker
									label="Data limite das inscrições"
									placeholder="01/02/2021"
									value={tournament.registrationEndDate}
									onChange={(date) =>
										handleDateInput(
											date,
											'registrationEndDate'
										)
									}
									name="registrationEndDate"
									format="dd/MM/yyyy HH:mm:ss"
									variant="dialog"
									color="primary"
									className={classes.datepicker}
								/>
							</MuiPickersUtilsProvider>

							<Grid
								className={classes.gridtoggle}
								direction="row"
								container
								justifyContent="flex-start"
								alignItems="center"
							>
								<Typography className={classes.inscriptiontext}>
									Aceitar Inscrições
								</Typography>
								<Switch
									checked={!tournament.isInviteOnly}
									onChange={handleSetIsInviteOnly}
									color="primary"
									name="isInviteOnly"
									value={tournament.isInviteOnly}
								/>
							</Grid>

							<Grid
								className={classes.gridtoggle}
								direction="row"
								container
								justifyContent="flex-start"
								alignItems="center"
							>
								<Typography className={classes.inscriptiontext}>
									Somente assinantes
								</Typography>
								<Switch
									checked={tournament.isPremiumOnly}
									onChange={handleSetPremiumOnly}
									color="primary"
									name="isPremiumOnly"
									value={tournament.isPremiumOnly}
								/>
							</Grid>

							<Grid>
								<Typography className={classes.titlevacancy}>
									VAGAS (Em equipes)
								</Typography>

								<Grid
									container
									direction="row"
									justifyContent="center"
									alignItems="center"
								>
									<Grid
										container
										item
										xs={2}
										justifyContent="center"
										alignItems="center"
									>
										<Input
											className={classes.inputslider}
											value={valueTotalTeamsMinMax[0]}
											onChange={handleMinTeamSize}
											onBlur={handleBlur}
											inputProps={{
												step: 1,
												min: 0,
												max: 512,
												type: 'number'
											}}
										/>
									</Grid>

									<Grid container item xs={8}>
										<Slider
											defaultValue={[8, 512]}
											value={
												typeof valueTotalTeamsMinMax[0] ===
													'number' &&
												typeof valueTotalTeamsMinMax[1] ===
													'number'
													? valueTotalTeamsMinMax
													: [0, 0]
											}
											onChange={handleSliderChange}
											aria-labelledby="input-slider"
											max={512}
											valueLabelDisplay="auto"
										/>
									</Grid>

									<Grid
										container
										item
										xs={2}
										justifyContent="center"
										alignItems="center"
									>
										<Input
											className={classes.inputslider}
											value={valueTotalTeamsMinMax[1]}
											onChange={handleMaxTeamSize}
											onBlur={handleBlur}
											inputProps={{
												step: 1,
												min: 0,
												max: 512,
												type: 'number'
											}}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid item className={classes.gridpadding} xs={12}>
					<Typography className={classes.title}>detalhes</Typography>

					<Typography className={classes.detailstitle}>
						Descrição
					</Typography>
					<Grid className={classes.griddetailsrules}>
						<TextField
							className={classes.inputrules}
							label="Descrição do Campeonato"
							multiline
							rows={5}
							variant="filled"
							name="description"
							value={tournament.description}
							onChange={(e) => handleChangeInput(e)}
						/>
					</Grid>

					<Typography className={classes.detailstitle}>
						Regras
					</Typography>
					<Grid
						className={classes.griddetails}
						container
						direction="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<TextField
							className={classes.inputtournament}
							placeholder="Upload de regras - PDF"
							name="rulesBookPDF"
							type="text"
							variant="filled"
							onChange={(e) => handleChangeInput(e)}
							value={tournament.rulesBookPDF}
						/>
					</Grid>
					<Grid item className={classes.rulespreview}>
						<Button
							color="primary"
							variant="contained"
							onClick={handleRulesPreview}
						>
							Preview
						</Button>
					</Grid>
					<Grid className={classes.griddetailsrules}>
						{rulesPreview ? (
							<div
								className={classes.inputrulespreview}
								dangerouslySetInnerHTML={{
									__html: tournament.rulesBook
								}}
							/>
						) : (
							<TextField
								className={classes.inputrules}
								label="Regras"
								multiline
								rows={15}
								variant="filled"
								name="rulesBook"
								value={tournament.rulesBook}
								onChange={(e) => handleChangeInput(e)}
							/>
						)}
					</Grid>
					<Typography className={classes.textdetails}>
						HTML suportado.
					</Typography>
				</Grid>
				<Grid item className={classes.gridpadding} xs={12}>
					<Grid item className={classes.infopreview}>
						<Typography className={classes.detailstitle}>
							Info
						</Typography>
						<Button
							color="primary"
							variant="contained"
							onClick={handleInfoPreview}
						>
							Preview
						</Button>
					</Grid>
					<Grid className={classes.griddetailsrules}>
						{infoPreview ? (
							<div
								className={classes.inputrulespreview}
								dangerouslySetInnerHTML={{
									__html: tournament.info
								}}
							/>
						) : (
							<TextField
								className={classes.inputrules}
								label="Informações"
								multiline
								rows={15}
								variant="filled"
								name="info"
								value={tournament.info}
								onChange={(e) => handleChangeInput(e)}
							/>
						)}
					</Grid>
					<Typography className={classes.textdetails}>
						HTML suportado.
					</Typography>
				</Grid>

				<Grid
					item
					xs={12}
					className={clsx(classes.footer, classes.gridpadding)}
				>
					<div className={classes.footerbuttongroup}>
						<Button
							color="primary"
							variant="outlined"
							className={classes.buttoncancel}
						>
							Cancelar
						</Button>
						<Button
							disabled={isLoading}
							color="primary"
							variant="contained"
							onClick={sendValues}
						>
							Salvar
						</Button>
					</div>
				</Grid>
			</ContentContainer>
		</>
	);
};

export default TournamentCreateContainer;
