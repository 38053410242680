import { Close, ContentCard } from '@coliseum.gg/shared';
import { Button, css, Flex, styled, Typography } from '@coliseum.gg/ui';
import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

const styledFlex = css({
	variants: {
		variant: {
			root: {
				height: '600px',
				width: '378px',
				position: 'fixed',
				top: 0,
				zIndex: 1,
				right: '16px',
				backgroundColor: '#101213',
				padding: '40px 16px'
			},
			gap: {
				gap: '$32',
				width: `100%`
			}
		}
	}
});

const StyledButton = styled(Button, {
	width: '100px'
});

export const ContentItemActions = ({
	post,
	setSelectedPost,
	token,
	refetch,
	top
}) => {
	const [isLoading, setIsLoading] = useState(false);

	const history = useHistory();

	const apiUrl = process.env.REACT_APP_CONTENT_API_URL;

	const handlePost = (post, action = 'publish', body) => {
		const putAction = {
			delete: {
				url: `${apiUrl}/content/admin/delete/${post.id}`,
				confirmationText: 'deletar',
				successText: 'O post foi deletado com sucesso!'
			},
			feature: {
				url: `${apiUrl}/content/admin/feature/${post.id}`,
				confirmationText: 'destacar',
				successText: 'O post foi destacado com sucesso!'
			},
			publish: {
				url: `${apiUrl}/content/admin/publish/${post.id}`,
				confirmationText: 'publicar',
				successText: 'O post foi publicado com sucesso!'
			},
			restore: {
				url: `${apiUrl}/content/admin/delete/${post.id}`,
				confirmationText: 'restaurar',
				successText: 'O post foi restaurado com sucesso!'
			},
			unfeature: {
				url: `${apiUrl}/content/admin/feature/${post.id}`,
				confirmationText: 'remover dos destaques',
				successText: 'O post foi removido dos destaques com sucesso!'
			},
			unpublish: {
				url: `${apiUrl}/content/admin/publish/${post.id}`,
				confirmationText: 'despublicar',
				successText: 'O post foi despublicado com sucesso!'
			}
		}[action];
		if (
			window.confirm(
				`Deseja realmente ${putAction.confirmationText} o post?`
			)
		) {
			setIsLoading(true);
			axios
				.put(putAction.url, body, {
					headers: { Authorization: `Bearer ${token}` }
				})
				.catch((e) => alert(e))
				.then((response) => {
					alert(putAction.successText);
					console.log(response);
					setSelectedPost({
						...post,
						published: response.data.published,
						featured: response.data.featured,
						deleted: response.data.deleted
					});
				})
				.finally(() => {
					setIsLoading(false);
					refetch((oldState) => !oldState);
				});
		}
	};
	return (
		<Flex className={styledFlex({ variant: `root` })}>
			<Close
				style={{
					position: 'absolute',
					color: 'white',
					width: '32px',
					height: '32px',
					cursor: 'pointer'
				}}
				onClick={() => setSelectedPost(null)}
			/>
			<Flex
				direction="column"
				className={styledFlex({ variant: `gap` })}
				align="center"
			>
				<Typography variant="h4">Preview</Typography>
				<ContentCard
					post={post}
					author={post.author}
					onClick={() => console.log('Clicked')}
				/>
				<Flex
					justify="center"
					className={styledFlex({ variant: `gap` })}
				>
					<StyledButton
						size="large"
						rounded="small"
						disabled={post.deleted || isLoading}
						onClick={() => history.push(`/content/edit/${post.id}`)}
					>
						Editar
					</StyledButton>
					{post.published ? (
						<StyledButton
							size="large"
							disabled={post.deleted || isLoading}
							rounded="small"
							onClick={() =>
								handlePost(post, 'unpublish', {
									published: false
								})
							}
						>
							Despublicar
						</StyledButton>
					) : (
						<StyledButton
							size="large"
							disabled={post.deleted || isLoading}
							rounded="small"
							onClick={() =>
								handlePost(post, 'publish', { published: true })
							}
						>
							Publicar
						</StyledButton>
					)}
				</Flex>
				<Flex
					justify="center"
					className={styledFlex({ variant: `gap` })}
				>
					{post.featured ? (
						<StyledButton
							size="large"
							rounded="small"
							disabled={post.deleted || isLoading}
							onClick={() =>
								handlePost(post, 'unfeature', {
									featured: false
								})
							}
						>
							Remover Destaque
						</StyledButton>
					) : (
						<StyledButton
							size="large"
							rounded="small"
							disabled={post.deleted || isLoading}
							onClick={() =>
								handlePost(post, 'feature', { featured: true })
							}
						>
							Destacar
						</StyledButton>
					)}
					{post.deleted ? (
						<StyledButton
							size="large"
							rounded="small"
							disabled={isLoading}
							color="secondary"
							onClick={() =>
								handlePost(post, 'restore', { deleted: false })
							}
						>
							Restaurar
						</StyledButton>
					) : (
						<StyledButton
							size="large"
							rounded="small"
							disabled={isLoading}
							color="secondary"
							onClick={() =>
								handlePost(post, 'delete', { deleted: true })
							}
						>
							Deletar
						</StyledButton>
					)}
				</Flex>
			</Flex>
		</Flex>
	);
};
