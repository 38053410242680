import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';

import { useAPI } from 'hooks';
import GroupWrapper from './Groups/GroupWrapper';

const useStyles = makeStyles(
	() => ({
		root: {
			minHeight: '65vh',
			width: '100%',
			maxWidth: 1460,
			paddingBottom: 10
			// marginLeft: 'auto'
		}
	}),
	{ name: 'TournamentGroups' }
);

const TournamentContentGroups = ({ tournament }) => {
	const classes = useStyles();
	const { id } = tournament;
	const {
		response: groups = [],
		isLoaded
		// setResponseWithData
	} = useAPI(`/tournaments/${id}/groups`);

	return (
		<Grid container spacing={4} className={classes.root}>
			{isLoaded && groups.length ? (
				groups.map((group, idx) => (
					<GroupWrapper
						xs={12}
						lg={6}
						key={`group-${idx}`}
						statistics={group.lineups}
						title={group.name}
					/>
				))
			) : (
				<Grid item xs={12}>
					<Typography>
						{isLoaded ? 'Grupos em breve...' : 'Carregando...'}
					</Typography>
				</Grid>
			)}
		</Grid>
	);
};

export default TournamentContentGroups;
