import teamOneLogo from 'assets/images/mstrmind.png';
import teamTwoLogo from 'assets/images/invaders.png';
import mapNuke from 'assets/images/nuke.map.png';

export default [
	{
		id: 3,
		teamOne: {
			avatar: teamOneLogo,
			name: 'MSTRMIND Gaming',
			score: 16,
			hasWon: true
		},
		teamTwo: {
			avatar: teamTwoLogo,
			name: 'Team INVADERS',
			score: 11,
			hasWon: false
		},
		matchDate: '2020-03-16T20:51:18+00:00',
		map: {
			name: 'Nuke',
			image: mapNuke
		}
	},
	{
		id: 4,
		teamOne: {
			avatar: teamOneLogo,
			name: 'MSTRMIND Gaming',
			score: 16,
			hasWon: true
		},
		teamTwo: {
			avatar: teamTwoLogo,
			name: 'Team INVADERS',
			score: 11,
			hasWon: false
		},
		matchDate: '2020-03-16T20:51:18+00:00',
		map: {
			name: 'Nuke',
			image: mapNuke
		}
	},
	{
		id: 5,
		teamOne: {
			avatar: teamOneLogo,
			name: 'MSTRMIND Gaming',
			score: 16,
			hasWon: true
		},
		teamTwo: {
			avatar: teamTwoLogo,
			name: 'Team INVADERS',
			score: 11,
			hasWon: false
		},
		matchDate: '2020-03-16T20:51:18+00:00',
		map: {
			name: 'Nuke',
			image: mapNuke
		}
	},
	{
		id: 6,
		teamOne: {
			avatar: teamOneLogo,
			name: 'MSTRMIND Gaming',
			score: 16,
			hasWon: true
		},
		teamTwo: {
			avatar: teamTwoLogo,
			name: 'Team INVADERS',
			score: 11,
			hasWon: false
		},
		matchDate: '2020-03-16T20:51:18+00:00',
		map: {
			name: 'Nuke',
			image: mapNuke
		}
	}
];
