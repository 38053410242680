import React from 'react';
import MenuLateral from 'components/menu-lateral';
import style from './createTournaments.module.scss';
import TournamentCreateContainer from 'container/TournamentCreateContainer';
import routes from 'routes';

export default function CreateTournaments() {
	return (
		<>
			<div className={style.container}>
				<MenuLateral name="campeonatos" routes={routes.tournaments} />
				<div className={style.content}>
					<TournamentCreateContainer />
				</div>
			</div>
		</>
	);
}
