import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { parseDateAsReadable } from '../helpers';
import MatchInfo from './MatchInfo';
import {
	BracketRoundCaption,
	BracketRoundTitle,
	BracketRoundDate
} from './styles';

const useStyles = makeStyles(
	({ palette }) => ({
		container: {
			position: 'relative',
			width: 220,
			minWidth: 220,
			marginRight: '3.5rem',
			'&:last-child': {
				marginRight: 0
			}
		},
		itemBlock: {
			textDecoration: 'none'
		},
		itemInner: {
			width: 140,
			marginRight: '0.5rem'
		},
		itemInnerActive: {
			backgroundColor: palette.gandalf
		},
		content: {
			width: '100%',
			zIndex: 2,
			position: 'absolute',
			top: 'calc(100% + 0.5rem)',
			left: 0,
			display: 'none',
			visibility: 'hidden',
			backgroundColor: palette.gunpowder,
			color: '#ffffff'
		},
		contentInterface: {
			boxShadow: '0 1.5rem 1.5rem rgba(0, 0, 0, 0.75)',
			borderRadius: '0.5rem',
			padding: '1rem'
		},
		contentVisible: {
			display: 'block',
			visibility: 'visible'
		},
		svgColor: {
			color: palette.smoke,
			marginRight: '0.25rem'
		}
	}),
	{ name: 'BracketHeadingItem' }
);

const BracketHeadingItem = ({ round, isVisible, onToggleVisibility }) => {
	const classes = useStyles();
	const { caption, title, date, matches } = round;

	const onClick = (e) => {
		e.preventDefault();
		onToggleVisibility();
	};

	return (
		<Grid container item className={classes.container}>
			<Grid
				item
				container
				alignItems="center"
				justify="flex-end"
				component="a"
				href="#"
				onClick={onClick}
				className={
					isVisible
						? clsx(classes.itemBlock, classes.itemInnerActive)
						: classes.itemBlock
				}
			>
				<Grid item className={classes.itemInner}>
					<BracketRoundCaption>{caption}</BracketRoundCaption>
					<BracketRoundTitle>{title}</BracketRoundTitle>
					<BracketRoundDate>
						{parseDateAsReadable(date, true, false)}
					</BracketRoundDate>
				</Grid>
				<Grid item>
					<ArrowDropDownIcon className={classes.svgColor} />
				</Grid>
			</Grid>
			<Grid
				item
				container
				className={
					isVisible
						? clsx(
								classes.content,
								classes.contentInterface,
								classes.contentVisible
						  )
						: clsx(classes.content, classes.contentInterface)
				}
			>
				<Box>
					{matches.map((match) => (
						<MatchInfo
							match={match}
							hideName
							avatarSize="lg"
							key={`match-${match.id}`}
						/>
					))}
				</Box>
			</Grid>
		</Grid>
	);
};

export default BracketHeadingItem;
