import React, { useState } from 'react';
import {
	DialogContent,
	DialogContentText,
	DialogActions,
	makeStyles,
	Button
} from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptBRLocale from 'date-fns/locale/pt-BR';
import { reschedule } from '../../services/reschedule';

const useStyles = makeStyles((theme) => ({
	dialogformcontrol: {
		width: '100%'
	},
	dialogtextField: {
		width: '100%',
		marginTop: 8,
		marginBottom: 8,
		'& .MuiFilledInput-input': {
			padding: '10px 12px 10px'
		}
	},
	dialogselect: {
		width: '100%',
		marginTop: 8,
		marginBottom: 8,
		'& .MuiFilledInput-input': {
			padding: '10px 12px 10px'
		}
	},
	dialogsavebutton: {
		backgroundColor: '#0092EE',
		color: '#fff',
		width: '100%',
		fontWeight: 700,
		'&:hover': {
			backgroundColor: 'slateblue'
		}
	},
	dialogsubtitle: {
		color: '#fff',
		...theme.typography.text,
		marginTop: 0,
		marginBottom: 0
	},
	dialogcontent: {
		padding: '8px',
		'&:first-child': {
			paddingTop: '0px'
		},
		'& .MuiFilledInput-underline:before': {
			borderBottom: '0px solid #000'
		},
		'& .MuiFilledInput-root': {
			backgroundColor: theme.palette.lead
		}
	}
}));

const localeMap = {
	ptBR: ptBRLocale
};

export const getFormatedDate = (date) =>
	new Date(date).toLocaleDateString('pt', {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric'
	});
export const getFormatedTime = (date) =>
	new Date(date).toLocaleTimeString('pt', {
		hour: 'numeric',
		minute: 'numeric'
	});
export const formatDateToService = (date) => {
	const dateObj = new Date(date);
	const year = dateObj.getFullYear();
	let month = String(dateObj.getMonth() + 1);
	if (month.length === 1) {
		month = `0${month}`;
	}
	const day = dateObj.getDate();
	return `${year}-${month}-${day} ${getFormatedTime(date)}:00`;
};

const Reschedule = ({ matches, tournament, match, dialogToggle }) => {
	const classes = useStyles();
	const [locale, setLocale] = useState('ptBR');

	const [selectedDate, handleDateChange] = useState(
		new Date().toISOString().replace('T', ' ').substring(0, 19)
	);

	const { response: schedule = [], isLoaded } =
		(`/admin/tournaments/${tournament.id}/match/${match.matchid}/schedule`,
		{
			method: 'post',
			payload: {
				date: selectedDate
			}
		});

	function handleSchedule() {
		const formattedPayload = formatDateToService(selectedDate);
		reschedule(tournament, match, formattedPayload)
			.then(dialogToggle())
			.catch((e) => alert(e));
	}

	return (
		<>
			<DialogContent className={classes.dialogcontent}>
				<DialogContentText className={classes.dialogsubtitle}>
					Horários
				</DialogContentText>
				<DialogContent className={classes.wrapperdateandtime}>
					<MuiPickersUtilsProvider
						utils={DateFnsUtils}
						locale={localeMap[locale]}
					>
						<DateTimePicker
							inputVariant="outlined"
							ampm={false}
							value={selectedDate}
							onChange={handleDateChange}
							format="dd/MM/yyyy HH:mm"
						/>
					</MuiPickersUtilsProvider>
				</DialogContent>
			</DialogContent>
			<DialogActions>
				<Button
					className={classes.dialogsavebutton}
					size="large"
					onClick={handleSchedule}
				>
					SALVAR
				</Button>
			</DialogActions>
		</>
	);
};

export default Reschedule;
