import React from 'react';
import { withRouter } from 'react-router-dom';
import TournamentCreateContainer from 'container/TournamentCreateContainer.jsx';
// import Layout from '../layouts';
import HeaderContainer from '../container/HeaderContainer';

const TournamentCreate = ({ match }) => {
	return (
		<>
			{/*  <Layout match={match} maxWidth={false}> */}
			<HeaderContainer />
			<TournamentCreateContainer />
			{/*  </Layout> */}
		</>
	);
};

export default withRouter(TournamentCreate);
