import {
	Avatar,
	Button,
	CircularProgress,
	Grid,
	makeStyles,
	Typography,
	IconButton,
	Select,
	MenuItem
} from '@material-ui/core';
import { InputText } from 'components/form';
import Layout from 'components/Layout';
import { usePromise } from 'hooks';
import { useCallback, useState } from 'react';
import { headers } from 'services/_helpers.js';
import { API } from 'services/api';
import steamIcon from 'assets/icons/SteamIcon.svg';
import { format } from 'date-fns';
import routes from 'routes';

const useStyles = makeStyles((theme) => ({
	avatar: {
		width: '1.875rem',
		height: '1.875rem'
	},
	userListContainer: {
		maxHeight: '250px',
		overflowX: 'hidden',
		overflowY: 'auto',
		marginTop: theme.spacing(1)
	},
	link: {
		backgroundColor: 'transparent',
		textDecoration: 'none',
		color: 'white'
	}
}));

export const findUser = (id) =>
	API.get(`/user/${id}`, {
		headers: headers()
	});

const Search = () => {
	const classes = useStyles();

	const [listLength, setListLength] = useState(6);
	const [shouldSkipPlayers, setShouldSkipPlayers] = useState(true);
	const [queryPlayers, updateQueryPlayers] = useState('');
	const [selectedQueue, setSelectedQueue] = useState('cpl');

	const [selectedPlayerId, setSelectedPlayerId] = useState();

	const findPlayers = (query) =>
		API.get(
			`/find-player`,
			{
				params: {
					query
				}
			},
			{
				headers: headers()
			}
		);

	const addToQueue = (steamId, queue) =>
		API.put(`/admin/queue/${queue}/add-user`, {
			steamId: steamId
		})
			.then(() => alert('Jogador adicionado à queue.'))
			.catch((error) => alert(error.response.data.message));

	const cbFindPlayers = useCallback(
		() => findPlayers(queryPlayers),
		[queryPlayers]
	);

	const { response, isLoading } = usePromise(
		cbFindPlayers,
		shouldSkipPlayers
	);

	const players = response?.players || [];

	let debounceTimePlayers;
	const handleChangePlayers = (ev) => {
		const { value } = ev.target;

		clearTimeout(debounceTimePlayers);
		debounceTimePlayers = setTimeout(() => {
			updateQueryPlayers(value);

			if (shouldSkipPlayers && value.length >= 3) {
				setShouldSkipPlayers(false);
			} else if (!shouldSkipPlayers && value.length < 3) {
				setShouldSkipPlayers(true);
			}
		}, 250);
	};

	const cbFindUser = useCallback(
		() => findUser(selectedPlayerId),
		[selectedPlayerId]
	);

	const { response: responseUser } = usePromise(cbFindUser);

	const today = new Date().getTime();

	return (
		<Layout routes={routes.players} name={'jogadores'}>
			<Grid item xs={12}>
				<h1>Buscar Jogadores</h1>
				<Grid container xs={12} spacing={6}>
					<Grid item xs={12}>
						<InputText
							label="Nome ou Steam64 do Jogador"
							placeholder="Buscar Jogadores"
							handleChange={(ev) => handleChangePlayers(ev)}
						/>
						<Grid
							item
							xs={12}
							className={classes.userListContainer}
						>
							{isLoading && (
								<Grid item container justify="center" xs={12}>
									<CircularProgress
										color="inherit"
										size="2rem"
									/>
								</Grid>
							)}
							{!isLoading && queryPlayers.length < 3 && (
								<Grid
									item
									container
									justifyContent="flex-start"
									xs={12}
								>
									Insira ao menos três caracteres
								</Grid>
							)}
							{!isLoading &&
								!players.length &&
								queryPlayers.length >= 3 && (
									<Grid
										item
										container
										justifyContent="center"
										xs={12}
									>
										Sem resultados
									</Grid>
								)}
							{!isLoading &&
								queryPlayers.length >= 3 &&
								players.map((result, index) => {
									if (index > listLength) {
										return <></>;
									}
									return (
										<Grid
											item
											container
											alignItems="center"
											xs={12}
											spacing={2}
											key={`user-search-${result.id}`}
										>
											<Grid item xs={1}>
												<Avatar
													className={classes.avatar}
													src={result.avatar}
												/>
											</Grid>
											<Grid item xs={8}>
												<Typography>
													{result.username}
												</Typography>
											</Grid>
											<Grid item xs={3}>
												<Button
													variant="contained"
													color="primary"
													fullWidth
													onClick={() => {
														setSelectedPlayerId(
															result.id
														);
														setSelectedQueue('cpl');
													}}
												>
													MAIS INFOS
												</Button>
											</Grid>
											{index === listLength && (
												<Grid
													container
													xs={12}
													justifyContent="center"
												>
													<Button
														color="primary"
														fullWidth
														variant="contained"
														onClick={() =>
															setListLength(
																listLength + 3
															)
														}
													>
														MOSTRAR MAIS
													</Button>
												</Grid>
											)}
										</Grid>
									);
								})}
						</Grid>
					</Grid>
					<Grid item xs={12} className={classes.userListContainer}>
						<Typography variant="h5">
							Usuário Selecionado
						</Typography>
						{responseUser ? (
							<Grid
								container
								alignItems="center"
								key={`user-search-${responseUser.id}`}
							>
								<Grid item xs={1}>
									<Avatar
										className={classes.avatar}
										src={responseUser.avatar}
									/>
								</Grid>
								<Grid item xs={2}>
									<Typography>
										<a
											className={classes.link}
											href={`https://coliseum.gg/profile/${responseUser.id}`}
											target="_blank"
											rel="noopener noreferrer"
										>
											{responseUser.name}
										</a>
									</Typography>
								</Grid>
								{responseUser.steam ? (
									<>
										<IconButton>
											<a
												className={classes.link}
												// eslint-disable-next-line max-len
												href={`https://steamcommunity.com/profiles/${responseUser.steam.steamId}`}
												target="_blank"
												rel="noopener noreferrer"
											>
												<img
													src={steamIcon}
													alt="Steam icon button"
													className={
														classes.socialIcon
													}
												/>
											</a>
										</IconButton>
										<Grid item xs={3}>
											<Typography>
												SteamID 64: &nbsp;
												{responseUser.steam.steamId}
											</Typography>
										</Grid>
									</>
								) : (
									<></>
								)}
								{responseUser.premiumEnd &&
								today <=
									new Date(
										responseUser.premiumEnd.date
									).getTime() ? (
									<Grid item xs={2}>
										<Typography>
											Premium até: &nbsp;
											{format(
												new Date(
													responseUser.premiumEnd.date
												),
												'dd-MM-yyyy'
											)}
										</Typography>
									</Grid>
								) : (
									<></>
								)}
								<Grid item xs={1}>
									<Select
										value={selectedQueue}
										defaultValue={'cpl'}
										onChange={(e) =>
											setSelectedQueue(e.target.value)
										}
									>
										<MenuItem value="cpl">RPL</MenuItem>
										<MenuItem value="grl">GRL</MenuItem>
										<MenuItem value="cgl">ADV</MenuItem>
										<MenuItem value="default">MM</MenuItem>
									</Select>
								</Grid>
								<Grid item xs={2}>
									<Button
										variant="contained"
										color="primary"
										disabled={!responseUser?.steam?.steamId}
										onClick={() => {
											if (responseUser?.steam?.steamId) {
												addToQueue(
													responseUser.steam.steamId,
													selectedQueue
												);
											}
										}}
									>
										Adicionar à fila
									</Button>
								</Grid>
							</Grid>
						) : (
							<></>
						)}
					</Grid>
				</Grid>
			</Grid>
		</Layout>
	);
};

export default Search;
