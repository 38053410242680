import { useState, useEffect } from 'react';

const usePromise = (promiseFn, skip = false) => {
	if (promiseFn.then) {
		throw new TypeError('First argument must be a Promise');
	}

	const [response, setResponse] = useState();
	const [isLoading, setLoadingState] = useState(false);
	const [isLoaded, setLoadedState] = useState(false);
	const [error, setError] = useState();
	const [reloadCount, increaseReloadCount] = useState(0);

	const refresh = () => {
		increaseReloadCount(reloadCount + 1);
	};

	const setResponseWithData = (data) => {
		setResponse(data);
		setLoadingState(false);
		setLoadedState(true);
	};

	useEffect(() => {
		let hasUnmount = false;
		if (!skip) {
			setError();
			setLoadingState(true);

			promiseFn()
				.then(({ data }) => {
					if (!hasUnmount) {
						setResponseWithData(data);
					}
				})
				.catch((e) => {
					const { response: r, message } = e;
					const { data: errorData = {} } = r || {};
					setLoadingState(false);
					setError(errorData.message || message);
				});
		}

		return () => {
			hasUnmount = true;
		};
	}, [promiseFn, skip, reloadCount]);

	return {
		response,
		isLoading,
		isLoaded,
		error,
		refresh,
		setResponseWithData,
		setLoadingState
	};
};

export default usePromise;
