import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const StyledTypography = withStyles({
	root: {
		textTransform: 'uppercase'
	}
})(Typography);

const ConfirmationText = ({ hasConfirmed }) => {
	const color = hasConfirmed ? 'primary' : 'error';
	const text = hasConfirmed ? 'Confirmado' : 'Aguardando confirmação';

	return (
		<StyledTypography variant="caption" color={color}>
			{text}
		</StyledTypography>
	);
};

export default ConfirmationText;
