import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const baseCSS = {
	position: 'relative',
	marginBottom: 16
};

const beforeCSS = {
	'&::before': {
		content: '""',
		display: 'block',
		height: 2,
		width: 20,
		background: '#25272c',
		position: 'absolute',
		top: 57,
		left: -31
	}
};

const useStyles = makeStyles(
	() => ({
		root: ({ tierPos }) => ({
			...baseCSS,
			...(tierPos ? beforeCSS : {})
		})
	}),
	{ name: 'MatchWrapper' }
);

const MatchWrapper = ({ children, tierPos }) => {
	const classes = useStyles({ tierPos });

	return <div className={classes.root}>{children}</div>;
};

export default MatchWrapper;
