import React, { useState } from 'react';
import HeaderContainer from '../container/HeaderContainer';

import {
	Typography,
	Grid,
	makeStyles,
	Button,
	TextField
} from '@material-ui/core';
import { createNewMatch } from 'services/createNewMatch';

const useStyles = makeStyles(
	(theme) => ({
		gridpadding: {
			marginTop: 50,
			marginLeft: '85vh',
			alignItems: 'center'
		},
		title: {
			display: 'flex',
			justifyContent: 'center',
			fontWeight: 500,
			fontSize: 30,
			textTransform: 'uppercase',
			color: 'white',
			marginTop: '5vh'
		},
		button: {
			marginLeft: 120,
			marginRight: 10
		},
		removeButton: {
			marginLeft: 220
		},
		list: {
			marginLeft: '12px',
			listStyleType: 'none',
			color: 'white',
			fontSize: '20px'
		},
		inputkeys: {
			width: '20%',
			textDecoration: 'none',

			paddingRight: 15,
			'& .MuiFilledInput-input': {
				padding: '10px 12px 10px'
			},
			'& .MuiFilledInput-underline:before': {
				borderBottom: '0px solid #000'
			},
			'& .MuiFilledInput-root': {
				backgroundColor: theme.palette.lead,
				borderRadius: 4
			}
		}
	}),
	{ name: 'NewMatch' }
);

const CreateNewMatch = (props) => {
	const classes = useStyles();
	const [value, setValue] = useState('');
	const [teamA, setTeamA] = useState([]);
	const [teamB, setTeamB] = useState([]);

	// const { response: newMatch = [] } = createNewMatch();

	const { response: matches = [] } =
		(`admin/match/new`,
		{
			method: 'post',
			payload: {
				queueId: 'cpl',
				teamA: teamA,
				teamB: teamB
			}
		});

	const handleSubmitTeamA = (e) => {
		e.preventDefault();
		if (!!value && teamA.length < 5) {
			setTeamA([...teamA, value]);
			setValue('');
		}
		setValue('');
	};
	const handleSubmitTeamB = (e) => {
		e.preventDefault();
		if (!!value && teamB.length < 5) {
			setTeamB([...teamB, value]);
			setValue('');
		}
		setValue('');
	};

	function handleDeleteTeamA(id) {
		const newTeamA = teamA.filter((player) => player !== id);

		setTeamA(newTeamA);
	}
	function handleDeleteTeamB(id) {
		const newTeamB = teamB.filter((player) => player !== id);

		setTeamB(newTeamB);
	}

	const handleSubmit = () => {
		if (teamA.length < 5 || teamB.length < 5) {
			alert('Número mínimo para criação de partidas');
		}
		createNewMatch('cpl', teamA, teamB);
	};

	return (
		<>
			<HeaderContainer />
			<Typography className={classes.title}>
				Criação de nova partida
			</Typography>
			<Grid className={classes.gridpadding}>
				<form onSubmit={handleSubmitTeamA}>
					<TextField
						className={classes.inputkeys}
						variant="filled"
						type="text"
						value={value}
						onChange={(event) =>
							setValue(parseInt(event.target.value) || value)
						}
						placeholder="Team A"
					/>
					<Button color="primary" variant="outlined" type="submit">
						adicionar
					</Button>
				</form>

				<br />
				<ul className={classes.list}>
					{teamA.map((player, index) => (
						<li key={index}>
							{player}
							<Button
								color="primary"
								className={classes.removeButton}
								type="button"
								onClick={() => handleDeleteTeamA(player)}
							>
								{' '}
								remove{' '}
							</Button>
						</li>
					))}
					{console.log(teamA)}
				</ul>
			</Grid>
			<Grid className={classes.gridpadding}>
				<form onSubmit={handleSubmitTeamB}>
					<TextField
						className={classes.inputkeys}
						variant="filled"
						type="text"
						value={value}
						onChange={(event) =>
							setValue(parseInt(event.target.value) || value)
						}
						placeholder="Team B"
					/>
					<Button color="primary" variant="outlined" type="submit">
						adicionar
					</Button>
				</form>

				<br />
				<ul className={classes.list}>
					{teamB.map((player, index) => (
						<li key={index}>
							{player}
							<Button
								color="primary"
								className={classes.removeButton}
								type="button"
								onClick={() => handleDeleteTeamB(player)}
							>
								{' '}
								remove{' '}
							</Button>
						</li>
					))}
					{console.log(teamB)}
				</ul>
			</Grid>
			<Grid className={classes.gridpadding}>
				<Button
					className={classes.button}
					color="primary"
					variant="outlined"
					onClick={handleSubmit}
				>
					Criar Partida
				</Button>
			</Grid>
		</>
	);
};

export default CreateNewMatch;
