import React, { useState } from 'react';
// import { useHistory } from 'react-router-dom';
import { Grid, CardMedia, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import ConfirmationText from 'components/Tournament/Teams/ConfirmationText';
import { approveTeam } from 'services/tournament';
const useStyles = makeStyles(
	(theme) => ({
		root: {
			padding: 8,
			maxWidth: '100%',
			marginTop: 16,
			color: theme.palette.gandalf,
			'&:hover': {
				color: '#FFF',
				cursor: 'pointer',
				background: theme.palette.lead
			}
		},
		media: {
			width: 40,
			height: 40
		},
		title: {
			fontSize: 24,
			marginLeft: -24,
			fontWeight: 'bold',
			lineHeight: '36px',
			textAlign: 'left'
		}
	}),
	{ name: 'TournamentTeamItem' }
);

const TournamentTeamItem = ({
	teamId,
	tournamentId,
	name,
	avatar,
	hasConfirmed
}) => {
	const classes = useStyles();

	const [isLoading, setIsLoading] = useState(false);

	const [isApproved, setIsApproved] = useState(hasConfirmed);

	const handleApproval = async () => {
		setIsLoading(true);
		await approveTeam({ teamId, tournamentId })
			.then(() => {
				setIsApproved(true);
			})
			.catch(() => {
				alert('Erro ao aprovar a equipe.');
				setIsApproved(false);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<a
			href={`https://coliseum.gg/team/${teamId}`}
			target="_blank"
			rel="noopener noreferrer"
			style={{ textDecoration: 'none' }}
		>
			<Grid
				container
				alignItems="center"
				spacing={1}
				className={classes.root}
			>
				<Grid item xs={1}>
					<CardMedia
						className={classes.media}
						component="img"
						image={avatar}
					/>
				</Grid>
				<Grid item xs={9} style={{ zIndex: 99 }}>
					<Typography className={classes.title}>{name}</Typography>
				</Grid>

				<Grid item xs={1}>
					<ConfirmationText hasConfirmed={isApproved} />
				</Grid>
				<Grid item xs={1} onClick={(event) => event.preventDefault()}>
					<Button
						color="primary"
						variant="contained"
						onClick={handleApproval}
						disabled={isApproved || isLoading}
					>
						APROVAR
					</Button>
				</Grid>
			</Grid>
		</a>
	);
};

export default TournamentTeamItem;
