import { SocketWrapper } from './wrapperClass';

const { SOCKET_URL = 'wss://ws.coliseum.gg' } = process.env;
const url = process.env.NODE_ENV === 'test' ? '' : SOCKET_URL;

export const ws = new SocketWrapper({
	url,
	keepAlive: 5 * 60 * 1000
});

export const initializeSockets = ({ dispatch, token }, socketWrapper = ws) => {
	socketWrapper.token = token;
	socketWrapper.connect();
	const { socket } = socketWrapper;

	socket &&
		socket.addEventListener('message', (message) => {
			const parsedMessage = JSON.parse(message.data);
			if (parsedMessage.type === 'WELCOME_MESSAGE') {
				socketWrapper.connectionId = parsedMessage.data.connectionId;
			}
		});

	// Needs to be updated
	// socket.on('balance', ({ balance }) => {
	// 	dispatch(updateAccountBalance(balance));
	// });

	return socketWrapper;
};
