import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1370,
      xl: 1920,
    },
  },

  typography: {
    text: {
      fontFamily: "Rubik, sans-serif",
      fontWeight: 500,
      fontSize: 18,
      color: "#fff",
      marginTop: 20,
      marginBottom: 20,
    },
    fontFamily: "Rubik, sans-serif",
    color: "#C0C0C3",
    h4: {
      fontSize: "1.5rem",
      "@media (min-width:600px)": {
        fontSize: "1.875rem",
      },
    },
  },

  palette: {
    type: "dark",

    primary: {
      main: "#0092EE",
    },
    info: {
      main: "#FEAE0F",
    },
    warning: {
      main: "#AC3906",
    },
    success: {
      main: "#9FBAE7",
    },

    ash: "#25272C",
    asphalt: "#0F0F10",
    smoke: "#C0C0C3",
    lead: "#17171A",
    gandalf: "#515357",
    nocturne: "#0B0B0A",
    gunpowder: "#1B1D20",
    gold: "#FEAE0F",
    darkGold: "#FEC312",
    silver: "#9FBAE7",
    bronze: "#AC3906",
  },
});

export default theme;
