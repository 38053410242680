import React from 'react';
import { Typography, CardMedia, Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

import TournamentSignupButton from 'components/Tournament/SignupButton';

const useStyles = makeStyles(
	() => ({
		titleWrapper: {
			display: 'flex',
			alignItems: 'center',
			marginBottom: 24
		},
		gridTitle: {
			marginLeft: 40
		},
		mainTitle: {
			fontWeight: 'bold'
		},
		organizedBy: {
			fontWeight: 300
		},
		media: {
			width: 'auto',
			height: 150
		},
		icon: {
			width: 16,
			height: 16,
			marginBottom: -2
		}
	}),
	{ name: 'TournamentHeading' }
);

const TournamentHeading = ({ tournament, onTournamentSignup }) => {
	const classes = useStyles();

	const { organizer = {} } = tournament;

	return (
		<Box className={classes.titleWrapper}>
			<CardMedia
				className={classes.media}
				component="img"
				image={tournament.logo}
			/>
			<Grid container spacing={1} className={classes.gridTitle}>
				<Grid item xs={12}>
					<Typography variant="h3" className={classes.mainTitle}>
						{tournament.name}
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<Typography variant="h6" className={classes.organizedBy}>
						Organizado por{' '}
						<Typography color="primary" display="inline">
							<strong>{organizer.name}</strong>{' '}
							{organizer.tbd_isVerified && (
								<VerifiedUserIcon className={classes.icon} />
							)}
						</Typography>
					</Typography>
				</Grid>
				{/* <Grid item xs={3}>
					<TournamentSignupButton
						tournament={tournament}
						onClick={onTournamentSignup}
					/>
				</Grid> */}
			</Grid>
		</Box>
	);
};

export default TournamentHeading;
