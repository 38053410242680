import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
	(theme) => ({
		aboutBox: {
			color: theme.palette.smoke,
			marginBottom: (props) => (props.smallerSpacing ? '1rem' : '1.75rem')
		},
		aboutTitle: {
			textTransform: 'uppercase'
		},
		aboutContent: {
			textAlign: 'right',
			fontWeight: 'bold'
		}
	}),
	{ name: 'InformationItem' }
);

const InformationItem = ({ title, smallerSpacing, color, children }) => {
	const classes = useStyles({ smallerSpacing });

	return (
		<Grid
			container
			direction="row"
			justify="space-between"
			alignItems="center"
			className={classes.aboutBox}
		>
			<Grid item xs={2}>
				<Typography variant="caption" className={classes.aboutTitle}>
					{title}
				</Typography>
			</Grid>
			<Grid item xs={10}>
				{title === 'Premiação' ? (
					<Typography
						variant="body1"
						className={classes.aboutContent}
						color={color}
						dangerouslySetInnerHTML={{
							__html: children
						}}
					/>
				) : (
					<Typography
						variant="body1"
						className={classes.aboutContent}
						color={color}
					>
						{children}
					</Typography>
				)}
			</Grid>
		</Grid>
	);
};

export default InformationItem;
