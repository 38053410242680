import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as ColiseumIcon } from '../assets/images/coliseumLogo.svg';

const useStyles = makeStyles((theme) => ({
	grow: {
		flexGrow: 1,
		paddingBottom: '100px'
	},
	header: {
		backgroundColor: theme.palette.asphalt,
		position: 'fixed',
		height: 100,
		top: 0
	},
	toolbar: {
		paddingLeft: 0
	},
	iconButton: {
		marginRight: theme.spacing(2),
		paddingRight: 0,
		background: '#17171a',
		borderRadius: 0,
		width: 160,
		height: 100,
		position: 'relative',
		'&:hover': {
			'&:before': {
				content: '"HOME"',
				fontSize: 12,
				paddingTop: 5,
				paddingBottom: 0,
				paddingLeft: 10,
				height: 16,
				textAlign: 'center',
				width: 80,
				position: 'absolute',
				top: 8,
				left: 8,
				background: theme.palette.primary.main,
				color: 'white',
				transform: 'translateX(-30%) translateY(10%) rotate(-45deg)'
			}
		}
	}
}));

export default function HeaderContainer() {
	const classes = useStyles();
	return (
		<div className={classes.grow}>
			<AppBar position="static" className={classes.header}>
				<Toolbar className={classes.toolbar}>
					<Link to="/">
						<IconButton
							edge="start"
							className={classes.iconButton}
							color="inherit"
						>
							<ColiseumIcon />
						</IconButton>
					</Link>
					<div className={classes.grow} />
				</Toolbar>
			</AppBar>
		</div>
	);
}