import { Link } from 'react-router-dom';
import TournamentReportsItem from '../item/TournamentReportsItem';
import style from '../../reports/tournamentReports.module.scss';
import { Typography } from '@material-ui/core';

const TournamentMatchReportsItem = ({ match, tournamentId }) => {
	return (
		<div className={style.content} style={{ marginBottom: `32px` }}>
			<Link
				to={`/edit/tournaments/${tournamentId}/match/${match.id}`}
				style={{ textDecoration: 'none', color: 'white' }}
			>
				<Typography variant="h4">
					Partida {match.id} - {match.teamA.name} vs {''}
					{match.teamB.name}{' '}
				</Typography>
			</Link>
			{match.reports.map((report) => (
				<TournamentReportsItem
					report={report}
					tournamentId={tournamentId}
					match={match}
					key={report.id}
				/>
			))}
		</div>
	);
};

export default TournamentMatchReportsItem;
