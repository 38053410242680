import React, { useLayoutEffect, useState } from 'react';
import './App.css';
import { ThemeProvider } from '@material-ui/core/styles';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	BrowserRouter
} from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import SignIn from './components/SignIn';
import Dashboard from './components/Dashboard';
import theme from './theme';
import { API } from 'services/api';
import TournamentSummary from './pages/Tournament';
import Match from './pages/Match';
import TournamentCreate from './pages/TournamentCreate';
import CancelMatch from 'components/CancelMatch';
import CreateNewMatch from 'components/CreateNewMatch';
import BanPlayer from 'components/BanPlayer';
import EditMatchTournament from 'components/EditMatchTournament';
import Home from 'pages/home';
import Tournaments from 'pages/tournaments';
import CreateTournaments from 'pages/tournaments/create';
import EditTournaments from 'pages/tournaments/edit';
import EditTournamentsSelected from 'pages/tournaments/selected';
import Players from 'pages/players';
import PlayersBan from 'pages/players/ban';
import PlayerUnban from 'pages/players/unban';
import Search from 'pages/players/search';
import Matches from 'pages/matches';
import MatchesCreate from 'pages/matches/create';
import MatchesCancel from 'pages/matches/cancel';
import { isLogin } from 'utils';
import Header from 'components/header';
import EditTournament from 'pages/tournaments/editTournament';
import TournamentTeams from 'pages/tournaments/teams';
import SwissTournamentTeams from 'pages/tournaments/swissTeams';
import TournamentReports from 'pages/tournaments/reports';
import { ContentCreate } from 'pages/content/create';
import { Content } from 'pages/content';

const App = () => {
	const [token] = useState(localStorage.getItem('jwt'));

	useLayoutEffect(() => {
		token
			? (API.defaults.headers.common.Authorization = `Bearer ${token}`)
			: (API.defaults.headers.common.Authorization = '');
	}, [token]);

	return (
		<>
			<ThemeProvider theme={theme}>
				<Router>
					<BrowserRouter>
						{isLogin() && <Header />}
						<Switch>
							<Route exact path="/signin" component={SignIn} />
							<PrivateRoute exact path="/" component={Home} />
							<PrivateRoute exact path="/home" component={Home} />
							<PrivateRoute
								exact
								path="/tournaments/"
								component={Tournaments}
							/>
							<PrivateRoute
								exact
								path="/create/tournaments"
								component={CreateTournaments}
							/>
							<PrivateRoute
								exact
								path="/edit/tournaments"
								component={EditTournaments}
							/>
							<PrivateRoute
								exact
								path="/tournament/:idTournament/teams"
								component={TournamentTeams}
							/>
							<PrivateRoute
								exact
								path="/tournament/:idTournament/teams/swiss"
								component={SwissTournamentTeams}
							/>
							<PrivateRoute
								exact
								path="/tournament/:idTournament/reports"
								component={TournamentReports}
							/>
							<PrivateRoute
								exact
								path="/edit/tournaments/:idTournaments/matches/:idMatches"
								component={EditTournamentsSelected}
							/>
							<PrivateRoute
								exact
								path="/edit/tournament/:idTournament"
								component={EditTournament}
							/>
							<PrivateRoute
								exact
								path="/players"
								component={Players}
							/>
							<PrivateRoute
								exact
								path="/players/ban"
								component={PlayersBan}
							/>
							<PrivateRoute
								exact
								path="/players/search"
								component={Search}
							/>
							<PrivateRoute
								exact
								path="/players/unban"
								component={PlayerUnban}
							/>
							<PrivateRoute
								exact
								path="/matches"
								component={Matches}
							/>
							<PrivateRoute
								exact
								path="/matches/create"
								component={MatchesCreate}
							/>
							<PrivateRoute
								exact
								path="/matches/cancel"
								component={MatchesCancel}
							/>
							{/* <PrivateRoute exact path="/tournaments/" component={EditTournaments} /> */}
							<PrivateRoute
								path="/old-dashboard"
								exact
								component={Dashboard}
							/>
							{/* <PrivateRoute
								path="/dashboard/tournaments/"
								exact
								component={Tournaments}
							/> */}
							<PrivateRoute
								path="/dashboard/createMatch"
								exact
								component={CreateNewMatch}
							/>
							<PrivateRoute
								path="/dashboard/match/cancelMatch/"
								exact
								component={CancelMatch}
							/>
							<PrivateRoute
								path="/dashboard/tournament/:id"
								exact
								component={TournamentSummary}
							/>
							<PrivateRoute
								path="/dashboard/tournaments/create"
								exact
								component={TournamentCreate}
							/>
							<PrivateRoute
								path="/dashboard/tournament/match/edit"
								exact
								component={EditMatchTournament}
							/>
							<PrivateRoute
								path="/dashboard/tournament/:id/match/:matchid/"
								exact
								component={Match}
							/>
							<PrivateRoute
								path="/dashboard/tournament/:id/match/:matchid/reset"
								exact
								component={Match}
							/>
							<PrivateRoute
								path="/dashboard/banPlayer"
								exact
								component={BanPlayer}
							/>
							<PrivateRoute
								path="/edit/tournaments/:id/match/:matchid/"
								exact
								component={Match}
							/>
							<PrivateRoute
								path="/content"
								exact
								component={Content}
							/>
							<PrivateRoute
								path="/content/create"
								exact
								component={ContentCreate}
							/>
							<PrivateRoute
								exact
								path="/content/edit/:postId"
								component={ContentCreate}
							/>
						</Switch>
					</BrowserRouter>
				</Router>
			</ThemeProvider>
		</>
	);
};

export default App;
