import React from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';

import { useAPI } from 'hooks';
import TeamListItem from './Teams/TeamListItem';

const useStyles = makeStyles(
	() => ({
		root: {
			minHeight: '65vh',
			width: '100%',
			maxWidth: 1330,
			paddingBottom: 10,
			marginLeft: 'auto',
			marginRight: 'auto'
		}
	}),
	{ name: 'TournamentTeams' }
);

const TournamentContentTeams = ({ tournament }) => {
	const { id } = tournament;
	const classes = useStyles();

	const { response: enrolledTeams = [], isLoaded } = useAPI(
		`/tournaments/${id}/teams`
	);

	return (
		<Box className={classes.root}>
			{isLoaded &&
				enrolledTeams?.map((teamInfo, idx) => (
					<TeamListItem key={`team-info-${idx}`} info={teamInfo} />
				))}

			{isLoaded && !enrolledTeams.length && (
				<Typography color="textSecondary">
					Nenhuma equipe inscrita
				</Typography>
			)}
		</Box>
	);
};

export default TournamentContentTeams;
