import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const StyledTypography = withStyles({
	root: {
		textTransform: 'uppercase',
		fontSize: '1.875rem'
	}
})(Typography);

const GroupTitle = ({ children }) => (
	<StyledTypography component="h3" gutterBottom>
		{children}
	</StyledTypography>
);

export default GroupTitle;
