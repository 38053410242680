import React, { useState } from 'react';
import {
	makeStyles,
	Button,
	Switch,
	Select,
	MenuItem
} from '@material-ui/core';
import { Flex, Typography } from '@coliseum.gg/ui';
import { changeMatchBestOf } from 'services/matches';

const ChangeFormat = ({ matchId, dialogToggleBestOf }) => {
	const [checked, setChecked] = useState(false);
	const [bestOf, setBestOf] = useState(false);

	function handleBestOf() {
		changeMatchBestOf(matchId, bestOf)
			.then(() => {
				alert('BestOf alterado com sucesso.');
				dialogToggleBestOf();
			})
			.catch((e) => {
				alert(e);
			});
	}

	return (
		<Flex direction="column">
			<Flex direction="column" gap="16" css={{ padding: '$8' }}>
				<Typography>Alterar Formato</Typography>
				<Select
					labelId="bestOf"
					id="bestOf-type-select"
					value={bestOf}
					onChange={(e) => setBestOf(e.target.value)}
					name="bestOf"
				>
					<MenuItem value={1}>BO1</MenuItem>
					<MenuItem value={3}>BO3</MenuItem>
					<MenuItem value={5}>BO5</MenuItem>
				</Select>
				<Flex align="center" css={{ gap: '$8' }}>
					<Typography>Você tem certeza?</Typography>
					<Switch
						checked={checked}
						onChange={() => setChecked((oldState) => !oldState)}
						name="checkBestOf"
						color="primary"
					/>
				</Flex>
			</Flex>
			<Flex direction="column" gap="8">
				<Button
					size="large"
					onClick={handleBestOf}
					variant="contained"
					style={{ width: '100%' }}
					color="primary"
					disabled={!checked}
				>
					ALTERAR
				</Button>
			</Flex>
		</Flex>
	);
};

export default ChangeFormat;
