import { API } from 'services/api';
import { headers } from 'services/_helpers';

export const getBanPlayer = async (leagueSelected, dateLimit) => {
	return API.get(`admin/matchmaking/banned-users?queue=${leagueSelected}`, {
		headers: headers(),
		params: { toDate: dateLimit }
	})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.error(error);
		});
};

export const banPlayer = (id, reason, date) => {
	return API.post(`admin/user/${id}/ban`, {
		reason: reason,
		date: date
	})
		.then((response) => {
			alert(response.data.message);
			return response;
		})
		.catch((error) => {
			alert(error);
		});
};

export const unbanPlayer = async (id) => {
	return API.post(`/admin/matchmaking/unban/${id}`, { headers: headers() })
		.then((response) => {
			alert(response.data.message);
			return response;
		})
		.catch((error) => {
			alert(error);
		});
};
