import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
	() => ({
		goldRadial: {
			background:
				// eslint-disable-next-line max-len
				'radial-gradient(102.65% 222.25% at 0% -6.58%, #FFEE8B 0%, #FFEE69 10.12%, #FFD237 20.19%, #FFA940 54.63%, #FF8A00 100%)',
			boxShadow: '0px 0px 17px rgba(255, 173, 15, 0.35)',
			borderRadius: 4
		}
	}),
	{ name: 'TournamentSignupButton' }
);

// This should be a component prop
const user = {
	id: 10,
	isPremium: true,
	joinedTournamentsId: [2]
};

const TournamentSignupButton = ({ tournament, onClick }) => {
	const classes = useStyles();
	const { id: tournamentId } = tournament;
	const { id: userId, isPremium, joinedTournamentsId } = user;

	const hasJoined = joinedTournamentsId.some((id) => id === tournamentId);
	const variant = hasJoined ? 'outlined' : 'contained';
	const className =
		(!hasJoined && !isPremium && classes.goldRadial) || undefined;
	const notJoinedText = !isPremium ? 'Premium' : 'Inscrever-se';
	const text = !hasJoined ? notJoinedText : 'Inscrito';
	const disabled = hasJoined || !tournament.isRegistrationOpen || !isPremium;

	return (
		<Button
			variant={variant}
			color="primary"
			className={className}
			fullWidth
			disabled={disabled}
			onClick={() => !disabled && onClick({ tournamentId, userId })}
		>
			{text}
		</Button>
	);
};

export default TournamentSignupButton;
