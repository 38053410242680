import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export const StyledTabs = withStyles((theme) => ({
	indicator: {
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: 'transparent',
		'& > span': {
			// maxWidth: 40,
			marginTop: -8,
			marginBottom: 8,
			width: '100%',
			backgroundColor: theme.palette.primary.main
		}
	}
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

export const StyledTab = withStyles((theme) => ({
	root: {
		textTransform: 'none',
		color: '#C0C0C3',
		// fontWeight: theme.typography.fontWeightBold,
		fontSize: theme.typography.pxToRem(15),
		marginRight: theme.spacing(2),
		padding: 0,
		minWidth: 'auto',
		'&:focus': {
			opacity: 1
		},
		'&$selected': {
			color: theme.palette.primary.main
		},
		'&:disabled': {
			opacity: 1
		}
	},
	selected: {}
}))((props) => <Tab disableRipple {...props} />);
