import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const BracketRoundCaption = withStyles((theme) => ({
	root: {
		fontSize: '0.875rem',
		color: theme.palette.smoke,
		fontWeight: 300
	}
}))(Typography);

export const BracketRoundTitle = withStyles((theme) => ({
	root: {
		fontSize: '1rem',
		color: theme.palette.primary.main,
		fontWeight: 500,
		textTransform: 'uppercase'
	}
}))(Typography);

export const BracketRoundDate = withStyles((theme) => ({
	root: {
		fontSize: '1.125em',
		color: theme.palette.smoke,
		fontWeight: 700,
		textTransform: 'uppercase'
	}
}))(Typography);
