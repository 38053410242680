import matchesFixture from './tournament.matches';

export default [
	{
		caption: 'B01',
		title: 'Abertura',
		date: '2020-04-14T18:30:00.000Z',
		matches: matchesFixture
	},
	{
		caption: 'B01',
		title: 'Abertura',
		date: '2020-04-14T18:30:00.000Z',
		matches: matchesFixture
	},
	{
		caption: 'B01',
		title: 'Fase de Grupos',
		date: '2020-04-14T18:30:00.000Z',
		matches: matchesFixture
	},
	{
		caption: 'B01',
		title: 'Quartas de Final',
		date: '2020-04-14T18:30:00.000Z',
		matches: matchesFixture
	},
	{
		caption: 'B03',
		title: 'Semi-Final',
		date: '2020-04-14T18:30:00.000Z',
		matches: matchesFixture
	},
	{
		caption: 'B05',
		title: 'Final',
		date: new Date().toISOString(),
		matches: matchesFixture
	}
];
