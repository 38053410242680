import axios from 'axios';

const API = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		'Content-Type': 'application/json'
	}
});

const publicAPI = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		'Content-Type': 'application/json'
	}
});

export { API, publicAPI };
