import React from 'react';
import { Typography, Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	summarycard: {
		backgroundColor: theme.palette.asphalt,
		borderRadius: '8px'
	},
	infocard: {
		backgroundColor: theme.palette.asphalt,
		borderRadius: '8px',
		display: 'flex',
		flexDirection: 'row'
	},
	infocontent: {
		padding: 8
	},
	infoicon: {
		padding: '8px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	infomaincontent: {
		color: theme.palette.smoke,
		fontSize: 40,
		fontWeight: 500
	},
	infomaintitle: {
		color: theme.palette.gandalf,
		fontSize: 18,
		fontWeight: 500,
		textTransform: 'uppercase'
	}
}));

const SummaryCard = ({ maincontent, maintitle, icon }, props) => {
	const classes = useStyles();

	return (
		<Card className={classes.summarycard}>
			<CardContent className={classes.infocard}>
				<div className={classes.infoicon}>{icon}</div>
				<div className={classes.infocontent}>
					<Typography className={classes.infomaincontent}>
						{maincontent}
					</Typography>
					<Typography className={classes.infomaintitle}>
						{maintitle}
					</Typography>
				</div>
			</CardContent>
		</Card>
	);
};

export default SummaryCard;
