import React, { useState } from 'react';
import MenuLateral from 'components/menu-lateral';
import style from './tournaments.module.scss';
import routes from 'routes';
import { useAPI } from 'hooks';
import TournamentListItem from './TournamentListItem';
import { CircularProgress } from '@material-ui/core';

export default function Tournaments() {
	const [isOpen, setIsOpen] = useState(false);
	const {
		response: tournaments = [],
		isLoaded
		// setResponseWithData
	} = useAPI('/tournaments');

	return (
		<>
			<div
				className={style.container}
				onClick={() => {
					if (isOpen) setIsOpen(false);
				}}
			>
				<MenuLateral name="campeonatos" routes={routes.tournaments} />
				<div className={style.content}>
					<h1>Listar campeonatos</h1>
					{!isLoaded && (
						<CircularProgress
							color="inherit"
							size="4rem"
							style={{ marginTop: 16 }}
						/>
					)}
					{isLoaded &&
						tournaments
							.filter((t) => t.status !== 4)
							.map((tournament, key) => {
								const { name, id, logo } = tournament;

								return (
									<TournamentListItem
										tournament={tournament}
										key={key}
										name={name}
										logo={logo}
										id={id}
										isOpen={isOpen}
										setIsOpen={setIsOpen}
									/>
								);
							})}
				</div>
			</div>
		</>
	);
}
