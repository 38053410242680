import React from 'react';
import clsx from 'clsx';
import { Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SectionTitle from './SectionTitle';

const useStyles = makeStyles(
	(theme) => ({
		info: {
			padding: 24,
			backgroundColor: (props) =>
				!props.transparent && theme.palette.gunpowder,
			borderRadius: 8
		},
		infoMarginTop: {
			marginTop: '3.25rem'
		}
	}),
	{ name: 'InnerSection' }
);

const InnerSection = ({ sectionTitle, children, transparent, ...props }) => {
	const classes = useStyles({ transparent });
	const contentClass = sectionTitle
		? classes.info
		: clsx(classes.info, classes.infoMarginTop);

	return (
		<Grid {...props}>
			{sectionTitle && <SectionTitle>{sectionTitle}</SectionTitle>}
			<Box className={contentClass}>{children}</Box>
		</Grid>
	);
};

export default InnerSection;
