import React, { useState } from 'react';
import style from './selectedTournaments.module.scss';
import MenuLateral from 'components/menu-lateral';
import { InputText, Button } from 'components/form';
import { useHistory } from 'react-router';
import routes from 'routes';

export default function SelectedTournaments() {
	const history = useHistory();

	const [tournamentId, setTournamentId] = useState();
	const [matchId, setMatchId] = useState();

	function goRoute(route) {
		history.push(route);
	}

	return (
		<>
			<div className={style.container}>
				<MenuLateral name="campeonatos" routes={routes.tournaments} />
				<div className={style.content}>
					<h1>Editar partida campeonato</h1>
					<InputText
						label="Tournament ID"
						value={tournamentId}
						handleChange={(event) => setTournamentId(event)}
					/>
					<InputText
						label="Match ID"
						value={matchId}
						handleChange={(event) => setMatchId(event)}
					/>
					<Button
						label="Editar partida"
						onClick={() => console.log('teste')}
					/>
				</div>
			</div>
		</>
	);
}
