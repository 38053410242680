import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(
	({ palette }) => ({
		root: {
			display: 'flex',
			backgroundColor: '#0F0F10',
			minHeight: '100vh',
			color: '#ffffff',
			flexDirection: 'column',
			paddingTop: 15,
			paddingLeft: 15,
			paddingRight: 20,
			paddingBottom: 50,
			top: 0
			// position: 'fixed',
		},
		children: {
			minWidth: '71%',
			maxWidth: '100%',
			marginLeft: 80,
			marginRight: 80
		}
	}),
	{ name: 'ContentContainer' }
);

export const ContentContainer = ({ children }) => {
	const classes = useStyles();

	return (
		<Grid container className={classes.root} spacing={0}>
			<Grid item xs={12} className={classes.children}>
				{children}
			</Grid>
		</Grid>
	);
};
