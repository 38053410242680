import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TeamAvatar from 'components/TeamAvatar';

const useStyles = makeStyles(
	(theme) => {
		const scoreBase = {
			fontWeight: 500,
			fontSize: 28
		};

		return {
			grid: {
				padding: [[0, '1rem']]
			},
			teamNames: {
				fontSize: '1.105rem',
				color: 'white',
				fontWeight: 500,
				margin: [['auto', 0]]
			},
			scoreWinner: {
				...scoreBase,
				color: theme.palette.primary.main
			},
			scoreLoser: {
				...scoreBase,
				color: 'white'
			}
		};
	},
	{ name: 'MatchInfo' }
);

const TeamScore = ({ position = 'left', winner, team, score }) => {
	const classes = useStyles();
	const { avatar, name } = team;
	const direction = position === 'right' ? 'row-reverse' : 'row';

	return (
		<Grid
			container
			item
			xs
			direction={direction}
			className={classes.grid}
			alignItems="center"
		>
			<TeamAvatar size="small" src={avatar} />
			<Grid item xs>
				<Typography className={classes.teamNames} align={position}>
					{name}
				</Typography>
			</Grid>
			<Typography
				component="span"
				className={winner ? classes.scoreWinner : classes.scoreLoser}
			>
				{score}
			</Typography>
		</Grid>
	);
};

export default TeamScore;
