import React, { useState } from 'react';

import HeaderContainer from '../container/HeaderContainer.jsx';
import { ContentContainer } from '../container/ContentContainer';

import {
	Typography,
	Grid,
	makeStyles,
	Button,
	TextField
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(
	(theme) => ({
		gridpadding: {
			padding: 20
		},
		button: {
			width: '60%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		},
		title2: {
			width: '100%',
			fontWeight: 500,
			fontSize: 30,
			textTransform: 'uppercase',
			marginRight: '300px'
		},

		title: {
			fontWeight: 500,
			fontSize: 30,
			textTransform: 'uppercase',
			marginLeft: '150px',
			marginTop: '20px',
			paddingBottom: '20px'
		},

		inputkeys: {
			width: '30%',
			paddingRight: 20,
			'& .MuiFilledInput-input': {
				padding: '10px 12px 10px'
			},
			'& .MuiFilledInput-underline:before': {
				borderBottom: '0px solid #000'
			},
			'& .MuiFilledInput-root': {
				backgroundColor: theme.palette.lead,
				borderRadius: 4
			}
		}
	}),
	{ name: 'Dashboard' }
);

const Dashboard = () => {
	const classes = useStyles();
	const history = useHistory();
	const [id, setId] = useState();
	const [cancelMatchId, setCancelMatchId] = useState();

	const [match, setMatch] = useState();

	const handleChangeId = (e) => {
		setId(e.target.value);
	};
	const handleChangeMatchId = (e) => {
		setCancelMatchId(e.target.value);
	};

	const handleChangeMatch = (e) => {
		setMatch(e.target.value);
	};

	// const toTournaments = () => {
	// 	history.push('/dashboard/tournaments');
	// };
	const toCreateMatch = () => {
		history.push('/dashboard/createMatch');
	};

	const toCancelMatch = () => {
		history.push(`/dashboard/match/cancelMatch/`);
	};

	const toCreateTournament = () => {
		history.push('/dashboard/tournaments/create');
	};

	const toTournamentMatch = () => {
		history.push(`/dashboard/tournament/match/edit`);
	};

	const toBanPlayer = () => {
		history.push(`/dashboard/banPlayer/`);
	};
	const toUnBanPlayer = () => {
		history.push(`/dashboard/unBanPlayer/`);
	};

	return (
		<>
			<HeaderContainer />
			<ContentContainer>
				<Grid container>
					<Grid
						item
						direction="row"
						xs={6}
						className={classes.gridpadding2}
					>
						<Typography className={classes.title}>Rotas</Typography>

						<Grid direction="row" className={classes.gridpadding}>
							<Button
								className={classes.button}
								color="primary"
								variant="contained"
								onClick={toCreateTournament}
							>
								CRIAR CAMPEONATO
							</Button>
						</Grid>

						<Grid className={classes.gridpadding}>
							<Button
								className={classes.button}
								color="primary"
								variant="contained"
								onClick={toTournamentMatch}
							>
								PARTIDA DO CAMPEONATO
							</Button>
						</Grid>
					</Grid>
					<Grid
						item
						direction="row"
						xs={6}
						className={classes.gridpadding}
					>
						<Typography className={classes.title2}>
							Manutenção de partidas
						</Typography>
						<Grid direction="row" className={classes.gridpadding}>
							<Grid
								direction="row"
								className={classes.gridpadding}
							>
								<Button
									className={classes.button}
									color="primary"
									variant="contained"
									onClick={toCreateMatch}
								>
									CRIAR PARTIDA
								</Button>
							</Grid>
							<Grid
								direction="row"
								className={classes.gridpadding}
							>
								<Button
									className={classes.button}
									color="primary"
									variant="contained"
									onClick={toCancelMatch}
								>
									CANCELAR PARTIDA
								</Button>
							</Grid>
							<Grid
								direction="row"
								className={classes.gridpadding}
							>
								<Button
									className={classes.button}
									color="secondary"
									variant="contained"
									onClick={toBanPlayer}
								>
									Banir Jogador
								</Button>
							</Grid>
							{/* <Grid
								direction="row"
								className={classes.gridpadding}
							>
								<Button
									className={classes.button}
									color="secondary"
									variant="contained"
									onClick={toUnBanPlayer}
								>
									Desbanir Jogador
								</Button>
							</Grid> */}
						</Grid>
					</Grid>
				</Grid>
			</ContentContainer>
		</>
	);
};

export default Dashboard;
