import React from 'react';
import { Typography, Grid, CardMedia } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { useState } from 'react';
import { TournamentSidebar } from './TournamentSidebar';
import { useEffect } from 'react';

const useStyles = makeStyles(
	(theme) => ({
		root: {
			padding: 8,
			maxWidth: '100%',
			marginTop: 16,
			color: theme.palette.gandalf,
			'&:hover': {
				color: '#FFF',
				cursor: 'pointer',
				background: theme.palette.lead
			}
		},
		media: {
			width: 80,
			height: 94
		},

		title: {
			fontSize: 24,
			fontWeight: 'bold',
			marginLeft: 36,
			lineHeight: '36px',
			textAlign: 'left'
		},

		buttonSize: {
			width: '100%'
		}
	}),
	{ name: 'TournamentListItem' }
);

const TournamentListItem = ({ tournament, name, logo, isOpen, setIsOpen }) => {
	const classes = useStyles();
	const [sidebarOpen, setSidebarOpen] = useState(false);

	useEffect(() => {
		if (!isOpen) setSidebarOpen(false);
	}, [isOpen]);

	return (
		<>
			{sidebarOpen && (
				<TournamentSidebar
					name={name}
					tournament={tournament}
					logo={logo}
					setIsOpen={setIsOpen}
				/>
			)}
			<Grid
				container
				alignItems="center"
				spacing={1}
				className={classes.root}
			>
				<Grid item xs={1}>
					<CardMedia
						className={classes.media}
						component="img"
						image={logo}
					/>
				</Grid>
				<Grid item xs={5} style={{ zIndex: 99 }}>
					<Typography className={classes.title}>{name}</Typography>
				</Grid>

				<Grid item xs={1}>
					<Typography>
						{tournament.entryFee
							? `R$${tournament.entryFee}`
							: 'Grátis'}
					</Typography>
					<Typography variant="caption">Entry fee</Typography>
				</Grid>
				<Grid item xs={1}>
					<Typography>
						{tournament.teamSize}x{tournament.teamSize}
					</Typography>
					<Typography variant="caption">Type</Typography>
				</Grid>
				<Grid item xs={1}>
					<Typography>
						{tournament.minEntries}/{tournament.maxEntries}
					</Typography>
					<Typography variant="caption">Teams</Typography>
				</Grid>
				<Grid item xs={1}>
					<Button
						color="secondary"
						variant="contained"
						onClick={() => {
							setSidebarOpen((oldState) => !oldState);
							setIsOpen((oldState) => !oldState);
						}}
						className={classes.buttonSize}
					>
						INFOS
					</Button>
				</Grid>
			</Grid>
		</>
	);
};

export default TournamentListItem;
