import React from 'react';
import { Grid, CardMedia, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import useApproveReport from 'utils/hooks/useApproveReport';
import { useCallback } from 'react';
const useStyles = makeStyles(
	(theme) => ({
		root: {
			padding: 8,
			maxWidth: '100%',
			marginTop: 16,
			color: theme.palette.gandalf,
			'&:hover': {
				color: '#FFF',
				cursor: 'pointer',
				background: theme.palette.lead
			}
		},
		media: {
			width: 40,
			height: 40
		},
		title: {
			fontSize: 24,
			marginLeft: -24,
			fontWeight: 'bold',
			lineHeight: '36px',
			textAlign: 'left'
		}
	}),
	{ name: 'TournamentReportsItem' }
);

const TournamentReportsItem = ({ report, tournamentId, match }) => {
	const classes = useStyles();
	const history = useHistory();

	const { approveReport, isLoading } = useApproveReport({
		matchId: match?.id,
		reportId: report?.id,
		tournamentId
	});

	const handleStatus = useCallback(() => {
		if (report?.adminDecision)
			return (
				<Typography variant="h6" color="primary">
					Aprovado
				</Typography>
			);
		return report?.disputedResult ? (
			<Typography variant="h6" color="error">
				Contestado
			</Typography>
		) : (
			<Typography variant="h6" style={{ color: 'white' }}>
				Aberto
			</Typography>
		);
	}, [report]);

	return (
		<Grid
			container
			alignItems="center"
			spacing={1}
			className={classes.root}
			onClick={() => {
				history.push({
					pathname: location.pathname,
					search: `?matchId=${match.id}&reportId=${report.id}`
				});
			}}
		>
			<Grid item xs={1}>
				<CardMedia
					className={classes.media}
					component="img"
					image={report.user.avatar}
				/>
			</Grid>
			<Grid item xs={2}>
				<Typography style={{ color: 'white' }} variant="h6">
					<strong>
						{report.user.name} - {report.userId}
					</strong>
				</Typography>
				<Typography variant="caption">User</Typography>
			</Grid>
			<Grid item xs={2}>
				<Typography style={{ color: 'white' }} variant="h6">
					<strong>{report.teamAScore}</strong>
				</Typography>
				<Typography variant="caption">
					{match?.teamA?.name || 'A'} Score
				</Typography>
			</Grid>
			<Grid item xs={2}>
				<Typography style={{ color: 'white' }} variant="h6">
					<strong>{report.teamBScore}</strong>
				</Typography>
				<Typography variant="caption">
					{match?.teamB?.name || 'B'} score
				</Typography>
			</Grid>
			<Grid item xs={2}>
				<Typography style={{ color: 'white' }} variant="h6">
					<strong>{report.matchId}</strong>
				</Typography>
				<Typography variant="caption">Match ID</Typography>
			</Grid>
			<Grid item xs={1}>
				<Typography style={{ color: 'white' }} variant="h6">
					<strong>{report.lineupId}</strong>
				</Typography>
				<Typography variant="caption">Lineup ID</Typography>
			</Grid>
			<Grid item xs={1}>
				<Typography style={{ color: 'white' }} variant="h6">
					<strong>{handleStatus()}</strong>
				</Typography>
				<Typography variant="caption">Status</Typography>
			</Grid>
			<Grid
				item
				xs={1}
				onClick={(event) => {
					event.preventDefault();
					event.stopPropagation();
				}}
			>
				<Button
					onClick={approveReport}
					disabled={
						isLoading || report.adminDecision || !!match.outcome
					}
					color="primary"
					variant="contained"
				>
					{report.adminDecision || !!match.outcome
						? `JÁ CONFIRMADO`
						: `APROVAR`}
				</Button>
			</Grid>
		</Grid>
	);
};
export default TournamentReportsItem;
