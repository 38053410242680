import {
	CircularProgress,
	Grid,
	TextField,
	Typography
} from '@material-ui/core';
import MenuLateral from 'components/menu-lateral';
import { useAPI } from 'hooks';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import routes from 'routes';
import TournamentMatchReportsItem from './match/TournamentMatchReportsItem';
import TournamentReportModal from './modal/TournamentReportModal';
import style from './tournamentReports.module.scss';

export default function TournamentReports(props) {
	const tournamentId = props.match.params.idTournament;
	const [userSearch, setUserSearch] = useState('');
	const [contestedFilter, setContestedFilter] = useState('all');
	const [reportsByMatch, setReportsByMatch] = useState([]);

	const { search } = useLocation();

	const query = new URLSearchParams(search);

	const selectedReportId = query.get('reportId');
	const selectedMatchId = query.get('matchId');

	const selectedMatch = reportsByMatch?.find(
		(match) => String(match?.id) === selectedMatchId
	);
	const selectedReport = selectedMatch?.reports.find(
		(report) => String(report.id) === selectedReportId
	);

	const { response: reports = [], isLoading } = useAPI(
		`/tournaments/${tournamentId}/reports`
	);

	const { response: contestedReports = [] } = useAPI(
		`/tournaments/${tournamentId}/reports?disputedResult=1`
	);

	const { response: matches = [] } = useAPI(
		`/tournaments/${tournamentId}/matches`
	);

	const tournamentName =
		matches?.at(0)?.tournament?.name || query.get('tournamentName');

	const handleReportsByMatch = useCallback(() => {
		const matchByObjectId = matches.reduce((acc, match) => {
			acc[match.id] = match;
			return acc;
		}, {});

		const matchReports = [];

		(contestedFilter === 'contested' ? contestedReports : reports)?.map(
			(report) => {
				const item = matchReports.find(
					(item) => item.id === report.matchId
				);
				if (item) {
					item.reports.push(report);
				} else if (matchByObjectId[report.matchId]) {
					matchReports.push({
						...matchByObjectId[report.matchId],
						reports: [report]
					});
				}
			}
		);
		setReportsByMatch(matchReports);
	}, [reports, matches, contestedFilter]);

	const filteredMatches = reportsByMatch
		? reportsByMatch.filter((match) =>
				String(match.id).includes(userSearch)
		  )
		: [];

	useEffect(() => {
		handleReportsByMatch();
	}, [reports, matches, contestedFilter]);

	return (
		<div>
			<div className={style.container}>
				<MenuLateral name="campeonatos" routes={routes.tournaments} />
				<div>
					<Grid
						container
						alignItems="center"
						direction="row"
						className={style.content}
					>
						<Grid item xs={6}>
							<Typography variant="h4">
								Reports - {tournamentName}{' '}
							</Typography>
						</Grid>
						<Grid item xs={3}>
							<TextField
								value={userSearch}
								onChange={(e) => setUserSearch(e.target.value)}
								placeholder="Buscar Partida por ID"
							/>
						</Grid>
						<Grid item xs={2}>
							<ToggleButtonGroup
								value={contestedFilter}
								onChange={setContestedFilter}
								aria-label="contesting filter"
								exclusive
								name="contestFilter"
							>
								<ToggleButton
									value="all"
									aria-label="bold"
									style={{ marginRight: 16 }}
								>
									<Typography
										display="inline"
										style={{ marginLeft: '8px' }}
									>
										Todos
									</Typography>
								</ToggleButton>
								<ToggleButton
									value="contested"
									aria-label="underlined"
								>
									<Typography
										display="inline"
										style={{ marginLeft: '8px' }}
									>
										Contestados
									</Typography>
								</ToggleButton>
							</ToggleButtonGroup>
						</Grid>
					</Grid>
					{isLoading ? (
						<CircularProgress />
					) : (
						(userSearch !== '' ? filteredMatches : reportsByMatch)
							.filter((match) => !match.outcome)
							.map((match) => (
								<TournamentMatchReportsItem
									key={match.id}
									match={match}
									tournamentId={tournamentId}
								/>
							))
					)}
				</div>
			</div>
			<TournamentReportModal
				report={selectedReport}
				match={selectedMatch}
				tournamentId={tournamentId}
			/>
		</div>
	);
}
