import React, { useState } from 'react';
import {
	Button,
	Divider,
	Grid,
	makeStyles,
	Typography
} from '@material-ui/core';
import { Document, Page } from 'react-pdf';

const useStyles = makeStyles(
	() => ({
		root: {
			minHeight: 700,
			minWidth: 568,
			maxWidth: 568
		},
		document: {
			marginTop: -20,
			maxHeight: 600,
			overflowY: 'scroll'
		},
		divider: {
			marginTop: -20
		}
	}),
	{ name: 'FaqContent' }
);

const PdfViewer = ({ pdfFile }) => {
	const classes = useStyles();
	const [pages, setPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);

	const onDocumentLoadSuccess = ({ numPages }) => {
		setPages(numPages);
	};
	const goToPrevPage = () => setPageNumber((pg) => pg - 1);
	const goToNextPage = () => setPageNumber((pg) => pg + 1);

	return (
		<Grid container className={classes.root} spacing={0}>
			<Grid item xs={12}>
				<Divider className={classes.divider} />
			</Grid>

			<Grid item xs={12}>
				{pdfFile ? (
					<Document
						className={classes.document}
						file={pdfFile}
						onLoadSuccess={onDocumentLoadSuccess}
						onLoadError={console.error}
					>
						<Page pageNumber={pageNumber} />
					</Document>
				) : (
					<Typography color="error">ERROR LOADING PDF</Typography>
				)}
			</Grid>
			<Grid item xs={12} align="center">
				<Typography variant="caption">
					Page {pageNumber} of {pages}
				</Typography>
			</Grid>
			<Grid
				item
				xs={12}
				align="center"
				spacing={1}
				justify="space-between"
			>
				<Button
					color="primary"
					disabled={pageNumber === 1}
					onClick={goToPrevPage}
				>
					Anterior
				</Button>
				<Button
					color="primary"
					disabled={pageNumber === pages}
					onClick={goToNextPage}
				>
					Próxima
				</Button>
			</Grid>
		</Grid>
	);
};

export default PdfViewer;
