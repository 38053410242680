import { combineReducers } from 'redux';
import { loginReducer } from '../duck/login';
import { recoverPasswordReducer } from '../duck/forgotPassword';
import { accountReducer } from '../duck/account';
import { userInfoReducer } from '../duck/user';
import { userNotifications } from '../duck/notifications';

const rootReducer = combineReducers({
	loginReducer,
	recoverPasswordReducer,
	accountReducer,
	userInfoReducer,
	userNotifications
});
export default rootReducer;
