import React from 'react'
import style from './header.module.scss'
import Logo from '../../assets/images/coliseumLogo.svg';
import { useHistory } from 'react-router';

export default function Header({ children }) {

    const history = useHistory()

    function goRoute(route) {
        history.push(route)
    }

    return(
        <div className={style.container}>
            <div className={style.logo}>
                <img src={Logo} />
            </div>
            <div className={style.content}>
                <ul className={style.list}>
					<li className={style.item} onClick={() => goRoute('/home')}>home</li>
                    <li className={style.item} onClick={() => goRoute('/tournaments')}>campeonatos</li>
                    <li className={style.item} onClick={() => goRoute('/matches')}>partidas</li>
                    <li className={style.item} onClick={() => goRoute('/players')}>jogadores</li>
                    <li className={style.item} onClick={() => goRoute('/content')}>conteúdo</li>
                    <li className={style.item} onClick={() => goRoute('/old-dashboard')}>dashboard antiga</li>
				</ul>
            </div>
        </div>
    )
}