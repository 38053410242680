import React, { useState, useEffect } from 'react'
import style from './dialog.module.scss'
import { Button } from 'components/form'

export default function Dialog({ open, children, handlerClick, close, noButton }) {
    const [status, setStatus] = useState()

    useEffect(() => {
        setStatus(open)
    }, [open])

    return (
        <dialog open={status ? 'true' : false}>
            <div className={style.container}>
                <div className={style.content}>
                    <header>
                        <span onClick={() => close()} className={style.close}>X</span>
                    </header>
                    <div>
                        { children }
                        { !noButton ? <Button handlerButton={() => handlerClick(true)} label="SIM"></Button> : '' }
                    </div>
                </div>
            </div>
        </dialog>
    )
}