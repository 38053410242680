import React, { useState } from 'react';
import Layout from 'components/Layout';
import style from './editTournaments.module.scss';
import { InputText } from 'components/form';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import routes from 'routes';

export default function EditTournaments() {
	const [tournamentId, setTournamentId] = useState();
	const [matchId, setMatchId] = useState();

	const history = useHistory();

	const handleChangeTournamentId = (e) => {
		setTournamentId(e.target.value);
	};

	const handleChangeMatchId = (e) => {
		setMatchId(e.target.value);
	};

	const handleSubmit = () => {
		if (tournamentId && matchId) {
			history.push(`/edit/tournaments/${tournamentId}/match/${matchId}/`);
		} else {
			console.log('ID or Match not found');
		}
	};

	return (
		<Layout routes={routes.tournaments} name={'campeonatos'}>
			<div className={style.root}>
				<h1>Editar partida de campeonato</h1>
				<InputText
					label="Tournament ID"
					value={tournamentId}
					handleChange={handleChangeTournamentId}
				/>
				<InputText
					label="Match ID"
					value={matchId}
					handleChange={handleChangeMatchId}
				/>
				<Button
					label="Editar partida"
					onClick={handleSubmit}
					className={style.button}
				>
					Editar partida
				</Button>
			</div>
		</Layout>
	);
}
