import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TierWrapper from './Bracket/TierWrapper';
import BracketHeading from './Bracket/Heading';

// import { getTournaments } from '../../services/tournaments';
const useStyles = makeStyles(
	() => ({
		root: {
			display: 'flex',
			flexFlow: 'row nowrap',
			paddingBottom: '3.25rem',
			overflow: 'auto',
			'&::before': {
				content: '""',
				margin: 'auto'
			},
			'&::after': {
				content: '""',
				margin: 'auto'
			}
		}
	}),
	{ name: 'TeamCard' }
);

const TournamentContentBrackets = ({ tournament }) => {
	const classes = useStyles();
	const { main } = tournament.brackets;

	return (
		<div>
			<BracketHeading />
			<div className={classes.root}>
				{main.map((tier, i) => {
					const isFinal = i + 1 === main.length;
					return (
						<TierWrapper
							tier={tier}
							idx={i}
							isFinal={isFinal}
							key={`tier-${i}`}
						/>
					);
				})}
			</div>
		</div>
	);
};

export default TournamentContentBrackets;
