import React from 'react';
import style from './style.module.scss';
import { useHistory } from 'react-router';
import { Box, Typography } from '@material-ui/core';

export default function MenuLateral({ name, routes }) {
	const history = useHistory();

	function goRoute(route) {
		history.push(route);
	}

	const isSelected = (route) => {
		return history.location.pathname === route;
	};

	return (
		<>
			<aside className={style.container}>
				<p className={style.title}>{name}</p>
				<ul className={style.list}>
					{routes.map((route, index) => {
						return (
							<li
								key={index}
								className={
									isSelected(route.path)
										? style.selectedRoute
										: style.item
								}
								onClick={() => goRoute(route.path)}
							>
								<Box className={style.flexDiv}>
									{route.icon}
									<Typography className={style.text}>
										{route.name}
									</Typography>
								</Box>
							</li>
						);
					})}
				</ul>
			</aside>
		</>
	);
}
