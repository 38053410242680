// Actions
export const updateAccountBalance = (newValue) => ({
	type: 'ACCOUNT_BALANCE',
	balance: newValue
});

// Initial State
const initialState = {
	balance: 15.642
};

// reducer
export const accountReducer = (state = initialState, { type, balance }) => {
	switch (type) {
		case 'ACCOUNT_BALANCE':
			return {
				...state,
				balance
			};
		default:
			return state;
	}
};
