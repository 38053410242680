import React, { useState } from 'react';
import style from './create.module.scss';
import MenuLateral from 'components/menu-lateral';
import { Button, InputText } from 'components/form';
import { createNewMatch } from 'services/matches';
import routes from 'routes';

export default function MatchesCreate() {
	const [playerA, setPlayerA] = useState();
	const [playerB, setPlayerB] = useState();
	const [teamA, setTeamA] = useState([]);
	const [teamB, setTeamB] = useState([]);

	function addPlayerTeamA(playerA) {
		if (playerA && teamA.length < 5) {
			setTeamA([...teamA, Number(playerA)]);
			setPlayerA('');
		}
	}

	function addPlayerTeamB(playerB) {
		if (playerB && teamB.length < 5) {
			setTeamB([...teamB, Number(playerB)]);
			setPlayerB('');
		}
	}

	function removePlayerTeamA(id) {
		const newTeamA = teamA.filter((player) => player !== id);
		setTeamA(newTeamA);
	}

	function removePlayerTeamB(id) {
		const newTeamB = teamB.filter((player) => player !== id);
		setTeamB(newTeamB);
	}

	function createMatch(teamA, teamB) {
		createNewMatch('cpl', teamA, teamB);
	}

	return (
		<>
			<div className={style.container}>
				<MenuLateral name="partidas" routes={routes.matches} />
				<div className={style.content}>
					<h1>Criar partidas</h1>
					<div className={style.box}>
						<div className={style.team}>
							<h3>Team A</h3>
							{teamA.length != 5 && (
								<div className={style.inputs}>
									<InputText
										label="Nome do jogador"
										type="number"
										value={playerA}
										handleChange={(event) =>
											setPlayerA(event.target.value)
										}
									/>
									<div className={style.button}>
										<Button
											label="Adicionar"
											handlerButton={() =>
												addPlayerTeamA(playerA)
											}
										/>
									</div>
								</div>
							)}
							<ul className={style.list}>
								{teamA.map((team) => {
									return (
										<li className={style.item}>
											<p>{team}</p>
											<span
												onClick={() =>
													removePlayerTeamA(team)
												}
											>
												REMOVER
											</span>
										</li>
									);
								})}
							</ul>
						</div>
						<div className={style.team}>
							<h3>Team B</h3>
							{teamB.length != 5 && (
								<div className={style.inputs}>
									<InputText
										label="Nome do jogador"
										type="number"
										value={playerB}
										handleChange={(event) =>
											setPlayerB(event.target.value)
										}
									/>
									<div className={style.button}>
										<Button
											label="Adicionar"
											handlerButton={() =>
												addPlayerTeamB(playerB)
											}
										/>
									</div>
								</div>
							)}
							<ul className={style.list}>
								{teamB.map((team) => {
									return (
										<li className={style.item}>
											<p>{team}</p>
											<span
												onClick={() =>
													removePlayerTeamB(team)
												}
											>
												REMOVER
											</span>
										</li>
									);
								})}
							</ul>
						</div>
					</div>
					{teamB.length == 5 && teamB.length == 5 && (
						<Button
							label="Criar partida"
							handlerButton={() => createMatch(teamA, teamB)}
						/>
					)}
				</div>
			</div>
		</>
	);
}
