import { recoverPassword } from 'services/login';

// types
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';
export const FORGOT_PASSWORD_RESET = 'FORGOT_PASSWORD_RESET';

// actions
export const forgotPassword = () => ({ type: FORGOT_PASSWORD });
export const forgotPasswordSuccess = () => ({
	type: FORGOT_PASSWORD_SUCCESS
});
export const forgotPasswordFailure = () => ({ type: FORGOT_PASSWORD_FAILURE });

export const forgotPasswordReset = () => ({ type: FORGOT_PASSWORD_RESET });

export function dispatchForgotPassword(email, reCaptcha) {
	return (dispatch) => {
		dispatch(forgotPassword());
		recoverPassword(email, reCaptcha)
			.then(({ data }) => {
				if (data.message && data.message === 'success') {
					dispatch(forgotPasswordSuccess());
				} else {
					dispatch(forgotPasswordFailure());
				}
			})
			.catch(() => {
				dispatch(forgotPasswordFailure());
			});
	};
}

// state
export const initialState = {
	loading: true,
	hasErrors: false,
	message: null
};

// reducer
export function recoverPasswordReducer(state = initialState, action) {
	switch (action.type) {
		case FORGOT_PASSWORD:
			return { ...state, loading: true };
		case FORGOT_PASSWORD_SUCCESS:
			return {
				...state,
				loading: false,
				hasErrors: false,
				message: 'success'
			};
		case FORGOT_PASSWORD_FAILURE:
			return { ...state, loading: false, hasErrors: true, message: null };
		case FORGOT_PASSWORD_RESET:
			return {
				...state,
				loading: false,
				hasErrors: false,
				message: null
			};
		default:
			return state;
	}
}
