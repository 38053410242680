import React from 'react';
import style from './cancel.module.scss';
import MenuLateral from 'components/menu-lateral';
import routes from 'routes';

export default function MatchesCancel() {
	return (
		<>
			<div className={style.container}>
				<MenuLateral name="partidas" routes={routes.matches} />
				<div className={style.content}>
					<h1>Cancelar partidas</h1>
				</div>
			</div>
		</>
	);
}
