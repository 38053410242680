import React, { useState } from 'react';
import { makeStyles, Button, Switch } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import ptBRLocale from 'date-fns/locale/pt-BR';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Flex, Typography } from '@coliseum.gg/ui';
import { applyRestart } from 'services/applyRestart';
import { formatDateToService } from './Reschedule';

const useStyles = makeStyles((theme) => ({
	dialogsubtitle: {
		...theme.typography.text,
		marginTop: 0,
		marginBottom: 0
	},
	dialogcontent: {
		padding: '8px',
		'&:first-child': {
			paddingTop: '0px'
		},
		'& .MuiFilledInput-underline:before': {
			borderBottom: '0px solid #000'
		},
		'& .MuiFilledInput-root': {
			backgroundColor: theme.palette.lead
		}
	},
	restartdescription: {
		...theme.typography.text,
		fontSize: 14,
		marginTop: 0,
		marginBottom: 0,
		color: theme.palette.grey
	},
	warning: {
		...theme.typography.text,
		fontSize: 14,
		marginTop: 0,
		marginBottom: 0,
		color: theme.palette.bronze
	}
}));

const locale = ptBRLocale;

const Restart = ({ matchInfo, tournament, match, dialogToggleRestart }) => {
	const classes = useStyles();
	const [restart, setRestart] = useState(false);
	const [selectedDate, handleDateChange] = useState(
		new Date().toISOString().replace('T', ' ').substring(0, 19)
	);

	const unrestartable = matchInfo.status === 4;

	function handleRestart() {
		const formattedPayload = formatDateToService(selectedDate);
		applyRestart(tournament, match, formattedPayload)
			.then(() => {
				alert('Partida restartada com sucesso.');
				dialogToggleRestart();
			})
			.catch((e) => {
				alert(e);
			});
	}

	return (
		<Flex direction="column">
			<Flex direction="column" gap="16" css={{ padding: '$8' }}>
				<Typography className={classes.dialogsubtitle}>
					Reiniciar a partida
				</Typography>
				<Typography
					color="neutral100"
					className={classes.restartdescription}
					variant="caption"
				>
					Esse método, diferentemente do reset, reinicia a partida sem
					perder os vetos, derruba o servidor e agenda um novo
					horário.
				</Typography>
				Escolha o novo horário
				<MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
					<DateTimePicker
						inputVariant="outlined"
						ampm={false}
						value={selectedDate}
						onChange={handleDateChange}
						format="dd/MM/yyyy HH:mm"
					/>
				</MuiPickersUtilsProvider>
				<Flex align="center" css={{ gap: '$8' }}>
					<Typography className={classes.dialogsubtitle}>
						Você tem certeza?
					</Typography>
					<Switch
						checked={restart}
						onChange={() => setRestart(!restart)}
						name="checkRestart"
						color="primary"
					/>
				</Flex>
			</Flex>
			<Flex direction="column" gap="8">
				<Button
					size="large"
					onClick={handleRestart}
					variant="contained"
					style={{ width: '100%' }}
					color="primary"
					disabled={!restart || unrestartable}
				>
					REINICIAR
				</Button>
				{unrestartable && (
					<Typography className={classes.warning}>
						O status da partida deve ser diferente de 4.
					</Typography>
				)}
			</Flex>
		</Flex>
	);
};

export default Restart;
