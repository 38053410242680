import React from 'react';
import clsx from 'clsx';
import { CardMedia, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
	(theme) => ({
		prize: {
			marginBottom: 10,
			margin: [[0, 'auto']],
			overflow: 'hidden',
			position: 'relative',
			borderBottomLeftRadius: 4
		},

		prizePlace: {
			backgroundColor: theme.palette.gold,
			//   box-shadow: 0 0 3px 2px rgba(0,0,0,0.8);
			height: 90,
			left: -45,
			position: 'absolute',
			bottom: -45,
			width: 90,
			borderRadius: 8,
			transform: 'rotate(-45deg)'
		},

		prizeSecond: {
			backgroundColor: theme.palette.silver
		},

		prizeThird: {
			backgroundColor: theme.palette.bronze
		},

		prizeNum: {
			color: 'white',
			left: 60,
			bottom: 26,
			position: 'absolute',
			zIndex: 99,
			fontSize: 20,
			// width: 80,
			transform: 'rotate(45deg)',

			'& > p': {
				margin: 0,
				fontSize: 10
			}
		}
	}),
	{ name: 'Prize' }
);

const Item = ({ prize, idx }) => {
	const classes = useStyles();
	const { image, name } = prize;
	const prizeClasses = [
		classes.prizePlace,
		clsx(classes.prizePlace, classes.prizeSecond),
		clsx(classes.prizePlace, classes.prizeThird)
	];

	return (
		<Box className={classes.prize}>
			<CardMedia component="img" image={image} alt={name} />
			<div className={prizeClasses[idx]}>
				<span className={classes.prizeNum}>
					{idx + 1}º <p>LUGAR</p>
				</span>
			</div>
		</Box>
	);
};

export default Item;
