import {
	Button,
	Flex,
	styled,
	Table,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Toggle,
	ToggleItem,
	Typography
} from '@coliseum.gg/ui/dist';
import MenuLateral from 'components/menu-lateral';
import React from 'react';
import routes from 'routes';
import { useDebounce } from 'react-use';
import { Search } from '@coliseum.gg/shared';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { ContentItem } from './ContentItem';
import { ContentItemActions } from './ContentItemActions';

const StyledFlex = styled(Flex, {
	padding: '20px',
	margin: '20px 20px 0 0',
	width: '100%',
	background: '#0F0F10',
	height: '100%',
	borderRadius: '8px',
	color: '#fff',
	gap: '40px'
});

export const Content = () => {
	const [selectedPost, setSelectedPost] = useState();
	const contentToken = localStorage.getItem('contentToken');
	const [token, setToken] = useState(contentToken);
	const [userSearch, setUserSearch] = useState('');
	const [debounced, setDebounced] = useState('');
	const [data, setData] = useState();
	const [showType, setShowType] = useState('published');
	const [updater, refetch] = useState(false);
	const filter = ``.concat(debounced ? `search=${debounced}` : '');

	const apiUrl = process.env.REACT_APP_CONTENT_API_URL;

	useDebounce(
		() => {
			setDebounced(userSearch || '');
		},
		300,
		[userSearch]
	);

	const filterUrl =
		filter !== ''
			? `${apiUrl}/content/feed/filtered-feed?${filter}`
			: {
					published: `${apiUrl}/content/feed`,
					unpublished: `${apiUrl}/content/admin/unpublished`,
					deleted: `${apiUrl}/content/admin/deleted`
			  }[showType];

	useEffect(() => {
		if (filterUrl)
			axios
				.get(filterUrl, {
					headers: { Authorization: `Bearer ${token}` }
				})
				.then((response) => setData(response.data));
	}, [updater, filterUrl]);

	return (
		<Flex>
			<MenuLateral name="conteúdo" routes={routes.content} />
			<StyledFlex direction="column">
				<Flex gap="16" direction="column">
					<Typography variant="h3">Lista de Posts</Typography>
					<Flex>
						<TextField
							value={token}
							onChange={(e) => {
								setToken(e.target.value);
								localStorage.setItem(
									'contentToken',
									e.target.value
								);
							}}
							placeholder="Token de ADM"
							containerProps={{ css: { width: '30vw' } }}
						/>
						{token && (
							<>
								{showType === 'published' ? (
									<TextField
										onChange={(e) => {
											setUserSearch(
												e.target.value.toLowerCase()
											);
										}}
										maxLength={32}
										placeholder="Buscar Vídeo"
										containerProps={{
											css: { width: '25vw' }
										}}
										Icon={<Search color="#A1A1A1" />}
									/>
								) : (
									<Flex
										css={{
											width: '28vw'
										}}
									/>
								)}

								<Flex>
									{filter === '' && (
										<Toggle
											type="single"
											value={showType}
											onValueChange={(value) => {
												if (value) setShowType(value);
											}}
											css={{ display: `flex` }}
										>
											<ToggleItem
												css={{
													'&[data-state=on]': {
														boxShadow:
															'0px 0px 7px #0092EE'
													}
												}}
												value="published"
											>
												<Typography color="white">
													Publicados
												</Typography>
											</ToggleItem>
											<ToggleItem
												css={{
													'&[data-state=on]': {
														boxShadow:
															'0px 0px 7px #0092EE'
													}
												}}
												value="unpublished"
											>
												<Typography color="white">
													Não Publicados
												</Typography>
											</ToggleItem>
											<ToggleItem
												css={{
													'&[data-state=on]': {
														boxShadow:
															'0px 0px 7px #0092EE'
													}
												}}
												value="deleted"
											>
												<Typography color="white">
													Deletados
												</Typography>
											</ToggleItem>
										</Toggle>
									)}
								</Flex>
							</>
						)}
					</Flex>
				</Flex>
				{token && (
					<Table ghost>
						<TableHead>
							<TableRow>
								<TableCell css={{ width: '12%' }}>
									Thumbnail
								</TableCell>
								<TableCell css={{ width: '20%' }}>
									Título
								</TableCell>
								<TableCell css={{ width: '20%' }}>
									Conteúdo
								</TableCell>
								<TableCell css={{ width: '15%' }}>
									Usuário
								</TableCell>
								<TableCell css={{ width: '30%' }}>
									URL Vídeo
								</TableCell>
								<TableCell css={{ width: '10%' }}>
									Destacado
								</TableCell>
							</TableRow>
						</TableHead>
						{data?.map((post) => (
							<ContentItem
								onClick={() => setSelectedPost(post)}
								post={post}
							/>
						))}
					</Table>
				)}
			</StyledFlex>

			{selectedPost && (
				<ContentItemActions
					token={token}
					post={selectedPost}
					setSelectedPost={setSelectedPost}
					refetch={refetch}
				/>
			)}
		</Flex>
	);
};
