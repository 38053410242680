import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

function getHeight(n) {
	return Array(n)
		.fill(1)
		.reduce((height) => 2 * height + 2, 126);
}

const useStyles = makeStyles(
	() => ({
		root: {
			top: 55,
			right: -46,
			width: 36,
			border: '2px solid',
			height: ({ level }) => getHeight(level),
			zIndex: '1',
			position: 'absolute',
			borderColor: '#25272C',
			borderLeftStyle: 'none',
			borderTopRightRadius: '4px',
			borderBottomRightRadius: '4px'
		}
	}),
	{ name: 'Connector' }
);

const Connector = ({ visible, level }) => {
	const classes = useStyles({ level });

	return (visible && <div className={classes.root} />) || null;
};

export default Connector;
