import React from 'react';
import {
	Typography,
	Dialog,
	Box,
	DialogTitle,
	IconButton
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(
	(theme) => ({
		header: {
			margin: 0,
			padding: theme.spacing(2),
			backgroundColor: theme.palette.gunpowder,
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center'
		},

		title: {
			textTransform: 'uppercase',
			// paddingTop: 7,
			fontWeight: 700,
			display: 'flex'
		},

		closeButton: {
			// position: 'absolute',
			// right: theme.spacing(1),
			// top: theme.spacing(1),
			color: theme.palette.grey[500],
			display: 'flex'
		},

		modal: {
			// display: 'flex',
			borderRadius: 8
		},
		paper: {
			alignSelf: 'center',
			margin: 'auto',
			// minWidth: 400,
			// minHeight: 400,
			backgroundColor: theme.palette.gunpowder,
			// boxShadow: theme.shadows[5],
			padding: theme.spacing(2),
			paddingTop: theme.spacing(0),
			outline: 'none'
		}
	}),
	{ name: 'Modal' }
);

const Modal = (props) => {
	const classes = useStyles();
	const { toggleModal, isShowingModal, children, title } = props;

	return (
		<Dialog
			open={isShowingModal}
			onClose={toggleModal}
			className={classes.modal}
			disableAutoFocus
			disableEnforceFocus
			disablePortal
			disableRestoreFocus
			PaperProps={{
				style: {
					backgroundColor: '#1B1D20'
				}
			}}
		>
			<DialogTitle disableTypography className={classes.header}>
				<Typography variant="h6" className={classes.title}>
					{title || 'NO_TITLE'}
				</Typography>
				<IconButton
					aria-label="close"
					className={classes.closeButton}
					onClick={toggleModal}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<Box className={classes.paper}>{children}</Box>
		</Dialog>
	);
};

export default Modal;
