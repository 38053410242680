import {
	ContentCard,
	CSGO_ICON as IconCSGO,
	LOLIcon as IconLOL,
	FifaIcon as IconFIFA,
	Valorant
} from '@coliseum.gg/shared';
import {
	Avatar,
	Button,
	CircularProgress,
	Flex,
	styled,
	TextField,
	Toggle,
	ToggleItem,
	Typography
} from '@coliseum.gg/ui';
import axios from 'axios';
import MenuLateral from 'components/menu-lateral';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import routes from 'routes';
import { schema } from './schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDebounce } from 'react-use';
import { useFindPlayers } from 'utils/hooks/useFindPlayers';

const StyledFlex = styled(Flex, {
	padding: '20px',
	margin: '20px 20px 0 0',
	width: '100%',
	background: '#0F0F10',
	height: '100%',
	borderRadius: '8px',
	color: '#fff',
	gap: '40px'
});

export const ContentCreate = ({ match }) => {
	const contentToken = localStorage.getItem('contentToken');
	const postId = match.params.postId;

	const [userSearch, setUserSearch] = useState('');
	const [userId, setUserId] = useState();

	const { debounced, setDebounced, players, player, isLoadingPlayers } =
		useFindPlayers(userId);

	const [game, setGame] = useState('all');
	const [token, setToken] = useState(contentToken);
	const [isLoading, setIsLoading] = useState(false);

	useDebounce(
		() => {
			setDebounced(userSearch || '');
		},
		300,
		[userSearch]
	);

	const gamesRegex = /CSGO|LOL|FIFA|all/;

	const {
		handleSubmit,
		control,
		watch,
		reset,
		formState: { errors }
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			content: ``,
			tags: ``,
			thumbnail: ``,
			title: ``,
			video: ``
		}
	});

	const preview = watch();

	const apiUrl = process.env.REACT_APP_CONTENT_API_URL;

	const createContent = (data) => {
		setIsLoading(true);
		const tags = (
			gamesRegex.test(data?.tags)
				? data?.tags.replace(gamesRegex, game)
				: data?.tags.concat(`,${game}`)
		).split(',');
		const post = {
			...data,
			tags
		};
		const contentUser = {
			avatar: player.avatar,
			username: player.name,
			userId: Number(userId)
		};
		if (postId) {
			if (window.confirm(`Deseja editar o post?`))
				axios
					.put(
						`${apiUrl}/content/admin/feed/${postId}`,
						{
							...post,
							authorId: Number(userId),
							deleted: false
						},
						{
							headers: {
								Authorization: `Bearer ${token}`,
								'access-control-allow-origin': '*',
								'access-control-allow-credentials': true
							}
						}
					)
					.then(() => alert('Post editado com sucesso!'))
					.catch((e) => alert(e.response.data.message))
					.finally(() => setIsLoading(false));
		} else {
			if (window.confirm(`Deseja criar o post?`))
				axios
					.post(
						`${apiUrl}/content/admin/feed-with-user`,
						{
							user: contentUser,
							post
						},
						{
							headers: {
								Authorization: `Bearer ${token}`,
								'access-control-allow-origin': '*',
								'access-control-allow-credentials': true
							}
						}
					)
					.then(() => alert('Post criado com sucesso!'))
					.catch((e) => alert(e.response.data.message))
					.finally(() => setIsLoading(false));
		}
		setIsLoading(false);
	};

	const onSubmit = (data) => {
		createContent(data);
	};

	useEffect(() => {
		if (postId) {
			setIsLoading(true);
			const handler = () => axios.get(`${apiUrl}/content/feed/${postId}`);
			handler()
				.then(({ data }) => {
					setUserId(data.authorId);
					reset({
						content: data.content,
						tags: Array.isArray(data.tags)
							? data.tags
									.filter((tag) => !gamesRegex.test(tag))
									.toString()
							: data.tags,
						thumbnail: data.thumbnail,
						title: data.title,
						video: data.video
					});
					setGame(
						data.tags.find(
							(tag) =>
								tag === 'CSGO' ||
								tag === 'LOL' ||
								tag === 'FIFA' ||
								tag === 'VALORANT' ||
								tag === 'all'
						) || 'all'
					);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	}, [postId]);

	useEffect(() => {
		if (postId && player) setUserSearch(player.name);
	}, [postId, player]);

	return (
		<Flex css={{ margin: 0 }}>
			<MenuLateral name="conteúdo" routes={routes.content} />
			<StyledFlex direction="column">
				<Typography variant="h3">
					{postId ? 'Editar' : 'Criar'} post
				</Typography>
				<Flex direction="column" align="center" gap="16">
					<TextField
						value={token}
						onChange={(e) => {
							setToken(e.target.value);
							localStorage.setItem(
								'contentToken',
								e.target.value
							);
						}}
						placeholder="Token de ADM"
						containerProps={{ css: { width: '30vw' } }}
					/>
					<Typography css={{ display: 'inline' }}>
						Para criar um post de patrocinador (a primeira fileira),
						inserir a tag{' '}
						<Typography color="primary" css={{ display: 'inline' }}>
							sponsor
						</Typography>
						, destacar e publicar o post. <br />
						<br />
						Para criar um post de mais vistos (a segunda fileira),
						inserir a tag{' '}
						<Typography color="primary" css={{ display: 'inline' }}>
							f34tur3d
						</Typography>
						, destacar e publicar o post. <br />
						<br />
						Para alterar o banner, inserir a tag{' '}
						<Typography color="primary" css={{ display: 'inline' }}>
							banner
						</Typography>
						, destacar e publicar o post. Caso um banner já exista,
						basta editá-lo, alterando o campo{' '}
						<Typography color="primary" css={{ display: 'inline' }}>
							URL do vídeo
						</Typography>{' '}
						para a URL a qual o banner levará, e o campo{' '}
						<Typography color="primary" css={{ display: 'inline' }}>
							URL da Thumbnail
						</Typography>{' '}
						para a imagem. (1280x200 pixels ) <br />
					</Typography>
				</Flex>
				{token && (
					<form
						onSubmit={(...args) => handleSubmit(onSubmit)(...args)}
					>
						<Flex direction="column" align="center" gap="16">
							<Flex direction="column" align="center" gap="16">
								<Typography variant="h4">Post</Typography>
								<Flex gap="16" justify="start">
									<Controller
										name="title"
										control={control}
										render={({ field }) => (
											<TextField
												placeholder="Título"
												name="title"
												invalid={
													!!errors?.title?.message
												}
												helperText={
													errors?.title?.message
												}
												containerProps={{
													css: {
														width: '25vw'
													}
												}}
												{...field}
											/>
										)}
									/>
									<Controller
										name="content"
										control={control}
										render={({ field }) => (
											<TextField
												placeholder="Conteúdo do post"
												invalid={
													!!errors?.content?.message
												}
												helperText={
													errors?.content?.message
												}
												name="content"
												containerProps={{
													css: { width: '25vw' }
												}}
												{...field}
											/>
										)}
									/>
									<Controller
										name="video"
										control={control}
										render={({ field }) => (
											<TextField
												placeholder="URL do vídeo"
												invalid={
													!!errors?.video?.message
												}
												helperText={
													errors?.video?.message
												}
												name="video"
												containerProps={{
													css: { width: '25vw' }
												}}
												{...field}
											/>
										)}
									/>
								</Flex>
								<Flex gap="16" wrap="wrap">
									<Controller
										name="thumbnail"
										control={control}
										render={({ field }) => (
											<TextField
												placeholder="URL da Thumbnail"
												invalid={
													!!errors?.thumbnail?.message
												}
												helperText={
													errors?.thumbnail?.message
												}
												css={{ height: `59px` }}
												name="thumbnail"
												containerProps={{
													css: {
														width: '25vw'
													}
												}}
												{...field}
											/>
										)}
									/>
									<Controller
										name="tags"
										control={control}
										render={({ field }) => (
											<TextField
												placeholder="Tags separadas por vírgula"
												name="tags"
												invalid={
													!!errors?.tags?.message
												}
												helperText={
													errors?.tags?.message
												}
												css={{ height: `59px` }}
												containerProps={{
													css: { width: '25vw' }
												}}
												{...field}
											/>
										)}
									/>
									<Flex>
										<Toggle
											type="single"
											value={game}
											onValueChange={(value) => {
												if (value) setGame(value);
											}}
											css={{ display: `flex` }}
										>
											<ToggleItem
												value="all"
												css={{
													'&[data-state=on]': {
														boxShadow:
															'0px 0px 7px #0092EE'
													}
												}}
											>
												<Typography>Todos</Typography>
											</ToggleItem>
											<ToggleItem
												value="CSGO"
												css={{
													'&[data-state=on]': {
														boxShadow:
															'0px 0px 7px #0092EE'
													}
												}}
											>
												<IconCSGO
													color="white"
													style={{
														height: '24px',
														width: '24px'
													}}
												/>
											</ToggleItem>
											<ToggleItem
												value="FIFA"
												css={{
													'&[data-state=on]': {
														boxShadow:
															'0px 0px 7px #0092EE'
													}
												}}
											>
												<IconFIFA
													color="white"
													style={{
														height: '24px',
														width: '44px'
													}}
												/>
											</ToggleItem>
											<ToggleItem
												value="LOL"
												css={{
													'&[data-state=on]': {
														boxShadow:
															'0px 0px 7px #0092EE'
													}
												}}
											>
												<IconLOL
													color="white"
													style={{
														height: '24px',
														width: '24px'
													}}
												/>
											</ToggleItem>
											<ToggleItem
												value="VALORANT"
												css={{
													'&[data-state=on]': {
														boxShadow:
															'0px 0px 7px #0092EE'
													}
												}}
											>
												<Valorant
													color="white"
													style={{
														height: '24px',
														width: '24px'
													}}
												/>
											</ToggleItem>
										</Toggle>
									</Flex>
								</Flex>
							</Flex>
							<Flex direction="column" align="center" gap="16">
								<Typography variant="h4">Usuário</Typography>
								<Flex gap="16" wrap="wrap">
									<TextField
										placeholder="Nome do usuário"
										value={userSearch}
										helperText={
											!userSearch
												? 'É obrigatório inserir um nome de usuário!'
												: ''
										}
										onChange={(e) => {
											setUserSearch(e.target.value);
										}}
										containerProps={{
											css: { width: '25vw' }
										}}
									/>
								</Flex>
								<Flex
									css={{
										maxHeight: '200px',
										overflow: isLoadingPlayers
											? 'none'
											: `auto`
									}}
									direction="column"
									gap="16"
								>
									{isLoadingPlayers ? (
										<CircularProgress />
									) : (
										debounced &&
										players &&
										players.players.map((player) => {
											return (
												<Flex
													gap="16"
													justify="between"
												>
													<Flex
														gap="16"
														align="center"
													>
														<Avatar
															src={player.avatar}
														/>
														<Typography>
															{player.username}
														</Typography>
													</Flex>
													<Button
														onClick={() =>
															setUserId(player.id)
														}
														type="button"
														rounded="medium"
														disabled={
															player.id === userId
														}
													>
														SELECIONAR
													</Button>
												</Flex>
											);
										})
									)}
								</Flex>
							</Flex>
							<Flex direction="column" align="center" gap="16">
								<Typography variant="h4">Preview</Typography>
								{preview.thumbnail && player && (
									<ContentCard
										post={preview}
										author={{ username: player.name }}
										onClick={() => console.log('click')}
									/>
								)}
							</Flex>
							<Button
								css={{ width: `100%`, marginTop: `$16` }}
								disabled={isLoading || !player || !userId}
								type="submit"
							>
								{postId ? 'EDITAR' : 'CRIAR'}
							</Button>
						</Flex>
					</form>
				)}
			</StyledFlex>
		</Flex>
	);
};
