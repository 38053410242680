import {
	Button,
	CardMedia,
	Grid,
	makeStyles,
	Typography
} from '@material-ui/core';
import { parseDateAsReadable } from 'components/Tournament/helpers';
import CachedIcon from '@material-ui/icons/Cached';
import EditIcon from '@material-ui/icons/Edit';
import GroupIcon from '@material-ui/icons/Group';
import { useState } from 'react';
import { clearCache } from 'services/tournament';
import { useHistory } from 'react-router-dom';
import ReportIcon from '@material-ui/icons/Report';

const useStyles = makeStyles(() => ({
	media: {
		width: 170,
		height: 175,
		margin: '0 auto'
	},
	buttonSize: {
		width: '100%'
	}
}));

export const TournamentSidebar = ({ tournament, name, logo }) => {
	const { id } = tournament;
	const [isLoadingCache, setIsLoadingCache] = useState(false);
	const classes = useStyles();
	const dateNow = new Date();
	const history = useHistory();
	const isRegistrationOpen =
		tournament.registrationStartDate &&
		new Date(tournament.registrationStartDate) < dateNow &&
		tournament.registrationEndDate &&
		new Date(tournament.registrationEndDate) > dateNow;

	const handleCacheClear = () => {
		if (id) {
			setIsLoadingCache(true);
			clearCache(id)
				.then(() => alert('Cache limpado com sucesso!'))
				.catch((error) => returnString(error.response.data))
				.finally(() => setIsLoadingCache(false));
		} else alert('ID not found');
	};

	const handleTournamentTeams = () => {
		id
			? history.push({
					pathname: `/tournament/${id}/teams`,
					search: `?tournamentName=${name}`
			  })
			: alert('ID not found');
	};

	const handleTournamentReports = () => {
		id
			? history.push({
					pathname: `/tournament/${id}/reports`,
					search: `?tournamentName=${name}`
			  })
			: alert('ID not found');
	};

	const handleSwissTeams = () => {
		id
			? history.push({
					pathname: `/tournament/${id}/teams/swiss`,
					search: `?tournamentName=${name}`
			  })
			: alert('ID not found');
	};

	const handleSubmit = () => {
		id
			? history.push(`/edit/tournament/${id}`)
			: alert('ID or Match not found');
	};

	return (
		<Grid
			container
			direction="column"
			wrap="nowrap"
			style={{
				height: '600px',
				width: '378px',
				position: 'fixed',
				top: `100px`,
				zIndex: 1,
				right: '16px',
				backgroundColor: '#101213',
				padding: '40px 16px'
			}}
			onClick={(e) => e.stopPropagation()}
		>
			<Grid item xs={12}>
				<CardMedia
					className={classes.media}
					component="img"
					image={logo}
				/>
				<Typography align="center" variant="h4">
					{name}
				</Typography>
			</Grid>
			<Grid container style={{ marginBottom: '16px' }}>
				<Grid
					container
					direction="row"
					xs={12}
					justifyContent="space-between"
				>
					<Grid xs={6}>
						<Typography
							variant="caption"
							style={{ color: `#A1A1A1` }}
						>
							INSCRIÇÃO
						</Typography>
					</Grid>
					<Grid xs={6}>
						<Typography
							align="right"
							color={isRegistrationOpen ? 'primary' : 'secondary'}
							variant="caption"
						>
							{isRegistrationOpen
								? `Inscrições abertas até ${parseDateAsReadable(
										tournament.registrationEndDate
								  )}`
								: 'Inscrições Encerradas'}
						</Typography>
					</Grid>
				</Grid>
				<Grid
					container
					direction="row"
					xs={12}
					justifyContent="space-between"
				>
					<Grid xs={6}>
						<Typography
							variant="caption"
							style={{ color: `#A1A1A1` }}
							align="left"
						>
							DATA
						</Typography>
					</Grid>
					<Grid xs={6}>
						<Grid>
							<Typography
								align="right"
								style={{ color: '#A1A1A1' }}
							>
								{tournament.startDate
									? parseDateAsReadable(
											tournament.startDate,
											false
									  )
									: 'A definir'}
							</Typography>
						</Grid>
						<Grid>
							<Typography
								align="right"
								style={{ color: '#A1A1A1' }}
							>
								{tournament.endDate
									? parseDateAsReadable(
											tournament.endDate,
											false
									  )
									: 'A definir'}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid container>
				<Grid
					container
					xs={12}
					justifyContent="space-between"
					style={{ marginBottom: '16px' }}
				>
					<Grid item xs={5}>
						<Button
							color="secondary"
							variant="contained"
							onClick={handleCacheClear}
							startIcon={<CachedIcon />}
							className={classes.buttonSize}
							disabled={isLoadingCache}
						>
							CACHE
						</Button>
					</Grid>
					<Grid item xs={5}>
						<Button
							color="primary"
							variant="contained"
							onClick={handleTournamentTeams}
							startIcon={<GroupIcon />}
							className={classes.buttonSize}
						>
							EQUIPES
						</Button>
					</Grid>
				</Grid>
				<Grid
					container
					xs={12}
					justifyContent="space-between"
					style={{ marginBottom: '16px' }}
				>
					<Grid item xs={5}>
						<Button
							color="primary"
							variant="contained"
							onClick={handleSubmit}
							startIcon={<EditIcon />}
							className={classes.buttonSize}
						>
							EDITAR
						</Button>
					</Grid>
					<Grid item xs={5}>
						<Button
							color="secondary"
							variant="contained"
							onClick={handleSwissTeams}
							startIcon={<GroupIcon />}
							className={classes.buttonSize}
						>
							SUIÇO
						</Button>
					</Grid>
				</Grid>
				<Grid
					container
					xs={12}
					justifyContent="start"
					style={{ marginBottom: '16px' }}
				>
					<Grid item xs={5}>
						<Button
							color="secondary"
							variant="contained"
							onClick={handleTournamentReports}
							startIcon={<ReportIcon />}
							className={classes.buttonSize}
							disabled={isLoadingCache}
						>
							REPORTS
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
