import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		width: '100%',
		color: theme.palette.smoke
	},
	input: {
		backgroundColor: theme.palette.lead,
		padding: '0.5rem'
	}
}));

const Input = (props) => {
	const classes = useStyles();

	return (
		<InputBase
			classes={{
				root: classes.wrapper,
				input: classes.input
			}}
			{...props}
		/>
	);
};

export default Input;
