import React, { useState } from 'react';
import {
	DialogContent,
	DialogContentText,
	DialogActions,
	makeStyles,
	Button,
	Switch
} from '@material-ui/core';
import { applyReset } from '../../services/applyReset';

const useStyles = makeStyles((theme) => ({
	dialogsavebutton: {
		backgroundColor: '#0092EE',
		color: '#fff',
		width: '100%',
		fontWeight: 700,
		'&:hover': {
			backgroundColor: 'slateblue'
		}
	},
	dialogsubtitle: {
		color: '#fff',
		...theme.typography.text,
		marginTop: 0,
		marginBottom: 0
	},
	dialogcontent: {
		padding: '8px',
		'&:first-child': {
			paddingTop: '0px'
		},
		'& .MuiFilledInput-underline:before': {
			borderBottom: '0px solid #000'
		},
		'& .MuiFilledInput-root': {
			backgroundColor: theme.palette.lead
		}
	}
}));

const Reset = ({ matchesinfo, tournament, match, dialogToggleRES }) => {
	const classes = useStyles();
	const [resetState, setResetState] = useState({ checkReset: false });

	const { response: reset = [], isLoaded } =
		(`/admin/tournaments/${tournament.id}/match/${match.matchid}/wo`,
		{
			method: 'post'
		});

	const checkResetSwitch = (event) => {
		setResetState({ ...reset, [event.target.name]: event.target.checked });
	};

	function handleReset() {
		applyReset(tournament, match)
			.then(dialogToggleRES())
			.catch((e) => {
				alert(e);
			});
	}

	function saveCheck() {
		return resetState.checkReset;
	}

	return (
		<>
			<DialogContent className={classes.dialogcontent}>
				<DialogContentText className={classes.dialogsubtitle}>
					Resetar a partida
				</DialogContentText>
				Você têm certeza?
				<Switch
					checked={resetState.checkReset}
					onChange={checkResetSwitch}
					name="checkReset"
					color="primary"
				/>
			</DialogContent>
			<DialogActions>
				<Button
					className={classes.dialogsavebutton}
					size="large"
					onClick={handleReset}
					variant="contained"
					disabled={!saveCheck()}
				>
					SALVAR
				</Button>
			</DialogActions>
		</>
	);
};

export default Reset;
