import React from 'react';
import {
	Typography,
	Grid,
	Card,
	CardContent,
	Button,
	TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Prizes from 'components/Tournament/Prizes';
// import ReportProblemRoundedIcon from '@material-ui/icons/ReportProblemRounded';
import InfoScoreBoard from 'components/Tournament/InfoScoreBoard';
import TournamentSignup from 'components/TournamentSignup';
import InnerSection from 'components/Tab/InnerSection';
import Details from './Summary/Details';
import ChatBox from './Chat';
import SummaryCard from '../SummaryCard';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';

const useStyles = makeStyles(
	(theme) => ({
		root: {
			maxWidth: '100%'
		},
		title: {
			textTransform: 'uppercase',
			margin: [[0, 0, 16, 0]]
		},
		info: {
			padding: 24,
			backgroundColor: theme.palette.gunpowder,
			borderRadius: 8
		},

		about: {
			display: 'flex',
			justifyContent: 'space-between',
			color: theme.palette.smoke
		},

		hiddenTitle: {
			visibility: 'hidden'
		},
		statisticscard: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			width: '100%',
			height: '100%'
		},
		statisticstitle: {
			fontSize: 30,
			fontWeight: 500,
			textAlign: 'center',
			textTransform: 'uppercase',
			display: 'flex'
		},
		statisticscontent: {
			fontSize: 12,
			fontWeight: 500,
			textAlign: 'center',
			textTransform: 'uppercase',
			color: theme.palette.gandalf,
			display: 'flex'
		},

		twitchheader: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center'
		},
		twitchframe: {
			minHeight: '360px',
			minWidth: '630px'
		},
		twitchchannelinput: {
			marginBottom: 16,
			'& .MuiInputBase-root': {
				backgroundColor: theme.palette.lead
			},
			'& .MuiFilledInput-underline:before': {
				borderBottom: '0px solid'
			},
			'& .MuiFilledInput-input': {
				padding: '10px 12px 10px'
			}
		},
		twitchupdatebutton: {
			marginBottom: 16
		}
	}),
	{ name: 'TournamentSummary' }
);

const TournamentContentSummary = ({ tournament, onTournamentSignup }) => {
	const classes = useStyles();

	// xs, sm, md, lg, and xl.

	return (
		<Grid container spacing={4} className={classes.root}>
			<Grid item xs={12} sm={6} md={4}>
				<SummaryCard
					maincontent="343"
					maintitle="equipes participantes"
					icon={
						<PeopleOutlineIcon
							style={{ fontSize: 60 }}
							color="primary"
						/>
					}
				/>
			</Grid>
			<Grid item xs={12} sm={6} md={4}>
				<div className={classes.statisticscard}>
					<Typography className={classes.statisticstitle}>
						estatísticas
					</Typography>
					<Typography className={classes.statisticscontent}>
						{' '}
						Atualizado por último 16/03/2020 (21:30)
					</Typography>
				</div>
			</Grid>
			<Grid item xs={12} sm={6} md={4}>
				<SummaryCard
					maincontent="R$ 1.234.567"
					maintitle="valor total inscrições"
					icon={
						<AttachMoneyIcon
							style={{ fontSize: 60 }}
							color="primary"
						/>
					}
				/>
			</Grid>
			<Grid item xs={12} sm={6} md={4}>
				<SummaryCard
					maincontent="1.234.567"
					maintitle="usuários únicos na transmissão"
					icon={
						<PlayArrowIcon
							style={{ fontSize: 60 }}
							color="primary"
						/>
					}
				/>
			</Grid>
			<Grid item xs={12} sm={6} md={4}>
				<SummaryCard
					maincontent="1.234.567"
					maintitle="visitas a página do campeonato"
					icon={
						<EmojiEventsIcon
							style={{ fontSize: 60 }}
							color="primary"
						/>
					}
				/>
			</Grid>
			<Grid item xs={12} sm={6} md={4}>
				<SummaryCard
					maincontent="999.999"
					maintitle="usuários simultâneos na transmissão"
					icon={
						<PlayArrowIcon
							style={{ fontSize: 60 }}
							color="primary"
						/>
					}
				/>
			</Grid>
			<Grid item xs={12} sm={6} md={4}>
				<Prizes prizes={tournament.prizes} />
			</Grid>

			<Grid item xs={12} sm={6} md={5}>
				<TournamentSignup
					tournament={tournament}
					onTournamentSignup={onTournamentSignup}
				/>
			</Grid>

			<Grid item xs={12} sm={12} md={3}>
				<Typography variant="h4" className={classes.title}>
					UPDATES
				</Typography>
				<Typography color="primary">EM BREVE</Typography>
				{/* <InfoScoreBoard
					results={tournament.tdb_results}
					avatarSize="extraLarge"
				/> */}
			</Grid>

			{/* <InnerSection item xs={12} md={6} sectionTitle="Informações">
				<Typography
					variant="body2"
					dangerouslySetInnerHTML={{
						__html: tournament.information
					}}
				/>
			</InnerSection> */}

			{/* <InnerSection item xs={12} md={6}>
				<Details tournament={tournament} />
			</InnerSection> */}

			<Grid item xs={12} sm={12} md={6}>
				<div className={classes.twitchheader}>
					<Typography variant="h4" className={classes.title}>
						stream
					</Typography>
					<form noValidate autoComplete="off">
						<TextField
							variant="filled"
							className={classes.twitchchannelinput}
						/>
					</form>
					<Button
						className={classes.twitchupdatebutton}
						color="primary"
						variant="outlined"
					>
						Atualizar
					</Button>
				</div>
				<div className={classes.twitchframe}>
					<iframe
						title="Twitch Stream"
						src="https://player.twitch.tv/?channel=redragonbrasil&parent=localhost&parent=coliseu.gg"
						height="360px"
						width="630px"
						allowfullscreen="true"
					></iframe>
				</div>
			</Grid>

			<Grid item xs={12} sm={12} md={6}>
				<Typography variant="h4" className={classes.title}>
					partidas
				</Typography>
			</Grid>

			<Grid item xs={12} sm={12} md={6}>
				<Typography variant="h4" className={classes.title}>
					times inscritos
				</Typography>
			</Grid>

			<Grid item xs={12} sm={12} md={6}>
				<Typography variant="h4" className={classes.title}>
					comentários
				</Typography>
			</Grid>

			{/* <ChatBox
				item
				xs={12}
				md={4}
				title="Comentários"
				tournamentId={tournament.id}
			/>

			<InnerSection item xs={12} md={8}>
				Twitch Stream
			</InnerSection> */}
		</Grid>
	);
};

export default TournamentContentSummary;
