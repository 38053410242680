import React, { useState } from 'react';
import HeaderContainer from '../container/HeaderContainer';

import {
	Typography,
	Grid,
	makeStyles,
	Button,
	TextField
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(
	(theme) => ({
		gridpadding: {
			marginTop: 50,
			marginLeft: '68vh',
			alignItems: 'center'
		},
		title: {
			display: 'flex',
			justifyContent: 'center',
			fontWeight: 500,
			fontSize: 30,
			textTransform: 'uppercase',
			color: 'white',
			marginTop: '5vh'
		},
		button: {
			marginLeft: 10,
			marginRight: 15
		},
		removeButton: {
			marginLeft: 292
		},
		list: {
			marginLeft: '12px',
			listStyleType: 'none',
			color: 'white',
			fontSize: '20px'
		},
		inputkeys: {
			width: '20%',
			textDecoration: 'none',

			paddingRight: 15,
			'& .MuiFilledInput-input': {
				padding: '10px 12px 10px'
			},
			'& .MuiFilledInput-underline:before': {
				borderBottom: '0px solid #000'
			},
			'& .MuiFilledInput-root': {
				backgroundColor: theme.palette.lead,
				borderRadius: 4
			}
		}
	}),
	{ name: 'NewMatch' }
);

const EditMatchTournament = () => {
	const classes = useStyles();
	const history = useHistory();

	const [id, setId] = useState();
	const [match, setMatch] = useState();

	const handleChangeId = (e) => {
		setId(e.target.value);
	};

	const handleChangeMatch = (e) => {
		setMatch(e.target.value);
	};

	const handleSubmit = () => {
		id && match
			? history.push(`/dashboard/tournament/${id}/match/${match}`)
			: console.log('ID or Match not found');
	};
	return (
		<>
			<HeaderContainer />;
			<Typography className={classes.title}>
				Editar Partida de Campeonato
			</Typography>
			<Grid className={classes.gridpadding}>
				<TextField
					className={classes.inputkeys}
					variant="filled"
					type="text"
					value={id}
					onChange={handleChangeId}
					placeholder="TOURNAMENT ID"
				/>
				<TextField
					className={classes.inputkeys}
					variant="filled"
					type="text"
					value={match}
					onChange={handleChangeMatch}
					placeholder="MATCH ID"
				/>
				<Button
					className={classes.button}
					variant="outlined"
					color="primary"
					onClick={handleSubmit}
				>
					Editar Partida
				</Button>
			</Grid>
		</>
	);
};

export default EditMatchTournament;
