import { usePromise } from 'hooks';
import { findUser } from 'pages/players/search';
import React, { useState } from 'react';
import { useCallback } from 'react';
import { API } from 'services/api';
import { headers } from 'services/_helpers';
export const useFindPlayers = (userId) => {
	const [debounced, setDebounced] = useState('');

	const findPlayers = (query) =>
		API.get(
			`/find-player`,
			{
				params: {
					query
				}
			},
			{
				headers: headers()
			}
		);

	const cbFindPlayers = useCallback(
		() => findPlayers(debounced),
		[debounced]
	);

	const { response: players, isLoading: isLoadingPlayers } = usePromise(
		cbFindPlayers,
		!debounced
	);

	const cbFindUser = useCallback(() => findUser(userId), [userId]);

	const { response: player, isLoading: isLoadingPlayer } =
		usePromise(cbFindUser);

	return {
		players,
		player,
		debounced,
		setDebounced,
		isLoadingPlayers,
		isLoadingPlayer
	};
};
