import React, { useState } from 'react';
import {
	DialogContent,
	DialogContentText,
	DialogActions,
	makeStyles,
	Select,
	MenuItem,
	FormControl,
	Button
} from '@material-ui/core';
import { applyWO } from '../../services/applyWO';

const useStyles = makeStyles((theme) => ({
	dialogformcontrol: {
		width: '100%'
	},
	dialogselect: {
		width: '100%',
		marginTop: 8,
		marginBottom: 8,
		'& .MuiFilledInput-input': {
			padding: '10px 12px 10px'
		}
	},
	dialogsavebutton: {
		backgroundColor: '#0092EE',
		color: '#fff',
		width: '100%',
		fontWeight: 700,
		'&:hover': {
			backgroundColor: 'slateblue'
		}
	},
	dialogsubtitle: {
		color: '#fff',
		...theme.typography.text,
		marginTop: 0,
		marginBottom: 0
	},
	dialogcontent: {
		padding: '8px',
		'&:first-child': {
			paddingTop: '0px'
		},
		'& .MuiFilledInput-underline:before': {
			borderBottom: '0px solid #000'
		},
		'& .MuiFilledInput-root': {
			backgroundColor: theme.palette.lead
		}
	}
}));

const Wo = ({ matchesinfo, tournament, match, dialogToggleWO }) => {
	const classes = useStyles();
	const [teamWinner, setTeamWinner] = useState('');

	const { response: matches = [], isLoaded } =
		(`/admin/tournaments/${tournament}/match/${match}/wo`,
		{
			method: 'post',
			payload: {
				winnerId: teamWinner
			}
		});

	const handleSelectWinner = (event) => {
		setTeamWinner(event.target.value);
	};

	function handleWo() {
		applyWO(tournament, match, teamWinner)
			.then(dialogToggleWO())
			.catch((e) => {
				alert(e);
			});
	}

	return (
		<>
			<DialogContent className={classes.dialogcontent}>
				<DialogContentText className={classes.dialogsubtitle}>
					Time Vencedor
				</DialogContentText>
				<FormControl
					variant="filled"
					className={classes.dialogformcontrol}
				>
					<Select
						className={classes.dialogselect}
						value={teamWinner}
						onChange={(e) => handleSelectWinner(e)}
					>
						<MenuItem value="teamA">
							{matchesinfo.teamA?.name}
						</MenuItem>
						<MenuItem value="teamB">
							{matchesinfo.teamB?.name}
						</MenuItem>
					</Select>
				</FormControl>
			</DialogContent>
			<DialogActions>
				<Button
					className={classes.dialogsavebutton}
					size="large"
					onClick={handleWo}
				>
					SALVAR
				</Button>
			</DialogActions>
		</>
	);
};

export default Wo;
