import { Button, CircularProgress, Grid } from '@material-ui/core';
import MenuLateral from 'components/menu-lateral';
import { useAPI } from 'hooks';
import { useState } from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import routes from 'routes';
import { createSwissSeeds } from 'services/tournament';
import SwissTeamItem from '../SwissTeamItem';
import style from './swissTeams.module.scss';

export default function SwissTournamentTeams(props) {
	const tournamentId = props.match.params.idTournament;
	const [isLoading, setIsLoading] = useState(false);
	const [swissTeams, setSwissTeams] = useState([]);
	const [swissSeeds, setSwissSeeds] = useState({
		tournamentId: tournamentId,
		teams: []
	});
	const { search } = useLocation();

	const query = new URLSearchParams(search);
	const tournamentName = query.get('tournamentName');

	const { response: teams = [], isLoaded } = useAPI(
		`/tournaments/${tournamentId}/teams`
	);

	const prepareSwiss = () => {
		setSwissSeeds((oldState) => ({
			...oldState,
			teams: swissTeams
		}));
	};

	const uniqueValues = useMemo(() => {
		return (
			new Set(swissSeeds.teams.map((team) => team.seed)).size ===
			teams.length
		);
	}, [swissSeeds, teams]);

	const sendSwissSeeds = () => {
		if (uniqueValues) {
			setIsLoading(true);
			createSwissSeeds(swissSeeds)
				.then(() => alert('Seeds criados com sucesso!'))
				.catch((error) => alert(error.response.data.message))
				.finally(setIsLoading(false));
		} else {
			alert('Seeds duplicados!');
		}
	};

	useEffect(() => swissTeams && prepareSwiss(), [swissTeams]);

	return (
		<>
			<div className={style.container}>
				<MenuLateral name="campeonatos" routes={routes.tournaments} />
				<div className={style.content}>
					<h1>Selecionar Seeds - {tournamentName} </h1>
					{!isLoaded && (
						<CircularProgress
							color="inherit"
							size="4rem"
							style={{ marginTop: 16 }}
						/>
					)}
					{isLoaded &&
						teams &&
						teams.map((team, index) => {
							const { name, id, avatar } = team;
							return (
								<SwissTeamItem
									name={name}
									avatar={avatar}
									teamId={id}
									tournamentId={tournamentId}
									index={index}
									setSwissTeams={setSwissTeams}
									tournamentSize={teams.length}
								/>
							);
						})}
					<Grid item xs={12}>
						<Button
							color="primary"
							variant="contained"
							style={{ width: '100%', marginTop: '16px' }}
							onClick={sendSwissSeeds}
							disabled={isLoading}
						>
							Criar Seeds
						</Button>
					</Grid>
				</div>
			</div>
		</>
	);
}
