import React from 'react';
import clsx from 'clsx';
import { Typography, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TeamAvatar, { avatarSizes } from '../TeamAvatar';
import { parseDateAsReadable } from './helpers';

const useStyles = makeStyles(
	(theme) => ({
		root: {
			width: 'auto',
			textAlign: 'center',
			margin: [[5, 0, 25]]
		},
		boxStyling: {
			background: '#1B1D20',
			padding: 12,
			borderRadius: 8,
			boxShadow: '0px 24px 24px rgba(0, 0, 0, 0.75)'
		},
		avatarGrid: {
			width: ({ avatarSize }) => avatarSize,
			boxSizing: 'content-box'
		},
		teamNames: {
			fontSize: 10,
			color: '#515357',
			fontWeight: 500,
			paddingTop: 8
		},
		date: {
			fontSize: 10,
			color: '#515357',
			fontWeight: 500,
			paddingBottom: 8
		},
		score: {
			fontWeight: 500,
			color: theme.palette.asphalt,
			fontSize: 12
		},
		scoreWinner: {
			color: theme.palette.primary.main,
			fontSize: 24
		},
		scoreLoser: {
			color: '#515357',
			fontSize: 24
		}
	}),
	{ name: 'InfoScoreBoard' }
);

const InfoScoreBoard = ({
	results = [],
	avatarSize = 'small',
	hideName = false,
	noStyling = false
}) => {
	const classes = useStyles({ avatarSize: avatarSizes[avatarSize] });
	const wrapperClass = noStyling
		? classes.root
		: clsx(classes.root, classes.boxStyling);

	return (
		<Grid
			container
			alignItems="stretch"
			spacing={1}
			className={wrapperClass}
		>
			{results.map((result, idx) => {
				const { teamA, teamB } = result;
				const { scoreWinner, scoreLoser } = classes;
				const classScoreA =
					teamA.score > teamB.score ? scoreWinner : scoreLoser;
				const classScoreB =
					teamB.score > teamA.score ? scoreWinner : scoreLoser;
				const scoreDate = parseDateAsReadable(result.date, false);

				return (
					<React.Fragment key={`tournament-scoreboard-${idx}`}>
						<Grid item xs="auto" className={classes.avatarGrid}>
							<TeamAvatar
								size={avatarSize}
								margin={0}
								src={teamA.avatar}
							/>
							{!hideName && (
								<Typography className={classes.teamNames}>
									{teamA.name}
								</Typography>
							)}
						</Grid>
						<Grid item container alignItems="center" xs>
							<Grid item xs={12}>
								<Typography
									variant="h5"
									className={classes.score}
								>
									<span className={classScoreA}>
										{teamA.score}
									</span>{' '}
									VS{' '}
									<span className={classScoreB}>
										{teamB.score}
									</span>
								</Typography>
								<div className={classes.date}>{scoreDate}</div>
							</Grid>
						</Grid>
						<Grid item xs="auto" className={classes.avatarGrid}>
							<TeamAvatar
								size={avatarSize}
								margin={0}
								src={teamB.avatar}
							/>
							{!hideName && (
								<Typography className={classes.teamNames}>
									{teamB.name}
								</Typography>
							)}
						</Grid>
						<Grid
							item
							component={Typography}
							xs={12}
							align="center"
						>
							<Button color="primary">VER ESTATISTICAS</Button>
						</Grid>
					</React.Fragment>
				);
			})}
		</Grid>
	);
};

export default InfoScoreBoard;
