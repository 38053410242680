import React from 'react';
import clsx from 'clsx';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TeamAvatar from 'components/TeamAvatar';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: '#1B1D20',
		padding: [[0, 2]],
		width: 220,
		height: 50,
		borderRadius: 4,
		boxShadow: '0px 24px 24px rgba(0, 0, 0, 0.75)',
		textAlign: 'center',
		margin: [['0.25rem', 0]]
	},
	teamNames: {
		fontSize: 14,
		lineHeight: '19px',
		textAlign: 'left',
		color: 'white',
		fontWeight: 500,
		paddingLeft: 2
	},
	media: {
		height: 30,
		width: 30
	},

	score: {
		fontWeight: 500,
		color: theme.palette.gandalf
	},
	scoreWinner: {
		color: theme.palette.primary.main
	}
}));

const TeamCard = ({ team }) => {
	const classes = useStyles();
	const { avatar, name, score, hasWon } = team || {};
	const scoreClass = hasWon
		? clsx(classes.score, classes.scoreWinner)
		: classes.score;

	return (
		<Grid
			container
			alignItems="center"
			justify="space-between"
			spacing={1}
			className={classes.root}
		>
			<Grid item xs={2}>
				<TeamAvatar src={avatar} size="small" margin={0} />
			</Grid>
			<Grid item xs={7}>
				<Typography variant="body2" className={classes.teamNames}>
					{name}
				</Typography>
			</Grid>
			<Grid item xs={3}>
				<Typography variant="h5" className={scoreClass}>
					{score}
				</Typography>
			</Grid>
		</Grid>
	);
};

export default TeamCard;
