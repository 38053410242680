import { useState } from 'react';
import { approveReport } from 'services/tournament';

const useApproveReport = ({ matchId, tournamentId, reportId }) => {
	const [isLoading, setIsLoading] = useState(false);

	const handleApproveReport = () => {
		setIsLoading(true);
		approveReport({
			matchId,
			tournamentId,
			reportId
		})
			.then((response) =>
				alert(
					response?.response?.data?.message ||
						'O reporte foi aprovado com sucesso!'
				)
			)
			.catch((error) =>
				alert(
					error?.response?.data?.message ||
						'Ocorreu um erro ao aprovar o report.'
				)
			)
			.finally(() => setIsLoading(false));
	};

	return {
		isLoading,
		approveReport: handleApproveReport
	};
};

export default useApproveReport;
