import React from 'react'
import style from './button.module.scss'

export default function Button({ label, handlerButton }) {
    return(
        <button 
            onClick={(event) => handlerButton(event)}
            className={style.button}
            type="button">
            {label}
        </button>
    )
}