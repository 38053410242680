import React, { useState } from 'react';
import { Grid, CardMedia, Typography, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';
const useStyles = makeStyles(
	(theme) => ({
		root: {
			padding: 8,
			maxWidth: '100%',
			marginTop: 16,
			color: theme.palette.gandalf,
			'&:hover': {
				color: '#FFF',
				background: theme.palette.lead
			}
		},
		media: {
			width: 40,
			height: 40
		}
	}),
	{ name: 'TournamentTeamItem' }
);

const SwissTeamItem = ({
	teamId,
	name,
	avatar,
	index,
	setSwissTeams,
	tournamentSize
}) => {
	const classes = useStyles();
	const [seed, setSeed] = useState(index + 1);

	const [seedError, setSeedError] = useState(false);

	useEffect(() => {
		!seed || seed < 1 || seed > tournamentSize
			? setSeedError(true)
			: setSeedError(false);
	}, [seed]);

	useEffect(() => {
		if (!seedError && seed)
			setSwissTeams((oldState) => [
				...oldState.slice(0, index),
				{ id: teamId, seed: seed },
				...oldState.slice(index + 1)
			]);
	}, [seed, seedError]);

	return (
		<Grid
			container
			alignItems="center"
			spacing={1}
			className={classes.root}
		>
			<Grid item xs={1}>
				<CardMedia
					className={classes.media}
					component="img"
					image={avatar}
				/>
			</Grid>
			<Grid item xs={7} style={{ zIndex: 99 }}>
				<a
					href={`https://coliseum.gg/team/${teamId}`}
					target="_blank"
					rel="noopener noreferrer"
					style={{ textDecoration: 'none', cursor: 'pointer' }}
				>
					<Typography color="textPrimary" variant="h6">
						{name}
					</Typography>
				</a>
			</Grid>
			<Grid item xs={1}>
				<Typography color="textPrimary">Seed</Typography>
			</Grid>
			<Grid item xs={1}>
				<TextField
					type="number"
					required
					error={seedError}
					onChange={(e) => setSeed(Number(e.target.value))}
					value={seed}
				></TextField>
			</Grid>
		</Grid>
	);
};

export default SwissTeamItem;
