// import React from 'react';
// import { useAPI } from 'hooks';
// import HeaderContainer from '../container/HeaderContainer';
// import { cancelMatch } from 'services/cancelMatch';

// const CancelMatch = (props) => {
// 	const matchId = props.match.params;

// 	const { response: match = [] } = cancelMatch(matchId.id);

// 	return (
// 		<div className="cancelMatch">
// 			<HeaderContainer />

// 			<h1 style={{ backgroundColor: 'white' }}>
// 				Partida cancelada com sucesso
// 			</h1>
// 		</div>
// 	);
// };

// export default CancelMatch;
import React, { useState } from 'react';
import HeaderContainer from '../container/HeaderContainer';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PersonIcon from '@material-ui/icons/Person';
import Typography from '@material-ui/core/Typography';
import { cancelMatch } from 'services/cancelMatch';
import { Grid, makeStyles, TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useAPI } from 'hooks';
import { getLiveMatches } from 'services/liveMatches';

const useStyles = makeStyles(
	(theme) => ({
		root: {
			margin: 0,
			padding: theme.spacing(2)
		},
		closeButton: {
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(1),
			color: theme.palette.grey[500]
		},
		gridpadding: {
			padding: '30px',
			marginTop: 50
		},
		list: {
			alignItems: 'center',
			marginLeft: '12px',
			listStyleType: 'none',
			color: 'white',
			fontSize: '20px'
		},
		infoButton: {
			fontWeight: 'bold',
			fontSize: '16px'
		},
		title2: {
			display: 'flex',
			justifyContent: 'center',
			fontWeight: 500,
			fontSize: 30,
			textTransform: 'uppercase',
			color: 'white',
			padding: '50px'
		},
		title: {
			display: 'flex',
			justifyContent: 'center',
			fontWeight: 500,
			fontSize: 30,
			textTransform: 'uppercase',
			color: 'white',
			padding: '30px'
		},
		titleDialog: {
			display: 'flex',
			justifyContent: 'center'
		},
		button: {
			marginLeft: 120,
			marginRight: 10
		},
		removeButton: {
			marginLeft: '20px',
			marginTop: '22px'
		},
		list: {
			display: 'flex',
			justifyContent: 'center',
			marginLeft: '12px',
			listStyleType: 'none',
			color: 'white',
			fontSize: '20px'
		},
		li: {
			display: 'column',
			justifyContent: 'center'
		},
		inputkeys: {
			marginLeft: '82vh',
			marginTop: '20px',
			width: '10%',
			textDecoration: 'none',

			paddingRight: 15,
			'& .MuiFilledInput-input': {
				padding: '10px 12px 10px'
			},
			'& .MuiFilledInput-underline:before': {
				borderBottom: '0px solid #000'
			},
			'& .MuiFilledInput-root': {
				backgroundColor: theme.palette.lead,
				borderRadius: 4
			}
		}
	}),
	{ name: 'NewMatch' }
);

const DialogTitle = withStyles(useStyles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton
					aria-label="close"
					className={classes.closeButton}
					onClick={onClose}
				></IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2)
	}
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1)
	}
}))(MuiDialogActions);

const CancelMatch = (props) => {
	const { response: liveMatches = [] } = useAPI('/admin/matches/live');
	const classes = useStyles();
	const history = useHistory();

	const [open, setOpen] = React.useState(false);
	const [open2, setOpen2] = React.useState(false);
	const [value, setValue] = useState('');

	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClickOpen2 = () => {
		setOpen2(true);
	};
	const handleClose = () => {
		setOpen(false);
		history.push('/dashboard/match/cancelMatch/');
	};

	const handleSubmit = () => {
		cancelMatch(value);
		handleClose();
	};

	console.log(liveMatches);

	return (
		<>
			<HeaderContainer />
			<Typography className={classes.title}>Cancelar Partida</Typography>
			<form>
				<TextField
					className={classes.inputkeys}
					variant="filled"
					type="text"
					value={value}
					onChange={(e) => setValue(e.target.value)}
					placeholder="MATCH ID"
				/>
				<Button
					className={classes.removeButton}
					variant="outlined"
					color="primary"
					onClick={handleClickOpen}
				>
					Cancelar Partida
				</Button>
			</form>
			<Typography className={classes.title2}>
				Partidas em andamento
			</Typography>
			<Grid></Grid>
			<ul className={classes.list}>
				{liveMatches.map((match) => (
					<li className={classes.li} key={match.id}>
						<br />
						{/* <h2
							style={{
								color: '#0092EE',
								border: '1px solid rgba(0, 146, 238, 0.5)',
								padding: '6px 16px',
								boxSizing: 'border-box',
								fontWeight: '1.75',
								borderRadius: '4px',
								textTransform: 'uppercase',
								fontSize: '1.5rem'
							}}
						> */}
						{/* time {match.teamA[0].name} X time{'    '}
						{match.teamB[0].name} */}
						<br />
						<Button
							className={classes.infoButton}
							variant="outlined"
							color="primary"
							onClick={handleClickOpen2}
						>
							time {match.teamA[0].name} X time{'    '}
							{match.teamB[0].name}
						</Button>
						{/* </h2> */}
						{/* <h4>
							<br />
							teamA: <br />
							<br /> {match.teamA[0].name} - {match.teamA[0].id}{' '}
							<br /> {match.teamA[1].name} - {match.teamA[1].id}{' '}
							<br /> {match.teamA[2].name} - {match.teamA[2].id}{' '}
							<br />
							{match.teamA[3].name} - {match.teamA[3].id} <br />{' '}
							{match.teamA[4].name} - {match.teamA[4].id}
						</h4>
						<h4>
							<br />
							teamB: <br /> <br /> {match.teamB[0].name} -{' '}
							{match.teamB[0].id} <br /> {match.teamB[1].name} -{' '}
							{match.teamB[1].id} <br />
							{match.teamB[2].name} - {match.teamB[2].id}
							<br /> {match.teamB[3].name} - {match.teamB[3].id}{' '}
							<br />
							{match.teamB[4].name} - {match.teamB[4].id}
						</h4> */}
					</li>
				))}
			</ul>
			{console.log(liveMatches)}

			<Dialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={open}
			>
				<DialogTitle
					className={classes.titleDialog}
					onClose={handleClose}
				>
					CONFIRMAÇÃO
				</DialogTitle>
				<DialogContent dividers>
					<Typography gutterBottom>
						Tem certeza que deseja cancelar a partida?
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={handleSubmit} color="primary">
						Cancelar partida
					</Button>
					<Button autoFocus onClick={handleClose} color="secundary">
						Voltar
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={open2}
			>
				<DialogTitle
					className={classes.titleDialog}
					onClose={handleClose}
				>
					Informações da partida
				</DialogTitle>
				<DialogContent dividers>
					<Typography gutterBottom>
						<ul className={classes.list}>
							{liveMatches.map((match) => (
								<li key={match.itemId}>
									<h4>
										id da partida: {match.id}
										<br />
										<br />
										TEAM A: <br />
										<br />
										<PersonIcon
											style={{
												fontSize: 25,
												marginRight: '10px',
												color: '#0092EE'
											}}
										/>
										{match.teamA[0].name} -{' '}
										{match.teamA[0].id} <br />{' '}
										<PersonIcon
											style={{
												fontSize: 25,
												marginRight: '10px',
												color: '#0092EE'
											}}
										/>
										{match.teamA[1].name} -{' '}
										{match.teamA[1].id} <br />{' '}
										<PersonIcon
											style={{
												fontSize: 25,
												marginRight: '10px',
												color: '#0092EE'
											}}
										/>
										{match.teamA[2].name} -{' '}
										{match.teamA[2].id} <br />
										<PersonIcon
											style={{
												fontSize: 25,
												marginRight: '10px',
												color: '#0092EE'
											}}
										/>
										{match.teamA[3].name} -{' '}
										{match.teamA[3].id} <br />{' '}
										<PersonIcon
											style={{
												fontSize: 25,
												marginRight: '10px',
												color: '#0092EE'
											}}
										/>
										{match.teamA[4].name} -{' '}
										{match.teamA[4].id}
									</h4>
									<h4>
										<br />
										TEAM B: <br /> <br />{' '}
										<PersonIcon
											style={{
												fontSize: 25,
												marginRight: '10px',
												color: '#0092EE'
											}}
										/>
										{match.teamB[0].name} -{' '}
										{match.teamB[0].id} <br />{' '}
										<PersonIcon
											style={{
												fontSize: 25,
												marginRight: '10px',
												color: '#0092EE'
											}}
										/>
										{match.teamB[1].name} -{' '}
										{match.teamB[1].id} <br />
										<PersonIcon
											style={{
												fontSize: 25,
												marginRight: '10px',
												color: '#0092EE'
											}}
										/>
										{match.teamB[2].name} -{' '}
										{match.teamB[2].id} <br />
										<PersonIcon
											style={{
												fontSize: 25,
												marginRight: '10px',
												color: '#0092EE'
											}}
										/>
										{match.teamB[3].name} -{' '}
										{match.teamB[3].id} <br />
										<PersonIcon
											style={{
												fontSize: 25,
												marginRight: '10px',
												color: '#0092EE'
											}}
										/>
										{match.teamB[4].name} -{' '}
										{match.teamB[4].id}
									</h4>
								</li>
							))}
						</ul>
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={handleClose} color="secundary">
						Voltar
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default CancelMatch;
