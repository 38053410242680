export const getTodayISOString = () => new Date().toISOString();

export const parseDateAsReadable = (
	dateString,
	withTime = true,
	withParentheses = true,
	withSeconds = false,
	todayISOString = getTodayISOString()
) => {
	if (!dateString) return '';
	const parsedDate = new Date(dateString);

	let options = {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric'
	};

	if (withTime) {
		options = {
			...options,
			hour: 'numeric',
			minute: 'numeric',
			hour12: false
		};

		if (withSeconds) {
			options = { ...options, second: 'numeric' };
		}
	}

	const [date, time] = new Intl.DateTimeFormat('pt-PR', options)
		.format(parsedDate)
		.split(' ');

	const isToday = todayISOString.slice(0, 10) === dateString.slice(0, 10);

	const displayTime = withParentheses ? `(${time})` : time;
	const displayDate = isToday ? 'Hoje' : date;

	if (time) {
		if (isToday) {
			return [displayDate, displayTime].join(' - ');
		}
		return [displayDate, displayTime].join(' ');
	}

	return displayDate;
};
