import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useAPI } from 'hooks';
import MatchInfo from './Matches/MatchInfo';

const TournamentContentMatches = ({ tournament }) => {
	const { id } = tournament;
	const {
		response: matches = [],
		isLoaded
		// setResponseWithData
	} = useAPI(`/tournaments/${id}/matches`);

	return (
		<Box style={{ minHeight: '64vh' }}>
			<Grid container spacing={0}>
				{isLoaded && !!matches.length ? (
					matches.map((match, idx) => (
						<MatchInfo
							container
							item
							alignItems="center"
							justify="center"
							spacing={1}
							xs={12}
							md={10}
							xl={8}
							key={`match-result-${idx}`}
							match={match}
							tournamentId={id}
						/>
					))
				) : (
					<Grid item xs={12}>
						<Typography>
							{isLoaded
								? 'Partidas em breve...'
								: 'Carregando...'}
						</Typography>
					</Grid>
				)}
			</Grid>
		</Box>
	);
};

export default TournamentContentMatches;
