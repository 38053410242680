import React from 'react';
import { withRouter } from 'react-router-dom';
import TournamentContainer from 'container/Tournament';
// import Layout from '../layouts';

const Tournament = ({ match }) => {
	// const {
	// 	params: { tournamentId }
	// } = match;
	const tournamentId = match.params.id

	return (
		// <Layout match={match} maxWidth={false}>
			<TournamentContainer id={tournamentId} />
		// </Layout>
	);
};

export default withRouter(Tournament);
