import { CircularProgress } from '@material-ui/core';
import MenuLateral from 'components/menu-lateral';
import { useAPI } from 'hooks';
import { useLocation } from 'react-router-dom';
import routes from 'routes';
import TournamentTeamItem from '../TournamentTeamItem';
import style from './teamsTournament.module.scss';

export default function TournamentTeams(props) {
	const tournamentId = props.match.params.idTournament;

	const { search } = useLocation();

	const query = new URLSearchParams(search);
	const tournamentName = query.get('tournamentName');

	const {
		response: teams = [],
		isLoaded
		// setResponseWithData
	} = useAPI(`/tournaments/${tournamentId}/teams`);

	return (
		<>
			<div className={style.container}>
				<MenuLateral name="campeonatos" routes={routes.tournaments} />
				<div className={style.content}>
					<h1>Equipes - {tournamentName} </h1>
					{!isLoaded && (
						<CircularProgress
							color="inherit"
							size="4rem"
							style={{ marginTop: 16 }}
						/>
					)}
					{isLoaded &&
						teams.map((team) => {
							const { name, id, avatar, hasConfirmed } = team;

							return (
								<TournamentTeamItem
									name={name}
									avatar={avatar}
									teamId={id}
									tournamentId={tournamentId}
									hasConfirmed={hasConfirmed}
								/>
							);
						})}
				</div>
			</div>
		</>
	);
}
