import React, { useState } from 'react';
import style from './playersBan.module.scss';
import MenuLateral from 'components/menu-lateral';
import { Button, InputText } from 'components/form';
import { banPlayer } from 'services/players';
import routes from 'routes';

export default function PlayerBan() {
	const [playerId, setPlayerId] = useState();
	const [daysBan, setDaysBan] = useState();

	const getFormatedTime = (date) => {
		return new Date(date).toLocaleTimeString('pt', {
			hour: 'numeric',
			minute: 'numeric'
		});
	};

	const formatDateToService = (date) => {
		const dateObj = new Date(date);
		const year = dateObj.getFullYear();
		let month = String(dateObj.getMonth() + 1);
		if (month.length === 1) {
			month = `0${month}`;
		}
		const day = dateObj.getDate();
		return `${year}-${month}-${day} ${getFormatedTime(date)}:00`;
	};

	function calculateDateBan(days) {
		var banexpires = new Date();
		var date = new Date();
		banexpires.setDate(date.getDate() + parseInt(days));
		return formatDateToService(banexpires);
	}

	function goBan(id, days) {
		const date = calculateDateBan(days);
		const reason = 'Banido pelo painel adm';
		banPlayer(id, reason, date);
	}

	return (
		<>
			<div className={style.container}>
				<MenuLateral name="jogadores" routes={routes.players} />
				<div className={style.content}>
					<h1>Banir jogador</h1>
					<InputText
						label="ID do jogador"
						handleChange={(event) =>
							setPlayerId(event.target.value)
						}
					/>
					<InputText
						label="Dias de banimento"
						handleChange={(event) => setDaysBan(event.target.value)}
					/>
					<Button
						label="banir"
						handlerButton={() => goBan(playerId, daysBan)}
					/>
				</div>
			</div>
		</>
	);
}
