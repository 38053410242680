import { useState } from 'react';

const useTab = (defaultTab = 0) => {
	const [activeTab, setActiveTab] = useState(defaultTab);

	const handleChange = (event, newValue) => {
		setActiveTab(newValue);
	};

	return [activeTab, handleChange];
};

export default useTab;
