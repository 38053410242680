import { API } from 'services/api';
import { headers } from 'services/_helpers';

export const getLiveMatches = () => {
	return API.get('/admin/matches/live')
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.error(error);
		});
};

export const createNewMatch = (payload, teamA, teamB) => {
	const data = {
		queueId: payload,
		teamA: teamA,
		teamB: teamB
	};
	return API.post(`admin/match/new`, data)
		.then((response) => {
			alert('Partida criada com sucesso');
			return response;
		})
		.catch((error) => {
			alert('Jogadores não encontrado');
		});
};

export const cancelMatch = (id) => {
	return API.post(`admin/match/${id}/cancel`, { headers: headers() });
};

export const changeMatchBestOf = (id, bestOf) => {
	return API.put(`admin/tournaments/match/${id}/bestof/`, { bestOf });
};
