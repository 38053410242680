import React, { useState } from 'react';
import HeaderContainer from '../container/HeaderContainer';

import {
	Typography,
	Grid,
	makeStyles,
	Button,
	TextField
} from '@material-ui/core';
import { banPlayer } from 'services/banPlayer';

const useStyles = makeStyles(
	(theme) => ({
		gridpadding: {
			marginTop: 50,
			marginLeft: '85vh',
			alignItems: 'center'
		},
		title: {
			display: 'flex',
			justifyContent: 'center',
			fontWeight: 500,
			fontSize: 30,
			textTransform: 'uppercase',
			color: 'white',
			marginTop: '5vh'
		},
		button: {
			marginLeft: 10,
			marginRight: 15
		},
		removeButton: {
			marginLeft: 292
		},
		list: {
			marginLeft: '12px',
			listStyleType: 'none',
			color: 'white',
			fontSize: '20px'
		},
		inputkeys: {
			width: '20%',
			textDecoration: 'none',

			paddingRight: 15,
			'& .MuiFilledInput-input': {
				padding: '10px 12px 10px'
			},
			'& .MuiFilledInput-underline:before': {
				borderBottom: '0px solid #000'
			},
			'& .MuiFilledInput-root': {
				backgroundColor: theme.palette.lead,
				borderRadius: 4
			}
		}
	}),
	{ name: 'NewMatch' }
);

const BanPlayer = () => {
	const classes = useStyles();
	const [value, setValue] = useState('');
	const [qtddias, setqtddias] = useState(0);

    const getFormatedTime = (date) =>
	new Date(date).toLocaleTimeString('pt', {
		hour: 'numeric',
		minute: 'numeric'
	});
	
	const formatDateToService = (date) => {
		const dateObj = new Date(date);
		const year = dateObj.getFullYear();
		let month = String(dateObj.getMonth() + 1);
		if (month.length === 1) {
			month = `0${month}`;
		}
		const day = dateObj.getDate();
		return `${year}-${month}-${day} ${getFormatedTime(date)}:00`;
	};

	const handleSubmit = async () => {
		var banexpires = new Date();
		var date = new Date();
		banexpires.setDate(date.getDate()+parseInt(qtddias));
		const banexpiresFormated = (formatDateToService(banexpires));

		try {
			const { data } = await banPlayer(value,'Banido pelo painel adm',banexpiresFormated);
			alert(data.message);
		  } catch (err) {
			alert(err);
		  }
	};
	return (
		<>
			<HeaderContainer />;
			<Typography className={classes.title}>Banir Jogador</Typography>
			<Grid className={classes.gridpadding}>
				<TextField
					className={classes.inputkeys}
					variant="filled"
					type="text"
					value={value}
					onChange={(e) => setValue(e.target.value)}
					placeholder="PLAYER ID"
				/>
				<TextField
					className={classes.inputkeys}
					variant="filled"
					type="text"
					value={qtddias}
					onChange={(e) => setqtddias(e.target.value)}
					placeholder="DIAS DE BAN"
				/>
				<Button
					className={classes.button}
					variant="outlined"
					color="primary"
					onClick={handleSubmit}
				>
					Banir Jogador
				</Button>
				
			</Grid>
		</>
	);
};

export default BanPlayer;
