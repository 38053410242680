import { fetchCurrentUserInfo } from 'services/user';

// types
export const GET_CURRENT_USER_INFO = 'GET_CURRENT_USER_INFO';
export const CLEAR_CURRENT_USER_INFO = 'CLEAR_CURRENT_USER_INFO';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';

// actions
export const getCurrentUserInfo = (payload) => ({
	type: GET_CURRENT_USER_INFO,
	payload
});

export const clearCurrentUserInfo = () => ({
	type: CLEAR_CURRENT_USER_INFO
});

export const updateUserInfo = (payload) => ({
	type: UPDATE_USER_INFO,
	payload
});

// state
const initialState = {
	userInfo: {}
};

export function fetchMe(authToken) {
	return async (dispatch) => {
		try {
			const userInfo = await fetchCurrentUserInfo(authToken);
			dispatch(getCurrentUserInfo(userInfo.data));
		} catch (e) {
			console.log(e);
		}
	};
}

export function updateUserInfoAction(userInfo) {
	return async (dispatch) => {
		try {
			dispatch(updateUserInfo(userInfo));
		} catch (e) {
			console.log(e);
		}
	};
}

// reducer
export function userInfoReducer(state = initialState, action) {
	switch (action.type) {
		case GET_CURRENT_USER_INFO:
			return { ...state, userInfo: action.payload };
		case UPDATE_USER_INFO:
			return { userInfo: { ...state.userInfo, ...action.payload } };
		case CLEAR_CURRENT_USER_INFO:
			return initialState;
		default:
			return state;
	}
}
