// types
export const Types = {
	LOGIN: 'LOGIN',
	LOGOUT: 'LOGOUT'
};

// Actions
export function getTokenAction(token) {
	return {
		token,
		type: Types.LOGIN
	};
}

export const logoutUser = () => ({
	type: Types.LOGOUT
});

// Initial State
const initialState = {
	isLogged: false,
	token: null
};

// reducer
export function loginReducer(state = initialState, action) {
	switch (action.type) {
		case Types.LOGIN:
			return {
				...state,
				token: action.token,
				isLogged: true
			};
		case Types.LOGOUT:
			return { ...state, isLogged: false, token: null };
		default:
			return state;
	}
}
