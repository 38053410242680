import { API } from './api';
import { headers } from './_helpers';

export const tournamentSignup = ({ teamId, tournamentId }) =>
	API.post(`/tournaments/${tournamentId}/join`, { teamId });

export const approveReport = ({ matchId, reportId, tournamentId }) =>
	API.post(`admin/tournaments/${tournamentId}/match/${matchId}/reports/${reportId}/approve`, 		{},
	{
		headers: headers()
	});

export const createNewTournament = (tournamentPayload) =>
	API.post('admin/tournaments', tournamentPayload, {
		headers: headers()
	});

export const editTournament = (tournamentId, tournamentPayload) =>
	API.put(`admin/tournaments/${tournamentId}`, tournamentPayload, {
		headers: headers()
	});

export const approveTeam = ({ teamId, tournamentId }) =>
	API.put(`/admin/tournaments/${tournamentId}/teams/${teamId}/approve`, {
		headers: headers()
	});

export const clearCache = (tournamentId) =>
	API.post(
		`/admin/tournaments/cache/${tournamentId}`,
		{},
		{
			headers: headers()
		}
	);

export const createSwissSeeds = (payload) =>
	API.post(`admin/tournaments/create-seed-group`, payload, {
		headers: headers()
	});
