/* eslint-disable camelcase */
// remove this after update tournament props
import React, { useState } from 'react';

import { Typography, Box, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';

import InformationItem from 'components/Tournament/Summary/InformationItem';
import TournamentSignupButton from 'components/Tournament/SignupButton';

import { useAPI } from 'hooks';
import { parseDateAsReadable } from './Tournament/helpers';

const useStyles = makeStyles(
	(theme) => ({
		title: {
			textTransform: 'uppercase',
			margin: [[0, 0, 16]]
		},
		container: {
			display: 'flex',
			flexDirection: 'column',
			height: 260,
			justifyContent: 'space-between'
		},

		wrapper: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			backgroundColor: theme.palette.gunpowder,
			padding: 14,
			height: 116,
			marginBottom: 16,
			borderRadius: 4,
			boxShadow: '0px 24px 24px rgba(0, 0, 0, 0.75)'
		},

		signupTitle: {
			fontSize: 18,
			fontWeight: 500
		},

		count: {
			color: theme.palette.smoke
		},

		advice: {
			color: theme.palette.gandalf,
			paddingTop: 2
		},

		alert: {
			paddingTop: 5,
			paddingBottom: 0
		},

		buttonSpaceBetween: {
			display: 'flex',
			justifyContent: 'center',
			marginBottom: 2
		},
		toggleinscriptions: {
			color: theme.palette.smoke,
			fontWeight: 300,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		inscriptionsettingsitem: {
			backgroundColor: theme.palette.lead,
			borderRadius: '4px',
			display: 'flex',
			flexDirection: 'row',
			margin: '8px 0',
		},
		inscriptionsettingsitemtitle: {
			color: theme.palette.smoke,
			padding: '8px',
		}
	}),
	{ name: 'TournamentSignup' }
);

const TournamentSignup = ({ tournament, onTournamentSignup }) => {
	const classes = useStyles();
	const {
		id,
		isRegistrationOpen,
		registrationEndDate,
		tbd_currentEntries,
		maxEntries
	} = tournament;
	const [state, setState] = useState({
		checkedA: true,
	  });

	const handleChange = (event) => {
		setState({ ...state, [event.target.name]: event.target.checked });
	  };

	const [selectedDate, handleDateChange] = useState(new Date());

	const { response: enrolledTeams = [] } = useAPI(`/tournaments/${id}/teams`);

	return (
		<>
			<Typography variant="h4" className={classes.title}>
				<br />
			</Typography>
			<Box className={classes.container}>
				<Box className={classes.wrapper}>
					<Typography className={classes.signupTitle}>
						INSCRIÇÕES
					</Typography>
					<Typography
						className={classes.count}
						align="center"
						variant="body2"
					>
						{enrolledTeams.length} / {maxEntries} Equipes Inscritas
					</Typography>

					<Typography
						align="center"
						variant="caption"
						className={classes.advice}
					>
						Apenas membros Premium podem participar de campeonatos
					</Typography>

					<Typography className={classes.toggleinscriptions}>Aceitar Inscrições <Switch
        checked={state.checkedA}
        onChange={handleChange}
		name="checkedA"
		color="primary"
      /></Typography>

					{/* <TournamentSignupButton
						tournament={tournament}
						onClick={onTournamentSignup}
					/> */}
					{/* <Typography
						color="secondary"
						variant="caption"
						className={classes.alert}
					>
						{isRegistrationOpen
							? `Inscrições abertas até ${parseDateAsReadable(
									registrationEndDate
							  )}`
							: 'Inscrições Encerradas'}
					</Typography> */}
				</Box>

				{/* <InformationItem title="Data" smallerSpacing>
					{parseDateAsReadable(tournament.startDate, false)} -
					{parseDateAsReadable(tournament.endDate, false)}
				</InformationItem> */}
				{/* <InformationItem
					title="Inscrição"
					smallerSpacing
					color={isRegistrationOpen ? 'primary' : 'error'}
				>
					{isRegistrationOpen ? 'ABERTAS' : 'ENCERRADAS'}
				</InformationItem> */}
				{/* <InformationItem title="Tipo" smallerSpacing>
					{tournament.teamSize}v{tournament.teamSize}
				</InformationItem> */}
				<div className={classes.inscriptionsettingsitem}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<Typography className={classes.inscriptionsettingsitemtitle}>Data de início</Typography>
						{/* <KeyboardDatePicker
							value={selectedDate}
							onChange={date => handleDateChange(date)}
							format="dd/MM/yyyy"
						/> */}
					</MuiPickersUtilsProvider>
				</div>
				<div className={classes.inscriptionsettingsitem}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<Typography className={classes.inscriptionsettingsitemtitle}>Data de Fim</Typography>
						{/* <KeyboardDatePicker
							value={selectedDate}
							onChange={date => handleDateChange(date)}
							format="dd/MM/yyyy"
						/> */}
					</MuiPickersUtilsProvider>
				</div>
				<div className={classes.inscriptionsettingsitem}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<Typography className={classes.inscriptionsettingsitemtitle}>5v5</Typography>
						{/* <KeyboardDatePicker
							value={selectedDate}
							onChange={date => handleDateChange(date)}
							format="dd/MM/yyyy"
						/> */}
					</MuiPickersUtilsProvider>
				</div>
			</Box>
		</>
	);
};

export default TournamentSignup;
