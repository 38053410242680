import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	CardMedia,
	Grid,
	TextField,
	Button,
	Typography
} from '@material-ui/core';
import { login } from '../utils';
import './SignIn.css';
import { publicAPI, API } from 'services/api';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(
	(theme) => ({
		root: {
			height: '100vh',
			width: '100vw',
			backgroundColor: '#0B0B0A',
			backgroundPosition: 'center',
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat',
			justifyItems: 'center',
			alignItems: 'center',
			flexWrap: 'nowrap',
			flexFlow: 'row'
		},
		signInContainerWrapper: {
			flexFlow: 'row',
			justifyContent: 'center',
			[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
				flexFlow: 'column'
			}
		},
		signInContainer: {
			alignSelf: 'center',
			textAlign: 'center',
			flexFlow: 'column',
			alignItems: 'center',
			maxWidth: 320
		},
		media: {
			maxWidth: 400,
			margin: 'auto'
		},
		textField: {
			marginBottom: 20,
			backgroundColor: '#1B1D20'
		},
		text: {
			...theme.typography.text
		},
		loginButton: {
			width: 229
		}
	}),
	{ name: 'SignIn' }
);

export default function Login({ children }) {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const history = useHistory();

	const styleProps = { backgroundImage: null };
	const classes = useStyles(styleProps);

	const logo = require('../assets/images/coliseumLogo.svg').default;

	function validateForm() {
		return username.length > 0 && password.length > 0;
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		publicAPI
			.post('/login', { username, password })
			.then(({ data }) => {
				const token = data.message;
				login(token);
				API.defaults.headers.common.Authorization = `Bearer ${token}`;
				history.push('/home');
			})
			.catch((e) => {
				console.log('error', e);
			});
	};

	return (
		<Grid container className={classes.root} spacing={0}>
			<Grid container className={classes.signInContainerWrapper}>
				<Grid container item className={classes.signInContainer}>
					<CardMedia
						className={classes.media}
						component="img"
						image={logo}
					/>
					<Typography variant="body2" className={classes.text}>
						PAINEL ADMINISTRATIVO
					</Typography>
				</Grid>
				<Grid container item className={classes.signInContainer}>
					<form onSubmit={handleSubmit}>
						<Typography variant="body2" className={classes.text}>
							FAÇA LOGIN
						</Typography>
						<TextField
							value={username}
							onChange={(e) => setUsername(e.target.value)}
							autoFocus
							type="text"
							className={classes.textField}
							label="Usuário"
							variant="outlined"
						/>
						<TextField
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							type="password"
							className={classes.textField}
							label="Senha"
							variant="outlined"
						/>
						<Button
							variant="contained"
							color="primary"
							size="large"
							type="submit"
							disabled={!validateForm()}
							className={classes.loginButton}
						>
							LOGIN
						</Button>
					</form>
				</Grid>
				{children}
			</Grid>
		</Grid>
	);
}
