import {
	Dialog,
	DialogTitle,
	Typography,
	IconButton,
	Box,
	makeStyles,
	Grid,
	Avatar,
	TextField,
	Button
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import useApproveReport from 'utils/hooks/useApproveReport';
const useStyles = makeStyles(
	(theme) => ({
		header: {
			margin: 0,
			padding: theme.spacing(4),
			backgroundColor: theme.palette.asphalt
		},
		title: {
			textTransform: 'uppercase',
			fontWeight: 700,
			marginRight: 8
		},
		closeButton: {
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(1),
			color: theme.palette.grey[500]
		},
		modal: {
			borderRadius: 8
		},
		paper: {
			alignSelf: 'center',
			margin: 'auto',
			backgroundColor: theme.palette.asphalt,
			width: '600px',
			padding: theme.spacing(4),
			outline: 'none'
		}
	}),
	{ name: 'Modal' }
);

const TournamentReportModal = ({ report, tournamentId, match }) => {
	const classes = useStyles();
	const history = useHistory();

	const { approveReport, isLoading } = useApproveReport({
		matchId: match?.id,
		reportId: report?.id,
		tournamentId
	});

	return (
		<Dialog
			open={report?.id}
			onClose={() =>
				history.push({
					pathname: location.pathname,
					search: ''
				})
			}
			className={classes.modal}
			disableAutoFocus
			disableEnforceFocus
			disablePortal
			disableRestoreFocus
			maxWidth={false}
		>
			<DialogTitle disableTypography className={clsx(classes.header)}>
				<Typography
					display="inline"
					variant="h6"
					className={classes.title}
				>
					RESULTADO INFORMADO PELO TIME
				</Typography>
				<a
					href={`https://coliseum.gg/app/team/${report?.lineupId}`}
					style={{ textDecoration: 'none' }}
					target="_blank"
				>
					<Typography display="inline" variant="h6" color="primary">
						{report?.lineup?.name}
					</Typography>
				</a>
				<IconButton
					aria-label="close"
					className={classes.closeButton}
					onClick={() =>
						history.push({
							pathname: location.pathname,
							search: ''
						})
					}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<Box className={classes.paper}>
				<Grid container>
					<Grid item xs={6}>
						<Grid>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									gap: 8,
									marginBottom: 16,
									justifyContent: 'center'
								}}
							>
								<Avatar src={match?.teamA?.avatar} />
								<Typography>
									Time {match?.teamA?.name}{' '}
								</Typography>
							</div>
						</Grid>
						<Grid>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center'
								}}
							>
								<TextField
									variant="outlined"
									value={report?.teamAScore}
									disabled
								/>
							</div>
						</Grid>
					</Grid>
					<Grid item xs={6} alignItems="center">
						<Grid>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									gap: 8,
									marginBottom: 16
								}}
							>
								<Avatar src={match?.teamB?.avatar} />
								<Typography display="inline">
									Time {match?.teamB?.name}
								</Typography>
							</div>
						</Grid>
						<Grid item>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center'
								}}
							>
								<TextField
									variant="outlined"
									style={{ justifySelf: 'center' }}
									value={report?.teamBScore}
									disabled
								/>
							</div>
						</Grid>
					</Grid>
				</Grid>
				<div
					style={{
						maxWidth: '500px',
						display: 'flex',
						flexWrap: 'wrap',
						justifyContent: 'center',
						marginTop: '32px',
						gap: '8px'
					}}
				>
					{report?.images?.map((image) => (
						<a href={image.image} target="_blank">
							<div>
								<img src={image.image} height="80px" />
							</div>
						</a>
					))}
				</div>

				<Button
					onClick={approveReport}
					disabled={
						isLoading || report?.adminDecision || !!match?.outcome
					}
					color="primary"
					variant="contained"
					style={{ width: '100%', marginTop: '16px' }}
				>
					{report?.adminDecision || !!match?.outcome
						? `JÁ CONFIRMADO`
						: `APROVAR`}
				</Button>
			</Box>
		</Dialog>
	);
};

export default TournamentReportModal;
