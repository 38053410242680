import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';

import RowTeamStats from './RowTeamStats';
import StyledTableRow from './StyledTableRow';

const useStyles = makeStyles(
	(theme) => ({
		table: {
			minWidth: 630,
			boxShadow: '0px 24px 24px rgba(0, 0, 0, 0.75)',
			borderRadius: 10,
			borderColor: 'transparent'
		}
	}),
	{ name: 'GroupTable' }
);

const GroupTable = ({ statistics }) => {
	const classes = useStyles();

	return (
		<Table className={classes.table} aria-label="group table">
			<TableHead>
				<StyledTableRow>
					<TableCell title="Equipe" />
					<TableCell align="center" title="Partidas">
						J
					</TableCell>
					<TableCell align="center" title="Vitórias">
						V
					</TableCell>
					<TableCell align="center" title="Derrotas">
						D
					</TableCell>
					<TableCell align="center" title="Empates">
						E
					</TableCell>
					<TableCell align="center" title="RG">
						RG
					</TableCell>
					<TableCell align="center" title="RP">
						RP
					</TableCell>
					<TableCell align="center" title="SR">
						SR
					</TableCell>
					<TableCell align="center" title="P">
						P
					</TableCell>
				</StyledTableRow>
			</TableHead>
			<TableBody>
				{statistics.map((row, idx) => (
					<RowTeamStats
						key={`group-row-${idx}`}
						row={row}
						idx={idx}
					/>
				))}
			</TableBody>
		</Table>
	);
};

export default GroupTable;
